import {
  Button,
  Text,
  type ButtonTextProps,
} from '@kanda-libs/ks-design-library';
import { ReactComponent as Lines } from 'assets/background.svg';
import type { FunctionComponent } from 'react';
import { isBlackFriday } from 'utils';
import Bullets from './Bullets';
import Features from './Features';
import type { SubscriptionCardVariant, SubscriptionProps } from './types';
import useSubscriptionCard from './useSubscriptionCard';

export interface SubscriptionCardProps {
  title: string;
  subscription: SubscriptionProps;
  bullets: string[];
  features: string[];
  buttonProps: ButtonTextProps;
  variant?: SubscriptionCardVariant;
  isLoading?: boolean;
}

const SubscriptionCard: FunctionComponent<SubscriptionCardProps> = ({
  title,
  subscription,
  bullets,
  features,
  buttonProps,
  variant = 'standard',
  isLoading = false,
}) => {
  const { classNames, showLines } = useSubscriptionCard(variant);

  return (
    <div className={classNames.card}>
      {showLines && <Lines className={classNames.lines} />}
      <div
        className={`flex flex-col ${
          isBlackFriday() && variant === 'highlight'
            ? 'p-4 bg-neutral-900 rounded-lg -mx-2 -my-2'
            : ''
        }`}
      >
        <Text
          text={title}
          className={classNames.title}
          isLoading={isLoading}
          skeletonProps={{ width: 80 }}
        />
        <div className="flex flex-row">
          <Text
            className={classNames.subscriptionPrice}
            text={`£${subscription.amount}`}
            isLoading={isLoading}
            skeletonProps={{ width: 60 }}
          />
          <Text
            className={classNames.subscriptionFrequency}
            text={` / ${subscription.frequency}`}
            isLoading={isLoading}
            skeletonProps={{ width: 60 }}
          />
        </div>
        <Bullets bullets={bullets} variant={variant} isLoading={isLoading} />
        <Features features={features} variant={variant} isLoading={isLoading} />
        <Button.Text {...buttonProps} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default SubscriptionCard;

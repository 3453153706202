import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import { useFinanceOptionsContext } from 'components/FinanceOptions/context';
import { useMemo } from 'react';
import {
  getFinanceRatesLabel,
  getLongestTerm,
  getLowestCostToConsumer,
  getRateCost,
} from 'utils';

export interface FinanceOptionRateCostsHook {
  from: number;
  cost: number;
  rate: FinanceRate;
  total: number;
  deposit: number;
  popoverId: string;
  rateName: string;
}

export default function useFinanceOptionRateCosts(
  rates: FinanceRate[],
): FinanceOptionRateCostsHook {
  const { total, deposit } = useFinanceOptionsContext();

  const rate = useMemo(() => getLongestTerm(rates), [rates]);

  const from = useMemo(
    () => getLowestCostToConsumer(rates, total, deposit),
    [rates, total, deposit],
  );

  const cost = useMemo(
    () => getRateCost(rate, total, deposit),
    [rate, total, deposit],
  );

  const popoverId = useMemo(() => {
    const { provider, name } = rate;
    return [provider, name].join('-');
  }, [rate]);

  const rateName = useMemo(() => getFinanceRatesLabel(rates), [rates]);

  return {
    from,
    cost,
    rate,
    total,
    deposit,
    popoverId,
    rateName,
  };
}

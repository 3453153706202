import type { EnquiryState } from '@kanda-libs/ks-frontend-services';
import { useMemo } from 'react';
import { getSuffix } from './helpers';

export interface LeadActivityHook {
  suffix: string;
  className: string;
}

export default function useLeadActivity(
  status: EnquiryState,
  textClassName?: string,
): LeadActivityHook {
  const className = useMemo(
    () =>
      [textClassName || 'text-16-20 text-neutral-900', 'text-left mr-1'].join(
        ' ',
      ),
    [textClassName],
  );

  const suffix = useMemo(() => getSuffix(status), [status]);

  return { className, suffix };
}

import useQuery from 'hooks/useQuery';
import useSearchWords from 'hooks/useSearchWords';
import { type QueryType } from 'store/slices/query';

export interface TableContactHook {
  searchWords: string[];
}

export default function useTableContact(
  queryType: QueryType,
): TableContactHook {
  const { fetchedQuery: query } = useQuery(queryType);
  const { searchWords } = useSearchWords(query);

  return {
    searchWords,
  };
}

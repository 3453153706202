import { pipe } from 'fp-ts/lib/function';
import { useMemo } from 'react';
import type { GroupedByKey } from 'utils';

export interface OptionHook {
  title: string;
  keys: string[];
}

const formatAprString = (apr: string): string => {
  if (apr === '0') return apr;
  const len = apr.length;
  const parts = [apr.substring(0, len - 2), apr.substring(len - 2)];
  if (parts[1] === '00') return parts[0];
  if (parts[1][1] === '0') return `${parts[0]}.${parts[1][0]}`;
  return `${parts[0]}.${parts[1]}`;
};

const appendInterestText = (apr: string): string => `${apr}% APR Interest`;

export default function useOption(
  apr: string,
  rates: GroupedByKey,
): OptionHook {
  const title = useMemo(
    () => pipe(apr, formatAprString, appendInterestText),
    [apr],
  );

  const keys = useMemo(() => {
    if (!rates) return [];
    if (parseInt(apr, 10) === 0) {
      return Object.keys(rates).sort((k1, k2) => {
        const r1 = rates[k1][0];
        const r2 = rates[k2][0];
        return (r1.duration || 0) - (r2.duration || 0);
      });
    }
    return Object.keys(rates);
  }, [apr, rates]);

  return { title, keys };
}

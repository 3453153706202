import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

export interface NoRatesHook {
  noWorkType: boolean;
}

export default function useNoRates(): NoRatesHook {
  const workType = useWatch({ name: 'job.work_type' });

  const noWorkType = useMemo(() => {
    if (!workType) return true;
    return false;
  }, [workType]);

  return { noWorkType };
}

import TableCell from 'components/table/TableCell';
import TableContact from 'components/table/TableContact';
import TableName from 'components/table/TableName';
import LeadActivity from 'pages/Leads/components/LeadActivity';
import LeadBudget from 'pages/Leads/components/LeadBudget';
import LeadStatusTag from 'pages/Leads/components/LeadStatusTag';

export const TABLE_COLUMNS = [
  {
    Header: 'Homeowner Name',
    accessor: 'firstName',
    subAccessors: ['lastName', 'example'],
    items: [
      {
        action: {
          type: 'first-name-alternate',
        },
        icon: 'alphabet',
        name: 'Sort by first name',
      },
      {
        action: {
          type: 'last-name-alternate',
        },
        icon: 'alphabet',
        name: 'Sort by last name',
      },
    ],
    width: 120,
    minWidth: 100,
    maxWidth: 2000,
    renderComponent: {
      component: TableName,
      value: 'firstName',
      optionalProps: ['lastName', 'example'],
      props: {
        className: 'text-16-20-em text-neutral-700',
        hideTag: true,
        queryType: 'leads',
      },
    },
  },
  {
    Header: 'Homeowner Contact',
    accessor: 'email',
    subAccessors: ['phone'],
    items: [
      {
        action: {
          type: 'email-alternate',
        },
        icon: 'email',
        name: 'Sort by email',
      },
      {
        action: {
          type: 'phone-alternate',
        },
        icon: 'phone',
        name: 'Sort by phone',
      },
    ],
    width: 160,
    minWidth: 100,
    maxWidth: 2000,
    renderComponent: {
      component: TableContact,
      value: 'email',
      optionalProps: ['phone'],
      props: {
        queryType: 'leads',
      },
    },
  },
  {
    Header: 'Work Type',
    accessor: 'workType',
    items: [
      {
        action: {
          type: 'work-type-ascending',
        },
        icon: 'sort-ascending',
        name: 'Sort ascending',
      },
      {
        action: {
          type: 'work-type-descending',
        },
        icon: 'sort-descending',
        name: 'Sort descending',
      },
    ],
    width: 120,
    minWidth: 80,
    maxWidth: 400,
    renderComponent: {
      component: TableCell,
      value: 'value',
      props: {
        className: 'text-14-22-em text-neutral-900',
        queryType: 'leads',
      },
    },
  },
  {
    Header: 'Budget',
    accessor: 'budget',
    subAccessors: ['interestInFinance'],
    items: [
      {
        action: {
          type: 'budget-min-ascending',
        },
        icon: 'sort-ascending',
        name: 'Min ascending',
      },
      {
        action: {
          type: 'budget-min-descending',
        },
        icon: 'sort-descending',
        name: 'Min descending',
      },
      {
        action: {
          type: 'budget-max-ascending',
        },
        icon: 'sort-ascending',
        name: 'Max ascending',
      },
      {
        action: {
          type: 'budget-max-descending',
        },
        icon: 'sort-descending',
        name: 'Max descending',
      },
      {
        action: {
          type: 'finance-interest-alternate',
        },
        icon: 'pound',
        name: 'Sort by interest',
      },
    ],
    width: 180,
    minWidth: 180,
    maxWidth: 180,
    renderComponent: {
      component: LeadBudget,
      value: 'budget',
      optionalProps: ['interestInFinance'],
      props: {
        className: 'text-16-20-em text-neutral-700',
        queryType: 'leads',
      },
    },
  },
  {
    Header: 'Status',
    accessor: 'currentState',
    items: [
      {
        action: {
          type: 'status-ascending',
        },
        icon: 'sort-ascending',
        name: 'Sort ascending',
      },
      {
        action: {
          type: 'status-descending',
        },
        icon: 'sort-descending',
        name: 'Sort descending',
      },
    ],
    width: 120,
    minWidth: 110,
    maxWidth: 140,
    renderComponent: {
      component: LeadStatusTag,
      value: 'status',
      props: {
        queryType: 'leads',
      },
    },
  },
  {
    Header: 'Source',
    accessor: 'source',
    items: [
      {
        action: {
          type: 'source-ascending',
        },
        icon: 'sort-ascending',
        name: 'Sort ascending',
      },
      {
        action: {
          type: 'source-descending',
        },
        icon: 'sort-descending',
        name: 'Sort descending',
      },
    ],
    width: 80,
    minWidth: 80,
    maxWidth: 100,
    renderComponent: {
      component: TableCell,
      value: 'value',
      props: {
        className: 'text-14-22-em text-neutral-900',
        queryType: 'leads',
      },
    },
  },
  {
    Header: 'Last updated',
    accessor: 'updatedAt',
    subAccessors: ['currentState'],
    items: [
      {
        action: {
          type: 'updated-ascending',
        },
        icon: 'sort-ascending',
        name: 'Sort ascending',
      },
      {
        action: {
          type: 'updated-descending',
        },
        icon: 'sort-descending',
        name: 'Sort descending',
      },
    ],
    width: 120,
    minWidth: 100,
    maxWidth: 160,
    renderComponent: {
      component: LeadActivity,
      value: 'updatedAt',
      optionalProps: ['currentState'],
      textClassName: 'text-14-22',
    },
  },
];

export const DEFAULT_COLUMN = {
  minWidth: 80,
  width: 200,
  maxWidth: 400,
};

import { createSelector } from '@reduxjs/toolkit';
import { type RootState } from 'store';

export const getQuery = (state: RootState) => state.query;

export const getQueryTerms = createSelector(
  getQuery,
  (query) => query.queryTerms,
);

const querySelectors = {
  getQuery,
  getQueryTerms,
};

export default querySelectors;

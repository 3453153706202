import type { TagProps } from '@kanda-libs/ks-design-library';
import type { InterestInFinance } from 'pages/Leads/helpers';
import { useMemo } from 'react';
import { getLabel, getLabelColor } from './helpers';

export interface InterestInFinanceTagHook {
  color: TagProps['color'];
  label: string | undefined;
}

export default function useInterestInFinanceTag(
  interest: InterestInFinance,
): InterestInFinanceTagHook {
  const color = useMemo(() => getLabelColor(interest), [interest]);
  const label = useMemo(() => getLabel(interest), [interest]);

  return {
    color,
    label,
  };
}

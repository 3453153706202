import { Field, FormTheme, Widget } from '@kanda-libs/ks-component-ts';
import { Button } from '@kanda-libs/ks-design-library';
import AddressFields from '../AddressFields';
import { COMPANY_ADDRESS_FIELD_NAMES, COMPANY_NAME_PROPS } from './constants';
import useLimitedCompanyForm from './useLimitedCompanyForm';

export interface LimitedCompanyFormProps {
  schemaError?: boolean;
}

const LimitedCompanyForm: React.FunctionComponent<LimitedCompanyFormProps> =
  function ({ schemaError }) {
    const { noCompany, noCompanyCallback, searchCompany } =
      useLimitedCompanyForm();
    return (
      <div className="flex flex-col">
        <FormTheme variant="streamline">
          <div className="flex-1">
            {noCompany ? (
              <div className="flex flex-col md:flex-row gap-x-2 ">
                <Field.Input {...COMPANY_NAME_PROPS} />
                <Button.Text
                  id="enable-search-company"
                  size={32}
                  variant="outline-primary"
                  label="Search Company"
                  className="mt-0 mb-4 md:mt-7 md:mb-0 min-w-40"
                  onClick={searchCompany}
                />
              </div>
            ) : (
              <div className="flex-1">
                <Widget.CompanyLimitedCompanyInfoCompanyName
                  label="Search for your company"
                  placeholder="Search Companies House"
                  noCompanyCallback={noCompanyCallback}
                  {...COMPANY_ADDRESS_FIELD_NAMES}
                />
              </div>
            )}
          </div>
          {noCompany || schemaError ? (
            <>
              <Widget.CompanyLimitedCompanyInfoCompanyNumber />
              <AddressFields companyType="limited_company" />
            </>
          ) : (
            <p className="text-neutral-600 text-12-18">
              Struggling to find your company? Try searching by company number.
            </p>
          )}
        </FormTheme>
      </div>
    );
  };

export default LimitedCompanyForm;

import {
  FormTheme,
  Widget,
  type ValidationItems,
} from '@kanda-libs/ks-component-ts';
import { Button } from '@kanda-libs/ks-design-library';

import { WorkTypesSelect } from 'components';
import WorkTypesForm from 'components/WorkTypesForm';
import TradeBodyButtons from './TradeBodyButtons';
import useTradeInfoForm from './useTradeInfoForm';

const ViewComponent = ({}) => {
  const {
    isLoading,
    isSoleTrader,
    tradeNumberValidation,
    // otherTradeType,
    otherTradeBody,
    noneTradeBody,
    showTradeTypeFields,
    showTradeBodyFields,
    onYesClick,
    onNoClick,
    showContinue,
    workTypesTitle,
  } = useTradeInfoForm();

  return (
    <FormTheme variant="streamline">
      <div className="flex flex-col">
        {showTradeTypeFields && (
          <div className="flex flex-col mb-6">
            <WorkTypesForm
              sectorTitle="What sector does your business operate in?"
              workTypeTitle="Which option below best describes the main work your business undertakes?"
            />
          </div>
        )}
        <WorkTypesSelect
          name="company.company_info.work_types"
          title={workTypesTitle}
        />
        <TradeBodyButtons
          onYesClick={onYesClick}
          onNoClick={onNoClick}
          state={showTradeBodyFields}
        />
        {showTradeBodyFields === 'yes' && (
          <>
            <Widget.CompanyCompanyInfoTradeBody
              placeholder="Select..."
              validation={{
                required: { value: true, message: 'Trade body is required.' },
              }}
            />
            {otherTradeBody && <Widget.CompanyCompanyInfoTradeBodyName />}
            {!noneTradeBody && (
              <Widget.CompanyCompanyInfoTradeBodyNumber
                placeholder="Trade body number"
                validation={tradeNumberValidation as unknown as ValidationItems}
              />
            )}
          </>
        )}

        {isSoleTrader && (
          <Widget.CompanySoleTraderInfoNationalInsuranceNumber
            validation={{
              required: {
                value: true,
                message: 'National insurance number is required',
              },
            }}
          />
        )}

        {showContinue && (
          <div className="mt-auto md:mt-0">
            <Button.Text
              submit
              id="onboarding-trade-info-next"
              label="Next"
              isLoading={isLoading}
              className="w-full mt-6"
            />
          </div>
        )}
      </div>
    </FormTheme>
  );
};

ViewComponent.displayName = 'StandardTradeInfoForm-view';

export default ViewComponent;

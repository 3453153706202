import { createSelector } from '@reduxjs/toolkit';
import { type RootState } from 'store';

export const getFiltersState = (state: RootState) => state.filters;

export const getFilters = createSelector(
  getFiltersState,
  (state) => state.filters,
);

export const getLeadsFilters = createSelector(
  getFilters,
  (state) => state.leads,
);

const filtersSelectors = {
  getFilters,
  getLeadsFilters,
};

export default filtersSelectors;

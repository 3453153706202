import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import { useMemo } from 'react';
import { formatToPercentage } from 'utils';

export interface FeeHook {
  fee: string;
}

export default function useFee(
  rates: FinanceRate[],
  grouped: boolean,
): FeeHook {
  const fee = useMemo(() => {
    // TODO: determine whether to show separate fees for non grouped
    const filtered = grouped ? rates : rates;
    const fees = filtered.map((rate) => rate.fee);
    const min = Math.min(...fees);
    return formatToPercentage(min);
  }, [rates, grouped]);

  return {
    fee,
  };
}

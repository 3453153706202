import type { GroupedByKey } from 'utils';
import Rate from './Rate';
import useOption from './useOption';

export interface OptionProps {
  apr: string;
  rates: GroupedByKey;
  toggleable: boolean;
}

const Option: React.FunctionComponent<OptionProps> = function ({
  apr,
  rates,
  toggleable,
}) {
  const { title, keys } = useOption(apr, rates);
  return (
    <div className="grid grid-cols-[1fr_96px_96px] gap-y-3 gap-x-1">
      <p className="text-11-20-em-up text-neutral-600">{title}</p>
      <p className="text-11-20-em-up text-neutral-600">from</p>
      <p className="text-11-20-em-up text-neutral-600">cost</p>
      {keys.map((key) => (
        <Rate key={key} rates={rates[key]} toggleable={toggleable} />
      ))}
    </div>
  );
};

export default Option;

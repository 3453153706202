import { Button, Icon } from '@kanda-libs/ks-design-library';
import { URLS } from 'config';
import { Link } from 'react-router-dom';
import {
  BUTTON_PROPS,
  CLASS_NAMES,
  DESCRIPTION,
  ICON_PROPS,
  TITLE,
} from './constants';

const LeadsPlaceholder: React.FunctionComponent = function () {
  return (
    <div className={CLASS_NAMES.container}>
      <div className={CLASS_NAMES.card}>
        <div className={CLASS_NAMES.content}>
          <Icon {...ICON_PROPS} />
          <p className={CLASS_NAMES.title}>{TITLE}</p>
          <p className={CLASS_NAMES.description}>{DESCRIPTION}</p>
          <Link to={URLS.alwaysOnLink}>
            <Button.Text {...BUTTON_PROPS} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LeadsPlaceholder;

import type { PaginationProps } from '@kanda-libs/ks-design-library';
import { useLeadsContext } from 'pages/Leads/context';
import type { FormattedLead } from 'pages/Leads/helpers';
import { useMemo } from 'react';
import { SKELETON_JOBS } from './constants';

export interface LeadsListHook {
  data: FormattedLead[];
  isLoading: boolean;
  showPagination: boolean;
  paginationProps: PaginationProps;
}

export default function useLeadsList(): LeadsListHook {
  const { leads, totalPages, pageIndex, setPage, isLoading } =
    useLeadsContext();

  const showPagination = useMemo(() => totalPages > 1, [totalPages]);

  const data = useMemo(() => {
    if (isLoading) return SKELETON_JOBS;
    return leads;
  }, [isLoading, leads]);

  return {
    data,
    isLoading,
    showPagination,
    paginationProps: {
      pageCount: totalPages,
      pageIndex,
      setPage,
    },
  };
}

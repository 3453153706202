import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import type { FinanceRate } from '@kanda-libs/ks-frontend-services';

export const groupAllRatesByInterest = (
  rates: FinanceRate[],
): StringIndexedObject => {
  const rateKeys = rates
    .sort((rate1, rate2) => (rate1.apr || 0) - (rate2.apr || 0))
    .map(
      (rate: FinanceRate) => `${rate.apr}-${rate.apr_type || 'INTEREST_FREE'}`,
    )
    .filter(
      (value: string, index: number, array: string[]) =>
        array.indexOf(value) === index,
    );
  if (!rateKeys || rateKeys.length === 0) return [];

  return rateKeys.map((key: string) => {
    if (key === undefined) return {};
    const [apr, aprType] = key.split('-');
    const durations = rates
      .filter(
        (rate: FinanceRate) =>
          String(rate.apr) === apr && String(rate.apr_type) === aprType,
      )
      .map((rate: FinanceRate) =>
        rate?.deferred_duration
          ? `${rate.deferred_duration} + ${rate?.duration}`
          : String(rate.duration),
      );
    const bnpl = aprType === 'BUYNOW_PAYLATER';
    const rateKey = [
      `${String(parseInt(apr, 10) / 100)}% APR`,
      bnpl ? ' BNPL' : '',
    ].join('');
    return {
      rateKey,
      durations,
    };
  });
};

import { BreakPoints, Button } from '@kanda-libs/ks-design-library';
import { Helmet } from 'components';
import TrustPilotRating from 'components/TrustPilotRating';
import { URLS } from 'config';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout } from '../components';
import { VideoEmbed } from './components';

const SetupInfo: React.FunctionComponent = function () {
  const { push } = useHistory();
  const onClick = useCallback(() => push(URLS.setupSubscription), [push]);
  return (
    <Layout
      mobileFooter={
        <Button.Text
          id="setup-info-next"
          className="w-full"
          label="Apply Now and Pay"
          icon="arrow-right"
          variant="gradient"
          onClick={() => push(URLS.setupSubscription)}
        />
      }
    >
      <Helmet title="Kanda Info" />
      <div className="flex flex-col w-full max-w-120 md:max-w-full mx-auto">
        <VideoEmbed />
        <BreakPoints
          desktop={
            <Button.Text
              id="setup-rates-next"
              className="w-full max-w-80 mt-6 mx-auto"
              label="Apply Now and Pay"
              icon="arrow-right"
              variant="gradient"
              onClick={onClick}
            />
          }
        />
        <TrustPilotRating wrapperClassName="mt-10" />
      </div>
    </Layout>
  );
};

export default SetupInfo;

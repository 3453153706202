import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import { useFinanceOptionsContext } from 'components/FinanceOptions/context';
import { useMemo } from 'react';
import { getFinanceRatesLabel } from 'utils';

export interface LabelHandleHook {
  showHandle: boolean;
  rateName: string;
}

export default function useLabelHandle(rates: FinanceRate[]): LabelHandleHook {
  const { showHandle } = useFinanceOptionsContext();
  const rateName = useMemo(() => getFinanceRatesLabel(rates), [rates]);
  return {
    showHandle,
    rateName,
  };
}

import { ModalContainer } from '@kanda-libs/ks-design-library';
import { Modal } from './components';
import { WORK_TYPE_MODAL_ID } from './constants';

const WorkTypeModal: React.FunctionComponent = function () {
  return (
    <ModalContainer id={WORK_TYPE_MODAL_ID} opacity={30}>
      {(props) => <Modal {...props} />}
    </ModalContainer>
  );
};

export default WorkTypeModal;

import Custom from './Custom';
import Kanda from './Kanda';
import Container from './Quote-container';

import { JOB_TYPE } from '../../../CreateJob-constants';

export interface QuoteProps {
  refreshJobDetails?: () => void;
}

const ViewComponent: React.FunctionComponent<QuoteProps> = ({
  refreshJobDetails,
}) => (
  <Container>
    {({ jobType }) => {
      switch (jobType) {
        case JOB_TYPE.CUSTOM:
          return <Custom refreshJobDetails={refreshJobDetails} />;
        default:
          return <Kanda />;
      }
    }}
  </Container>
);

ViewComponent.displayName = 'CreateJob-Quote-view';

export default ViewComponent;

import Popover from 'components/Popover';
import { useMemo } from 'react';
import type { GroupedByKey } from 'utils';
import GroupedRates from './GroupedRates';
import IndividualRates from './IndividualRates';

export interface MyAccountFinanceRatesFormRateCardProps {
  grouped: GroupedByKey;
  apr: string;
  groupDurations: boolean;
}

const MyAccountFinanceRatesFormRateCard: React.FunctionComponent<MyAccountFinanceRatesFormRateCardProps> =
  function ({ grouped, apr, groupDurations }) {
    const label = useMemo(() => {
      if (apr === 'bnpl') return 'Buy Now, Pay Later';
      return `${parseInt(apr, 10) / 100}% APR INTEREST`;
    }, [apr]);

    const showPopover = useMemo(() => apr === '1290', [apr]);

    return (
      <div className="grid grid-cols-[1fr_min-content]">
        <div className="flex flex-row mb-2">
          <h3 className="text-12-18-em-up text-neutral-500 ">{label}</h3>
          {showPopover && (
            <Popover popoverId={`apr-info-${apr}`}>
              <p className="text-14-22 text-neutral-700">
                This option is only available for certain types of work
              </p>
            </Popover>
          )}
        </div>
        <h3 className="text-12-18-em-up text-neutral-500 whitespace-nowrap text-right">
          Fee (from)
        </h3>
        {groupDurations ? (
          <GroupedRates grouped={grouped} />
        ) : (
          <IndividualRates grouped={grouped} apr={apr} />
        )}
      </div>
    );
  };

export default MyAccountFinanceRatesFormRateCard;

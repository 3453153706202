import {
  useWatch,
  type LabelProps,
  type SelectOption,
} from '@kanda-libs/ks-component-ts';
import type { WorkType } from '@kanda-libs/ks-frontend-services';
import { useCurrentCompany } from 'hooks';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import { useMemo } from 'react';
import { formatWorkTypesToOptions } from 'utils';
import { LABEL_PROPS, NAME, WORK_TYPES } from './constants';
import { checkWorkTypeNoRates } from './helpers';

interface WorkTypeFieldHook {
  props: {
    name: string;
    options: SelectOption[];
    disabled: boolean;
  };
  example: boolean;
  label: LabelProps;
  showNoRates: boolean;
  filterableSelect: boolean;
}

export default function useWorkTypeField(): WorkTypeFieldHook {
  const { data } = useCreateJobContextValue();
  const { company, isLoading } = useCurrentCompany();
  const workType = useWatch({
    name: 'job.work_type',
  });

  const example = useMemo(() => data?.job?.flow_type === 'example', [data]);

  const showNoRates = useMemo(() => {
    if (isLoading || !company) return false;
    return checkWorkTypeNoRates(company, workType);
  }, [isLoading, company, workType]);

  const companyTypes = useMemo(
    () => company?.company_info?.work_types,
    [company],
  );

  const workTypes = useMemo(() => {
    if (example)
      return [
        {
          name: 'Relevant Work Type',
          value: 'other',
        },
      ];
    if (!companyTypes)
      return formatWorkTypesToOptions(WORK_TYPES as WorkType[]).sort(
        (workType1, workType2) => workType1.name.localeCompare(workType2.name),
      );
    return formatWorkTypesToOptions(companyTypes).sort((workType1, workType2) =>
      workType1.name.localeCompare(workType2.name),
    );
  }, [example, companyTypes]);

  return {
    filterableSelect: !companyTypes,
    example,
    props: {
      name: NAME,
      options: workTypes,
      disabled: example,
    },
    label: {
      ...LABEL_PROPS,
      helperText: example
        ? '(can be changed when creating a live job)'
        : LABEL_PROPS.helperText,
    },
    showNoRates,
  };
}

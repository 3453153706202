import { Button, Header } from '@kanda-libs/ks-design-library';
import LeadSlideUpModal from '../LeadSlideUpModal';
import useMobileHeader from './useMobileHeader';

const MobileHeader: React.FunctionComponent = function ({}) {
  const { openSlideUp, onClick } = useMobileHeader();
  return (
    <Header.Base
      options={[
        <Button.Icon
          id="job-details-mobile-header-mobile"
          key="more"
          icon="more"
          onClick={openSlideUp}
        />,
      ]}
    >
      <Button.Icon
        id="lead-details-mobile-header-back"
        onClick={onClick}
        icon="chevron-left"
        className="-ml-3"
      />
      <LeadSlideUpModal />
    </Header.Base>
  );
};

export default MobileHeader;

import { Button, Header } from '@kanda-libs/ks-design-library';
import { URLS } from 'config';
import { useLeadsContext } from 'pages/Leads/context';
import { Link } from 'react-router-dom';

const LeadHeaderMobile: React.FunctionComponent = function () {
  const { archive } = useLeadsContext();
  if (archive)
    return (
      <Header.Base>
        <Link to={URLS.leads}>
          <Button.Icon
            id="home-mobile-back"
            icon="chevron-left"
            className="-ml-4"
          />
        </Link>
      </Header.Base>
    );
  return <Header.Main linkComponent={Link} />;
};

export default LeadHeaderMobile;

import type { Company, Enquiry } from '@kanda-libs/ks-frontend-services';
import { useCurrentCompany } from 'hooks';
import {
  createContext,
  useContext,
  useMemo,
  type FunctionComponent,
  type ReactNode,
} from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';

export interface LeadContextType {
  lead: Enquiry | undefined;
  company: Company | undefined;
  archived: boolean;
  id: string | undefined;
  isLoading: boolean;
  isSubmitting: boolean;
}

export const LeadContext = createContext<LeadContextType>({
  lead: undefined,
  company: undefined,
  archived: false,
  id: undefined,
  isLoading: false,
  isSubmitting: false,
});

export const useLeadContext = () => useContext(LeadContext);

export interface LeadProviderProps {
  children: ReactNode;
}

const LeadProvider: FunctionComponent<LeadProviderProps> = function ({
  children,
}) {
  const lead = useSelector(selectors.enquiry.getEntity);
  const leadHasFetched = useSelector(selectors.enquiry.getHasFetched);
  const id = useSelector(selectors.enquiry.getId);
  const isSubmitting = useSelector(selectors.enquiry.getIsSubmitting);

  const archived = useMemo(() => {
    if (!lead) return false;
    return lead.archived === 'yes';
  }, [lead]);

  const { company } = useCurrentCompany();

  const isLoading = useMemo(
    () => !leadHasFetched || !company,
    [leadHasFetched, company],
  );

  const values = useMemo(
    () => ({
      lead,
      company: company || undefined,
      archived,
      id,
      isLoading,
      isSubmitting,
    }),
    [lead, company, archived, id, isLoading, isSubmitting],
  );

  return <LeadContext.Provider value={values}>{children}</LeadContext.Provider>;
};

export default LeadProvider;

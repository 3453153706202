import { type TagProps } from '@kanda-libs/ks-design-library';

export const CLASS_NAMES = {
  highlighter: 'text-green-300 bg-transparent',
  container: 'flex flex-row text-16-20 text-neutral-900 text-left',
  text: 'text-16-20-em',
  tag: 'mr-3',
};

export const HIGHLIGHT_PROPS = {
  highlightClassName: CLASS_NAMES.highlighter,
  autoEscape: true,
};

export const TAG_PROPS: TagProps = {
  label: 'Example',
  size: 21,
  color: 'lavender',
  variant: 'solid',
  className: CLASS_NAMES.tag,
};

export const OWNER_TAG_PROPS: TagProps = {
  label: 'Owner',
  size: 21,
  color: 'green',
  variant: 'solid',
  className: CLASS_NAMES.tag,
};

export const CURRENT_USER_TAG_PROPS: TagProps = {
  label: 'Me',
  size: 21,
  color: 'green',
  variant: 'solid',
  className: CLASS_NAMES.tag,
};

import { Form, Widget } from '@kanda-libs/ks-component-ts';
import { Button, Loader, Popover, Text } from '@kanda-libs/ks-design-library';
import { JOB_SETTINGS_OPTIONS } from 'pages/MyAccount/JobSettings/JobSettings-constants';
import InvoiceSettingsPopover from './InvoiceSettingsPopover';
import useJobSettingsForm from './useJobSettingsForm';

const JobSettingsForm: React.FunctionComponent = function () {
  const { form, onSubmit, isSubmitting } = useJobSettingsForm();
  return (
    <Form id="account-settings-job-settings" form={form} onSubmit={onSubmit}>
      <label
        className="flex w-full mb-6 text-style-h-em text-neutral-600 gap-x-1"
        htmlFor="customer_details.email"
      >
        <Text
          text="Invoice settings"
          className="text-16-20-em text-neutral-900"
        />
        <Popover.Standard
          id="invoice-settings-popover"
          above
          button={
            <div className="w-4 h-4">
              <Button.Icon
                id="invoice-settings-popover"
                icon="info"
                size="28-16"
                className="-mt-1.5 -ml-1"
              />
            </div>
          }
        >
          {({ handleClose }) => (
            <InvoiceSettingsPopover onClose={handleClose} />
          )}
        </Popover.Standard>
      </label>
      <Widget.CompanyQuotePreference
        options={JOB_SETTINGS_OPTIONS}
        label=""
        variant="streamline-radio"
        inline
        wrap
      />
      <Button.Text
        submit
        id="account-settings-job-settings-submit"
        className="mb-4 mt-2"
        disabled={isSubmitting}
        label="Update Invoice Type"
      />
      <Loader isLoading={isSubmitting} />
    </Form>
  );
};

export default JobSettingsForm;

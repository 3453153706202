import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import { AmountDisplay } from '../../components';
import useRateCostPopover from './useRateCostPopover';

export interface RateCostPopoverProps {
  rate: FinanceRate;
  rateName: string;
  total: number;
  deposit: number;
}

const RateCostPopover: React.FunctionComponent<RateCostPopoverProps> =
  function ({ rate, rateName, total, deposit }) {
    const { subsidy, charge, subsidyTitle, aprLabel } = useRateCostPopover(
      rate,
      total,
      deposit,
    );
    return (
      <div className="grid grid-cols-[1fr_auto] gap-y-2 gap-x-2">
        <p className="text-18-22-em md:text-12-18-em text-neutral-900 col-span-2 mb-2 md:mb-0">
          Cost Breakdown
        </p>
        <div className="flex md:hidden py-3 px-4 bg-neutral-100 text-16-20-em text-neutral-600 rounded-xl flex items-center col-span-2 mb-2 -mx-2">
          {aprLabel}
          <div className="w-1 h-1 bg-neutral-400 rounded-full mx-2" />
          {rateName}
        </div>
        <AmountDisplay title={subsidyTitle} amount={subsidy} showFree />
        <AmountDisplay title="Success Charge" amount={charge} showFree />
        <div className="border-b border-neutral-200 col-span-2" />
        <AmountDisplay title="Total" amount={subsidy + charge} emphasized />
      </div>
    );
  };

export default RateCostPopover;

import {
  Address,
  Enquiry,
  InterestInCredit,
  MaxBudget,
  type EnquiryState,
} from '@kanda-libs/ks-frontend-services';
import { pipe } from 'fp-ts/lib/function';
import get from 'lodash.get';
import type { LeadsFilter } from 'store/slices/filters';
import type { BaseOrder, LeadSorting } from 'store/slices/sorting';
import { formatToCurrency, formatWorkTypeToString, parseCurrency } from 'utils';

export type InterestInFinance =
  | 'interested'
  | 'not_interested'
  | 'not_answered';

export interface FormattedLead {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  source: string;
  currentState: EnquiryState;
  verboseCurrentState: string;
  budget: string;
  interestInFinance: InterestInFinance;
  workType: string;
}

export const formatAddress = (address?: Address): string => {
  if (!address) return 'No address given';
  return [address.line_1, address?.line_2, address.city, address.postcode]
    .filter(Boolean)
    .join(', ');
};

export const formatBudget = (budget: MaxBudget | undefined): string => {
  if (!budget) return 'No budget set';
  const min = budget?.min?.amount;
  const max = budget.max.amount;
  if (!min) return `Max: ${formatToCurrency(max)}`;
  return `${formatToCurrency(min)}-${formatToCurrency(max)}`;
};

export const getInterestInFinance = (
  interest: InterestInCredit | undefined,
): InterestInFinance => {
  if (!interest) return 'not_answered';
  return interest === 'yes' ? 'interested' : 'not_interested';
};

export const getVerboseCurrentState = (
  status: EnquiryState | undefined,
): string => {
  switch (status) {
    case 'viewed':
    case 'contact_only':
      return '';
    case 'contact_agreed':
    case 'preferences_set':
      return 'Contact agreed';
    case 'ask_for_quote':
    case 'submitted':
      return 'Quote Requested';
    case 'quoted':
      return 'Quoted';
    case 'converted_to_job':
      return 'Converted';
    case 'not_interested':
      return 'Not Interested';
    case 'unreachable':
      return 'Unreachable';
    case 'other_reason':
      return 'Other';
    default:
      return '';
  }
};

export const formatLeads = (leads: Enquiry[]): FormattedLead[] =>
  leads.map((lead) => ({
    id: lead.id,
    updatedAt: new Date(lead.metadata.updated_at),
    createdAt: new Date(lead.metadata.created_at),
    currentState: lead.current_state,
    verboseCurrentState: getVerboseCurrentState(lead.current_state),
    source: lead.source,
    firstName: lead?.enquiry_info?.consumer?.first_name || 'No name',
    lastName: lead?.enquiry_info?.consumer?.last_name || 'given',
    email: lead?.enquiry_info?.consumer?.email || 'No email given',
    phone: lead?.enquiry_info?.consumer?.phone || 'No phone given',
    address: formatAddress(lead?.enquiry_info?.consumer?.address),
    budget: formatBudget(lead?.enquiry_info?.budget),
    interestInFinance: getInterestInFinance(
      lead?.enquiry_info?.interest_in_credit,
    ),
    workType: lead?.enquiry_info?.job_info?.work_type
      ? formatWorkTypeToString(lead.enquiry_info.job_info.work_type)
      : 'Not stated',
  }));

export const filterByState = (
  leads: FormattedLead[],
  state?: EnquiryState,
): FormattedLead[] => {
  if (!state) return leads;
  return leads.filter((lead) => lead.currentState === state);
};

export const filterBySource = (
  leads: FormattedLead[],
  source?: string,
): FormattedLead[] => {
  if (!source) return leads;
  return leads.filter((lead) => lead.source === source);
};

export const filterLeads = (
  leads: FormattedLead[],
  filter: LeadsFilter,
): FormattedLead[] =>
  pipe(
    leads,
    (all) => filterByState(all, filter.state),
    (stateFiltered) => filterBySource(stateFiltered, filter?.source),
  );

/**
 * Sorts data by date fetched via accessor
 */
const sortByDate = <T>(data: T[], order: BaseOrder, accessor: keyof T): T[] =>
  data.sort((datum1: T, datum2: T) => {
    const datum1Date = new Date(get(datum1, accessor) || 0).getTime();
    const datum2Date = new Date(get(datum2, accessor) || 0).getTime();
    if (order === 'INVERSE') return datum1Date - datum2Date;
    return datum2Date - datum1Date;
  });

/**
 * Sorts data by string fetched via accessor
 */
const sortByString = <T>(
  data: T[],
  order: BaseOrder,
  accessors: (keyof T)[],
  delimiter = ' ',
): T[] =>
  data.sort((datum1: T, datum2: T) => {
    const datum1String = accessors
      .map((accessor: keyof T) => get(datum1, accessor) || undefined)
      .filter(Boolean)
      .join(delimiter);
    const datum2String = accessors
      .map((accessor: keyof T) => get(datum2, accessor) || undefined)
      .filter(Boolean)
      .join(delimiter);
    if (!datum1String && !datum2String) return 1;
    if (!datum1String) return 1;
    if (!datum2String) return -1;
    if (order === 'INVERSE') return datum2String.localeCompare(datum1String);
    return datum1String.localeCompare(datum2String);
  });

export type SortBudgetType = 'min' | 'max';

const parseBudget = (budget: string) => {
  if (budget.includes('Max:')) {
    const max = parseCurrency(budget.replace('Max: ', ''));
    return [0, max];
  }
  const [minRaw, maxRaw] = budget.split('-');
  return [parseCurrency(minRaw), parseCurrency(maxRaw)];
};

export const sortByBudget = (
  leads: FormattedLead[],
  order: BaseOrder,
  type: SortBudgetType = 'min',
): FormattedLead[] =>
  leads.sort((datum1: FormattedLead, datum2: FormattedLead) => {
    const budgetString1 = datum1.budget;
    const budgetString2 = datum2.budget;
    if (budgetString1 === 'No budget set' && budgetString2 === 'No budget set')
      return 1;
    if (budgetString1 === 'No budget set') return 1;
    if (budgetString2 === 'No budget set') return -1;
    const [min1, max1] = parseBudget(budgetString1);
    const [min2, max2] = parseBudget(budgetString2);
    const [val1, val2] = type === 'min' ? [min1, min2] : [max1, max2];
    if (order === 'INVERSE') return val2 - val1;
    return val1 - val2;
  });

export const sortLeadData = (
  leads: FormattedLead[],
  sorting: LeadSorting,
  order: BaseOrder,
): FormattedLead[] => {
  if (sorting === 'UPDATED_AT') return sortByDate(leads, order, 'updatedAt');
  if (sorting === 'FIRST_NAME')
    return sortByString(leads, order, ['firstName']);
  if (sorting === 'LAST_NAME') return sortByString(leads, order, ['lastName']);
  if (sorting === 'EMAIL') return sortByString(leads, order, ['email']);
  if (sorting === 'PHONE') return sortByString(leads, order, ['phone']);
  if (sorting === 'BUDGET_MIN') return sortByBudget(leads, order, 'min');
  if (sorting === 'BUDGET_MAX') return sortByBudget(leads, order, 'max');
  if (sorting === 'FINANCE_INTEREST')
    return sortByString(leads, order, ['interestInFinance']);
  if (sorting === 'STATUS') return sortByString(leads, order, ['currentState']);
  if (sorting === 'SOURCE') return sortByString(leads, order, ['source']);
  if (sorting === 'ID') return sortByString(leads, order, ['id']);
  return sortByDate(leads, order, 'updatedAt');
};

export const getNewOrder = (
  type: string | undefined,
  currentOrder: BaseOrder,
): BaseOrder => {
  if (!type) return 'NORMAL';
  if (type.includes('alternate'))
    return currentOrder === 'NORMAL' ? 'INVERSE' : 'NORMAL';
  return type.includes('descending') ? 'INVERSE' : 'NORMAL';
};

export const getNewSorting = (type?: string): LeadSorting => {
  if (type?.includes('updated')) return 'UPDATED_AT';
  if (type?.includes('first-name')) return 'FIRST_NAME';
  if (type?.includes('last-name')) return 'LAST_NAME';
  if (type?.includes('email')) return 'EMAIL';
  if (type?.includes('phone')) return 'PHONE';
  if (type?.includes('budget-min')) return 'BUDGET_MIN';
  if (type?.includes('budget-max')) return 'BUDGET_MAX';
  if (type?.includes('finance-interest')) return 'FINANCE_INTEREST';
  if (type?.includes('status')) return 'STATUS';
  if (type?.includes('source')) return 'SOURCE';
  if (type?.includes('id')) return 'ID';
  return 'UPDATED_AT';
};

export const filterLeadsForArchiveStatus = (
  leads: Enquiry[],
  archive = false,
): Enquiry[] =>
  leads.filter((lead) =>
    archive ? lead.archived === 'yes' : lead.archived === 'no',
  );

import type { Company, UserType } from '@kanda-libs/ks-frontend-services';

export const getCompanyDirectors = (users: UserType[]): UserType[] =>
  users.filter((user) => Boolean(user?.director_info));

export const getCompanyDirectorsNotUpdated = (
  directors: UserType[],
): UserType[] =>
  directors.filter(
    (director) =>
      director?.email !== 'no-reply@kanda.co.uk' &&
      director?.director_info?.verification_status === 'verified',
  );

export const checkCompanySetupInProgress = (company?: Company): boolean => {
  if (!company) return false;
  const { lifecycle, users } = company;
  if (!lifecycle) return false;
  if (['registered', 'onboarded'].includes(lifecycle)) return true;
  if (lifecycle === 'verified') return false;
  if (!users || users?.length === 0) return true;
  const directors = getCompanyDirectors(users);
  const notUpdated = getCompanyDirectorsNotUpdated(directors);
  return notUpdated.length === 0;
};

import { useIsDesktop, type IconProps } from '@kanda-libs/ks-design-library';
import { useLeadContext } from 'pages/Lead/context';
import {
  formatBudget,
  getInterestInFinance,
  type InterestInFinance,
} from 'pages/Leads/helpers';
import { useMemo } from 'react';
import { formatWorkTypeToString } from 'utils';
import { ICON_PROPS, SEND_QUOTE_STATUSES } from './constants';
import { getLabel } from './helpers';

export interface LeadQuoteDetailsHook {
  iconProps: IconProps;
  label: string;
  budget: string;
  workType: string;
  interestInFinance: InterestInFinance;
  showButton: boolean;
  isLoading: boolean;
  isSubmitting: boolean;
}

export default function useLeadQuoteDetails(): LeadQuoteDetailsHook {
  const { lead, archived, isLoading, isSubmitting } = useLeadContext();

  const currentStatus = useMemo(() => lead?.current_state, [lead]);

  const isDesktop = useIsDesktop();

  const iconProps: IconProps = useMemo(() => {
    const size = isDesktop ? 64 : 20;
    if (isLoading || !currentStatus)
      return {
        size,
        icon: 'send',
        stroke: 'green-500',
      };
    if (archived)
      return {
        size,
        icon: 'archive',
        stroke: 'orange-200',
      };
    const baseProps = ICON_PROPS[currentStatus];
    return {
      size,
      ...baseProps,
    };
  }, [isLoading, isDesktop, archived, currentStatus]);

  const label = useMemo(() => {
    if (archived) return 'Lead Archived';
    return getLabel(currentStatus);
  }, [archived, currentStatus]);

  const budget = useMemo(
    () => formatBudget(lead?.enquiry_info?.budget),
    [lead],
  );

  const workType = useMemo(() => {
    const leadWorkType = lead?.enquiry_info?.job_info?.work_type;
    if (!leadWorkType) return 'Not answered';
    return formatWorkTypeToString(leadWorkType);
  }, [lead]);

  const interestInFinance = useMemo(
    () => getInterestInFinance(lead?.enquiry_info?.interest_in_credit),
    [lead],
  );

  const showButton = useMemo(
    () =>
      Boolean(
        lead?.current_state &&
          SEND_QUOTE_STATUSES.includes(lead.current_state) &&
          !archived,
      ),
    [lead, archived],
  );

  return {
    iconProps,
    label,
    budget,
    workType,
    interestInFinance,
    showButton,
    isLoading,
    isSubmitting,
  };
}

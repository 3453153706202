import { Layout } from '@kanda-libs/ks-design-library';
import LeadsBanner from '../components/Banner';
import LeadHeaderMobile from './components/Header';
import useLeadsMobile from './useLeadsMobile';

const LeadsMobile: React.FunctionComponent = function () {
  const { Content } = useLeadsMobile();
  return (
    <Layout
      stickyFooter
      noBorder
      stickyHeader
      scrollTop
      header={<LeadHeaderMobile />}
    >
      <LeadsBanner />
      {Content && <Content />}
    </Layout>
  );
};

export default LeadsMobile;

import { useFormContext, useWatch } from '@kanda-libs/ks-component-ts';
import { useCallback, useEffect, useMemo, useState } from 'react';

import useCurrentCompany from '../../../../../../../../../../../hooks/useCurrentCompany';
import useTradeBodyNumberValidation from '../../../../../../../../../../../hooks/useTradeBodyNumberValidation';
import { TITLES } from './constants';

export default function useTradeInfo() {
  const [showTradeBodyFields, setShowTradeBodyFields] = useState<
    string | undefined
  >(undefined);

  const { company } = useCurrentCompany();

  const { setValue } = useFormContext();

  const [tradeType, tradeBody, formSector] = useWatch({
    name: [
      'company.company_info.trade_type',
      'company.company_info.trade_body',
      'company.business_config.business_sector',
    ],
  });

  const tradeNumberValidation = useTradeBodyNumberValidation();

  const isSoleTrader = useMemo(() => {
    if (!company) return false;
    return company?.company_type === 'sole_trader';
  }, [company]);

  const otherTradeType = useMemo(() => {
    if (!tradeType) return false;
    if (tradeType !== 'other_trade') return false;
    return true;
  }, [tradeType]);

  const otherTradeBody = useMemo(() => {
    if (!tradeBody) return false;
    if (tradeBody !== 'other') return false;
    return true;
  }, [tradeBody]);

  const noneTradeBody = useMemo(() => {
    if (!tradeBody) return false;
    if (tradeBody !== 'none') return false;
    return true;
  }, [tradeBody]);

  const showTradeTypeFields = useMemo(() => {
    if (!company) return false;
    return !company?.business_config?.trade_type;
  }, [company]);

  const sector = useMemo(() => {
    const companySector = company?.business_config?.business_sector;
    if (companySector) return companySector;
    return formSector;
  }, [company, formSector]);

  const type = useMemo(() => {
    if (!sector) return 'HOME_IMPROVEMENT';
    return sector === 'Home improvement'
      ? 'HOME_IMPROVEMENT'
      : 'NON_HOME_IMPROVEMENT';
  }, [sector]);

  const workTypesTitle = useMemo(
    () => (type === 'HOME_IMPROVEMENT' ? TITLES.homeimprovement : TITLES.other),
    [type],
  );

  const onYesClick = useCallback(() => {
    if (showTradeBodyFields === 'yes') return;
    setShowTradeBodyFields('yes');
    setValue('company.company_info.trade_body', undefined);
  }, [setValue, showTradeBodyFields]);

  const onNoClick = useCallback(() => {
    if (showTradeBodyFields === 'no') return;
    setShowTradeBodyFields('no');
    setValue('company.company_info.trade_body', 'none');
  }, [setValue, showTradeBodyFields]);

  const showContinue = useMemo(() => Boolean(tradeBody), [tradeBody]);

  // Effect sets trade type name if not other
  useEffect(() => {
    if (!tradeType) return;
    if (tradeType === 'other_trade') {
      setValue('company.company_info.trade_type_name', '');
      return;
    }
    setValue('company.company_info.trade_type_name', tradeType);
  }, [tradeType, setValue]);

  // Effect sets trade body name if not other
  useEffect(() => {
    if (!tradeBody) return;
    if (tradeBody === 'other') {
      setValue('company.company_info.trade_body_name', '');
      return;
    }
    setValue('company.company_info.trade_body_name', tradeBody);
  }, [tradeBody, setValue]);

  useEffect(() => {
    if (!tradeBody) return;
    if (tradeBody !== 'none') return;
    setValue('company.company_info.trade_body_number', '');
  }, [tradeBody, setValue]);

  return {
    isLoading: !company,
    isSoleTrader,
    tradeNumberValidation,
    otherTradeType,
    otherTradeBody,
    noneTradeBody,
    showTradeTypeFields,
    showTradeBodyFields,
    onYesClick,
    onNoClick,
    showContinue,
    workTypesTitle,
  };
}

import { BreakPoints } from '@kanda-libs/ks-design-library';
import type { ReactNode } from 'react';
import OptionPopoverDesktop from './Desktop';
import OptionPopoverMobile from './Mobile';

export interface OptionPopoverProps {
  children: ReactNode;
  popoverId: string;
}

const OptionPopover: React.FunctionComponent<OptionPopoverProps> = function ({
  children,
  popoverId,
}) {
  return (
    <BreakPoints
      desktop={
        <OptionPopoverDesktop popoverId={popoverId}>
          {children}
        </OptionPopoverDesktop>
      }
      mobile={
        <OptionPopoverMobile popoverId={popoverId}>
          {children}
        </OptionPopoverMobile>
      }
    />
  );
};

export default OptionPopover;

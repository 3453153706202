import { type FunctionComponent } from 'react';
import Highlighter from 'react-highlight-words';
import { type QueryType } from 'store/slices/query';
import { HIGHLIGHT_PROPS } from './constants';
import useTableCell from './useTableCell';

export interface TableCellProps {
  value: any;
  formatter?: (value: any) => string;
  queryType?: QueryType;
  className?: string;
  highlightClassName?: string;
}

const TableCell: FunctionComponent<TableCellProps> = function ({
  value,
  formatter = (rawValue: any) => String(rawValue),
  queryType = 'leads',
  className,
  highlightClassName,
}) {
  const { classNames, searchWords, toDisplay } = useTableCell(
    value,
    formatter,
    queryType,
    className,
    highlightClassName,
  );

  return (
    <div className={classNames.wrapper}>
      <Highlighter
        {...HIGHLIGHT_PROPS}
        highlightClassName={classNames.highlight}
        searchWords={searchWords}
        textToHighlight={toDisplay || ''}
        className={classNames.text}
      />
    </div>
  );
};

export default TableCell;

import type { StringIndexedObject } from '@kanda-libs/ks-component-ts';
import { type FuseResult } from 'fuse.js';
import { useEffect } from 'react';
import { type QueryType } from 'store/slices/query';
import { useDebounce } from 'use-debounce';
import { useFuse } from './useFuse';
import useQuery from './useQuery';

export interface SearchQueryHook<T> {
  hits: FuseResult<T>[];
  query: string;
  setQuery: (query: string) => void;
}

export default function useSearchQuery<T>(
  data: T[],
  queryType: QueryType,
  searchOptions: StringIndexedObject,
  debounce = 500,
): SearchQueryHook<T> {
  const { fetchedQuery: query, onSetQuery: setQuery } = useQuery(queryType);

  const { hits, handleSearch: fuseSetQuery } = useFuse(data, searchOptions, {
    initialQuery: '',
  });
  const [debouncedQuery] = useDebounce(query, debounce);

  /**
   * Update query when debouncedQuery is updated
   */
  useEffect(() => {
    fuseSetQuery(debouncedQuery || '');
  }, [debouncedQuery, fuseSetQuery, setQuery]);

  return {
    hits,
    query: debouncedQuery,
    setQuery,
  };
}

import { useMemo } from 'react';
import { CLASS_NAMES } from './constants';

export interface UpdatedAtClassNames {
  wrapper: string;
  text: string;
}

export interface UpdatedAtCellPropsHook {
  timestamp: number;
  classNames: UpdatedAtClassNames;
}

export default function useTableUpdatedAt(
  date: Date,
  textClassName?: string,
): UpdatedAtCellPropsHook {
  const timestamp = useMemo(() => new Date(date).getTime(), [date]);

  const classNames = useMemo(
    () => ({
      wrapper: CLASS_NAMES.wrapper,
      text: textClassName || CLASS_NAMES.text,
    }),
    [textClassName],
  );

  return { timestamp, classNames };
}

import { toPng } from 'html-to-image';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type RefObject,
} from 'react';

export interface TagBannerHook {
  dataUrl: string | undefined;
  ref: RefObject<HTMLDivElement>;
  onClick: () => void;
}

const handleRefToPng = async (
  element: HTMLDivElement,
  callback: (dataUrl: string) => void,
): Promise<void> => {
  const dataUrl = await toPng(element, { cacheBust: false });
  if (!dataUrl) return;
  callback(dataUrl);
};

export default function useTagBanner(url: string, id: string): TagBannerHook {
  const [dataUrl, setDataUrl] = useState<string>();

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!ref.current || !url) return;
    const element = ref.current;
    setTimeout(() => {
      handleRefToPng(element, setDataUrl);
    }, 100);
  }, [url]);

  const onClick = useCallback(() => {
    if (!dataUrl) return;
    const link = document.createElement('a');
    link.download = `kanda-banner-link-${id}.png`;
    link.href = dataUrl;
    link.click();
  }, [dataUrl, id]);

  return { dataUrl, ref, onClick };
}

import type { IconProps } from '@kanda-libs/ks-design-library';
import { EnquiryState } from '@kanda-libs/ks-frontend-services';

export const ICON_PROPS: Record<EnquiryState, IconProps> = {
  viewed: {
    icon: 'circle-cross',
    stroke: 'red-200',
  },
  contact_only: {
    icon: 'circle-cross',
    stroke: 'red-200',
  },
  contact_agreed: {
    icon: 'send',
    stroke: 'green-500',
  },
  preferences_set: {
    icon: 'send',
    stroke: 'green-500',
  },
  ask_for_quote: {
    icon: 'send',
    stroke: 'green-500',
  },
  submitted: {
    icon: 'send',
    stroke: 'green-500',
  },
  quoted: {
    icon: 'check-circle',
    stroke: 'green-500',
  },
  converted_to_job: {
    icon: 'check-circle',
    stroke: 'green-500',
  },
  not_interested: {
    icon: 'circle-cross',
    stroke: 'red-200',
  },
  unreachable: { icon: 'circle-cross', stroke: 'red-200' },
  other_reason: { icon: 'circle-cross', stroke: 'red-200' },
};

export const SEND_QUOTE_STATUSES: EnquiryState[] = [
  'contact_agreed',
  'preferences_set',
  'ask_for_quote',
  'submitted',
];

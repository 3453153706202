import { Field, Form, FormTheme } from '@kanda-libs/ks-component-ts';
import { Button } from '@kanda-libs/ks-design-library';
import { type QueryType } from 'store/slices/query';
import useSearchBarForm from './useSearchBarForm';

export interface SearchBarProps {
  queryType: QueryType;
  isLoading?: boolean;
}

const SearchBar: React.FunctionComponent<SearchBarProps> = function ({
  queryType,
  isLoading = false,
}) {
  const { form, onSubmit, onClick } = useSearchBarForm(queryType);
  return (
    <FormTheme variant="streamline">
      <Form
        id="search-bar-form"
        form={form}
        onSubmit={onSubmit}
        className="flex flex-row -mb-5 w-full"
      >
        <Field.Input name="search" icon="search" isLoading={isLoading} />
        <Button.Icon
          id="search-remove"
          icon="close"
          className="min-w-10 min-h-10 md:-mt-0.75 ml-3"
          onClick={onClick}
          isLoading={isLoading}
        />
      </Form>
    </FormTheme>
  );
};

export default SearchBar;

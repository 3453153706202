import { Icon } from '@kanda-libs/ks-design-library';
import { COPY } from './constants';
import useNoRates from './useNoRates';

const NoRates: React.FunctionComponent = function () {
  const { noWorkType } = useNoRates();
  return (
    <div className="flex flex-row gap-x-4">
      <Icon
        icon="warning"
        size={24}
        className="min-w-6 min-h-6 mt-1.5 mb-auto"
        stroke="orange-200"
      />
      <p className="text-14-22-em text-neutral-600">
        {noWorkType ? COPY.noWorkType : COPY.invalid}
      </p>
    </div>
  );
};

export default NoRates;

import { useAmplitude } from '@kanda-libs/ks-amplitude-provider';
import {
  ModalsWrapperContext,
  type StringIndexedObject,
} from '@kanda-libs/ks-component-ts';
import {
  FirebaseAuthService,
  useCurrentUser,
  useMutate,
} from '@kanda-libs/ks-frontend-services';
import { useCallback, useContext, useMemo } from 'react';

import { useActiveLinks } from '../../hooks';
import useCurrentCompany from '../../hooks/useCurrentCompany';
import useMe from '../../hooks/useMe';
import { STAFF_IMITATION_MODAL_ID } from './components/StaffImitationModal/constants';
import {
  DESKTOP_ADMIN_ITEMS,
  DESKTOP_COMPANY_ITEMS,
  DESKTOP_MENU_ADMIN_ITEMS,
  DESKTOP_MENU_ITEMS,
  DESKTOP_VERIFICATION_ITEM,
  STAFF_DESKTOP_COMPANY_ITEM,
} from './DesktopHeader-constants';

const ContainerComponent = ({ children }) => {
  const { mutate } = useMutate(FirebaseAuthService.logout);
  const { showModal } = useContext(ModalsWrapperContext);

  const {
    company,
    isLoading: companyIsValidating,
    directorsAreVerified,
  } = useCurrentCompany();
  const { isValidating: isUserLoggedInIsValidating } = useCurrentUser();

  const { me, isValidating: meIsValidating } = useMe();

  const { reset } = useAmplitude();

  const desktopMenuItems = useMemo(() => {
    const role = me?.role;
    if (!role) return DESKTOP_MENU_ITEMS;
    const isStaff = role === 'staff';
    const isAdmin = !['company-staff', 'company-manager'].includes(role);
    if (!isAdmin && !isStaff) return DESKTOP_MENU_ITEMS;
    return [...DESKTOP_MENU_ADMIN_ITEMS, ...DESKTOP_MENU_ITEMS];
  }, [me]);

  const items = useActiveLinks(desktopMenuItems);

  const name = me?.name || 'My Kanda';

  const companyLogo = company?.company_logo;
  const photoURL = me?.photoURL;

  const img = companyLogo || photoURL || null;

  const logout = useCallback(() => {
    reset();
    mutate();
  }, [reset, mutate]);

  const isLoading =
    isUserLoggedInIsValidating || meIsValidating || companyIsValidating;

  const showNotice = useMemo(() => {
    if (isLoading) return false;
    return !(company as StringIndexedObject)?.subscription;
  }, [isLoading, company]);

  const companyItems = useMemo(() => {
    const role = me?.role;
    if (!role) return [];
    const isStaff = role === 'staff';
    const isAdmin = !['company-staff', 'company-manager'].includes(role);
    if (!isStaff)
      return [
        ...DESKTOP_COMPANY_ITEMS,
        ...(isAdmin ? DESKTOP_ADMIN_ITEMS : []),
        ...(!directorsAreVerified && isAdmin
          ? [DESKTOP_VERIFICATION_ITEM]
          : []),
      ];
    return [
      ...DESKTOP_COMPANY_ITEMS,
      ...DESKTOP_ADMIN_ITEMS,
      DESKTOP_VERIFICATION_ITEM,
      {
        ...STAFF_DESKTOP_COMPANY_ITEM,
        onClick: () => {
          showModal(STAFF_IMITATION_MODAL_ID);
        },
      },
    ];
  }, [me, showModal, directorsAreVerified]);

  return children({
    name,
    img,
    items,
    companyItems,
    isLoading,
    logout,
    showNotice,
  });
};

ContainerComponent.displayName = 'DesktopHeader-container';

export default ContainerComponent;

import FinanceRatesContent from './Content';
import FinanceRatesProvider from './context';

export interface FinanceRatesProps {
  showStoredRates?: boolean;
}

const FinanceRates: React.FunctionComponent<FinanceRatesProps> = function ({
  showStoredRates = false,
}) {
  return (
    <FinanceRatesProvider showStoredRates={showStoredRates}>
      <FinanceRatesContent />
    </FinanceRatesProvider>
  );
};

export default FinanceRates;

import {
  ConfigWrapper,
  type HomeLinkProps,
} from '@kanda-libs/ks-design-library';
import { URLS } from 'config';
import { type FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import DesktopHeader from '../DesktopHeader';
import Footer from './Footer';

export interface ContactConfigWrapper {
  children: JSX.Element;
}

const ContactConfigWrapper: FunctionComponent<ContactConfigWrapper> =
  function ({ children }) {
    return (
      <ConfigWrapper
        desktopHeader={DesktopHeader}
        linkComponent={Link as FunctionComponent<HomeLinkProps>}
        desktopFooter={Footer}
        homeLinkProps={{
          to: URLS.home,
        }}
      >
        {children}
      </ConfigWrapper>
    );
  };

export default ContactConfigWrapper;

import { Icon } from '@kanda-libs/ks-design-library';

export interface NewTagFormButtonProps {
  onAddClick: () => void;
}

const NewTagFormButton: React.FunctionComponent<NewTagFormButtonProps> =
  function ({ onAddClick }) {
    return (
      <button
        type="button"
        onClick={onAddClick}
        id="new-tag-form-add"
        className="w-full bg-neutral-000 hover:bg-green-100 border border-neutral-300 hover:border-green-500 border-dashed hover:border-solid px-4 py-5 rounded-lg flex flex-row gap-x-1 justify-center shadow-card text-neutral-700 hover:text-green-500 transition-all"
      >
        <p className="text-16-24-em my-auto">Add New Link</p>
        <Icon icon="plus" size={24} className="my-auto" />
      </button>
    );
  };

export default NewTagFormButton;

import type { Job, JobItem } from '@kanda-libs/ks-frontend-services';
import { cleanObject } from '../../../../utils';

const checkStandardItem = (item: JobItem): boolean =>
  [item?.title, item?.price?.amount].filter(Boolean).length > 2;

const standardItemChecker = (items: JobItem[]): boolean =>
  items.map((item: JobItem) => checkStandardItem(item)).some((val) => val);

const checkCustomItem = (item: JobItem): boolean =>
  Boolean(item?.quote_document?.content);

const customItemChecker = (items: JobItem[]): boolean =>
  items.map((item) => checkCustomItem(item)).some((val) => val);

export const shouldShowSave = (values) => {
  const job: Job = values?.job;
  if (!job) return false;
  const type = values?.jobType;
  const itemChecker =
    type === 'custom' ? customItemChecker : standardItemChecker;

  const itemCheck = itemChecker(job.job_items);
  // Check at least title and one valid item
  const conditions = [Boolean(job?.title), itemCheck].filter(Boolean).length;
  if (conditions < 2) return false;
  return true;
};

export const formatPayload = (values) => {
  const items = values?.job?.job_items?.map((item) => ({
    ...item,
    ...(!item?.title && { title: 'No line item title added...' }),
    ...(!item?.price?.amount && {
      price: { amount: 0, currency: 'GBP' },
    }),
  }));
  const job = cleanObject({
    ...values?.job,
    job_items: items,
  });
  return job;
};

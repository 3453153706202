import type { ProgressStep } from '.';

export const getBarSize = (
  steps: ProgressStep[],
  isDesktop: boolean,
): string => {
  const someActive = steps.some((step) => step.active);
  if (!someActive) return '0%';
  const numItems = steps.length;
  const minActive = steps.findIndex((step) => step.active);
  if (minActive === 0) return isDesktop ? '20px' : '8px';
  if (minActive === numItems - 1) return '100%';
  const stepPercentage = 100 / numItems;
  const midOffset = stepPercentage / 2;
  return `${stepPercentage * minActive + midOffset}%`;
};

import type {
  AuthUser,
  Company,
  Enrolment,
  FinanceRate,
  InfoWorkType,
} from '@kanda-libs/ks-frontend-services';
import { useCurrentCompany, useMe } from 'hooks';
import {
  createContext,
  useContext,
  useMemo,
  type FunctionComponent,
  type ReactNode,
} from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { getFinanceRateFullInfoKey } from 'utils';

export interface MyAccountFinanceRatesContextType {
  companyRates: FinanceRate[] | undefined;
  availableRates: FinanceRate[] | undefined;
  enrolment: Enrolment | undefined;
  company: Company | undefined;
  me: AuthUser | undefined;
  cid: string | undefined;
  isLoading: boolean;
  isSubmitting: boolean;
}

export const MyAccountFinanceRatesContext =
  createContext<MyAccountFinanceRatesContextType>({
    companyRates: undefined,
    availableRates: undefined,
    enrolment: undefined,
    company: undefined,
    me: undefined,
    cid: undefined,
    isLoading: false,
    isSubmitting: false,
  });

export const useMyAccountFinanceRatesContext = () =>
  useContext(MyAccountFinanceRatesContext);

export interface MyAccountFinanceRatesProviderProps {
  children: ReactNode;
}

const extractAvailableRates = (
  infoWorkTypes: InfoWorkType[],
): FinanceRate[] | undefined => {
  if (!infoWorkTypes) return undefined;
  const allRates = infoWorkTypes
    .map((infoWorkType) => infoWorkType.rates)
    .flat();
  const rateKeys = allRates.map((rate) => getFinanceRateFullInfoKey(rate));
  const uniqueKeyIndexes = rateKeys.map(
    (key, index, keys) => keys.indexOf(key) === index,
  );
  return allRates.filter((_, index) => uniqueKeyIndexes[index]);
};

const MyAccountFinanceRatesProvider: FunctionComponent<MyAccountFinanceRatesProviderProps> =
  function ({ children }) {
    const infoWorkTypes = useSelector(selectors.infoWorkType.getRawResponse);
    const infoWorkTypesLoading = useSelector(
      selectors.infoWorkType.getIsLoading,
    );

    const enrolments = useSelector(selectors.enrolment.getEntities);
    const enrolmentsLoading = useSelector(selectors.enrolment.getIsLoading);

    const isSubmitting = useSelector(selectors.enrolment.getIsSubmitting);

    const { company, isLoading: companyIsLoading } = useCurrentCompany();
    const { me } = useMe();

    const isLoading = useMemo(
      () => infoWorkTypesLoading || enrolmentsLoading || companyIsLoading,
      [infoWorkTypesLoading, enrolmentsLoading, companyIsLoading],
    );

    const cid = useMemo(() => company?.cid, [company]);

    const companyRates = useMemo(() => {
      if (!company) return undefined;
      return company?.finance_rates;
    }, [company]);

    const availableRates = useMemo(() => {
      if (!infoWorkTypes) return undefined;
      return extractAvailableRates(infoWorkTypes as InfoWorkType[]);
    }, [infoWorkTypes]);

    const enrolment = useMemo(() => {
      if (!cid) return undefined;
      return enrolments?.[cid];
    }, [cid, enrolments]);

    const values = useMemo(
      () => ({
        companyRates,
        availableRates,
        enrolment,
        company: company || undefined,
        me,
        cid,
        isLoading,
        isSubmitting,
      }),
      [
        companyRates,
        availableRates,
        enrolment,
        company,
        me,
        cid,
        isLoading,
        isSubmitting,
      ],
    );

    return (
      <MyAccountFinanceRatesContext.Provider value={values}>
        {children}
      </MyAccountFinanceRatesContext.Provider>
    );
  };

export default MyAccountFinanceRatesProvider;

import { Field } from '@kanda-libs/ks-component-ts';
import { WorkTypeModal } from 'pages/CreateJob/components';
import type { FunctionComponent } from 'react';
import AddWorkTypesButton from './AddWorkTypesButton';
import { EXAMPLE_COPY, NO_RATES_LABEL } from './constants';
import StandardLabel from './StandardLabel';
import useWorkTypeField from './useWorkTypeField';

const WorkTypeField: FunctionComponent = function () {
  const { props, example, showNoRates, filterableSelect } = useWorkTypeField();
  return (
    <>
      {example && (
        <div className="flex flex-col text-14-22 px-4 py-2 bg-lavender-100 text-lavender-200 border border-lavender-200 rounded-xl mb-4">
          <p className="text-14-22-em mb-1">Job Type</p>
          <p>{EXAMPLE_COPY}</p>
        </div>
      )}
      <Field.Validator
        validation={{
          required: { value: true, message: 'Please enter a work type' },
        }}
      >
        {filterableSelect ? (
          <Field.FilterableSelect
            {...props}
            label={<StandardLabel />}
            helperText={<AddWorkTypesButton />}
          />
        ) : (
          <Field.Select
            {...props}
            label={<StandardLabel />}
            placeholder="Select..."
            helperText={<AddWorkTypesButton />}
          />
        )}
      </Field.Validator>
      {!example && showNoRates && (
        <p className="p-4 mb-6 text-orange-200 border border-orange-200 border-solid rounded-xl text-neutral-600 amber-warning">
          {NO_RATES_LABEL}
        </p>
      )}
      <WorkTypeModal />
    </>
  );
};

export default WorkTypeField;

import type { EnquiryState } from '@kanda-libs/ks-frontend-services';

interface LeadProgressStep {
  text: string;
  states: EnquiryState[];
}

export const VALID_STEPS: EnquiryState[] = [
  'contact_agreed',
  'preferences_set',
  'ask_for_quote',
  'submitted',
  'converted_to_job',
];

export const STEPS: LeadProgressStep[] = [
  {
    text: 'Contact Requested',
    states: ['contact_agreed', 'preferences_set'],
  },
  {
    text: 'Quote Requested',
    states: ['ask_for_quote', 'submitted', 'quoted'],
  },
  {
    text: 'Completed',
    states: ['converted_to_job'],
  },
];

import { Table } from '@kanda-libs/ks-component-ts';
import useLeadsTable from './useLeadsTable';

const LeadsTable: React.FunctionComponent = function ({}) {
  const { tableProps } = useLeadsTable();
  return (
    <div className="-mx-8">
      <Table {...tableProps} />
    </div>
  );
};

export default LeadsTable;

import { URLS } from 'config';
import useQuery from 'hooks/useQuery';
import useSearchWords from 'hooks/useSearchWords';
import type { FormattedLead } from 'pages/Leads/helpers';
import { useMemo, type FunctionComponent, type ReactNode } from 'react';
import { generatePath } from 'react-router-dom';
import { CLASS_NAMES, type LeadCardClassNames } from './constants';
import DivWrapper from './DivWrapper';
import LinkWrapper from './LinkWrapper';

export interface WrapperProps {
  children: ReactNode;
  className?: string;
  to?: string;
}

export interface LeadCardHook {
  Wrapper: FunctionComponent<WrapperProps>;
  wrapperProps: Omit<WrapperProps, 'children'>;
  classNames: LeadCardClassNames;
  searchWords: string[];
}

export default function useLeadCard(
  lead: FormattedLead,
  isLoading = false,
): LeadCardHook {
  const { fetchedQuery: query } = useQuery('leads');
  const { searchWords } = useSearchWords(query);

  const Wrapper = useMemo(
    () => (isLoading ? DivWrapper : LinkWrapper),
    [isLoading],
  );

  const wrapperProps = useMemo(
    () => (isLoading ? {} : { to: generatePath(URLS.lead, { id: lead.id }) }),
    [isLoading, lead],
  );

  const classNames = useMemo(
    () => ({
      ...CLASS_NAMES,
      card: [CLASS_NAMES.card, isLoading ? 'bg-violet-100' : 'bg-neutral-000']
        .filter(Boolean)
        .join(' '),
    }),
    [isLoading],
  );

  return {
    Wrapper,
    wrapperProps,
    classNames,
    searchWords,
  };
}

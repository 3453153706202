export const CLASS_NAMES = {
  highlighter: 'text-green-300 bg-transparent',
  container: 'flex flex-row text-16-20 text-neutral-900 text-left',
  text: 'text-16-20-em',
  tag: 'mr-3',
};

export const HIGHLIGHT_PROPS = {
  highlightClassName: CLASS_NAMES.highlighter,
  autoEscape: true,
};

import DepositOptions from '../DepositOptions';
import { Header, Option } from './components';
import { CLASS_NAMES } from './Mobile-constants';
import Container from './Mobile-container';

const ViewComponent = () => (
  <Container>
    {({ options }) => (
      <>
        <DepositOptions />
        <div className={CLASS_NAMES.container}>
          <Header />
          {options &&
            options.map((optionProps) => (
              <Option key={optionProps.apr} {...optionProps} />
            ))}
        </div>
      </>
    )}
  </Container>
);

ViewComponent.displayName = 'PaymentMethods-Mobile-view';

export default ViewComponent;

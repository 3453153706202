import {
  DesktopLayoutDefault,
  DesktopLayoutDefaultContent,
  DesktopLayoutDefaultContentHeader,
} from '@kanda-libs/ks-design-library';

import {
  FinanceApplicationProgress,
  Items,
  JobHeader,
  Notes,
  PaymentAndFinanceOptions,
} from '../components';
import { CLASS_NAMES } from './Desktop-constants';

const ViewComponent = ({}) => (
  <DesktopLayoutDefault>
    <DesktopLayoutDefaultContentHeader>
      <JobHeader />
    </DesktopLayoutDefaultContentHeader>
    <DesktopLayoutDefaultContent>
      <div className={CLASS_NAMES.container}>
        <div className={CLASS_NAMES.content}>
          <Items />
          <Notes />
        </div>
        <div className={CLASS_NAMES.side}>
          <FinanceApplicationProgress />
          <PaymentAndFinanceOptions />
        </div>
      </div>
    </DesktopLayoutDefaultContent>
  </DesktopLayoutDefault>
);

ViewComponent.displayName = 'Job-Desktop-view';

export default ViewComponent;

import { Header, Layout } from '@kanda-libs/ks-design-library';
import type { ReactNode } from 'react';

export interface AddWorkTypeLayoutMobileProps {
  children: ReactNode;
}

const AddWorkTypeLayoutMobile: React.FunctionComponent<AddWorkTypeLayoutMobileProps> =
  function ({ children }) {
    return (
      <Layout noPadding noBorder header={<Header.Base lines help />}>
        <div className="px-6">{children}</div>
      </Layout>
    );
  };

export default AddWorkTypeLayoutMobile;

import { Widget } from '@kanda-libs/ks-component-ts';

import { Item } from './components';

import { BASE_ITEM } from '../../../../CreateJob-constants';

export interface CustomQuoteProps {
  refreshJobDetails?: () => void;
}

const ViewComponent: React.FunctionComponent<CustomQuoteProps> = ({
  refreshJobDetails,
}) => (
  <Widget.JobJobItemsArrayWrapper initialData={[BASE_ITEM]}>
    {({ arrayName, fields }) =>
      fields.map((field, index) => (
        <Item
          key={field.id}
          index={index}
          arrayName={arrayName}
          refreshJobDetails={refreshJobDetails}
        />
      ))
    }
  </Widget.JobJobItemsArrayWrapper>
);

ViewComponent.displayName = 'Createjob-Quote-Custom-view';

export default ViewComponent;

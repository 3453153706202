import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import type { FieldValues } from '../useSetupAccountForm';

export interface SetupAccountSubmitButtonHook {
  show: boolean;
  disabled: boolean;
}

export default function useSetupAccountSubmitButton(): SetupAccountSubmitButtonHook {
  const [tradeText] = useWatch<
    FieldValues,
    ['company.business_config.trade_text']
  >({
    name: ['company.business_config.trade_text'],
  });

  const show = useMemo(
    () => Boolean(tradeText && tradeText !== ''),
    [tradeText],
  );

  return { show, disabled: false };
}

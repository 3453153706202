import { Price, Text } from '@kanda-libs/ks-design-library';
import { useMemo } from 'react';

export interface AmountDisplayProps {
  title: string;
  amount: number | undefined;
  showFree?: boolean;
  emphasized?: boolean;
}

const AmountDisplay: React.FunctionComponent<AmountDisplayProps> = function ({
  title,
  amount,
  showFree = false,
  emphasized = false,
}) {
  const priceProps = useMemo(
    () => ({
      poundsClassName: emphasized
        ? 'text-green-400 text-20-24-em'
        : 'text-green-400 text-16-20-em',
      centsClassName: emphasized
        ? 'text-green-400 text-10-14-em mt-px'
        : 'text-green-400 text-10-14-em mt-px',
      wrapperClassName: 'ml-auto',
    }),
    [emphasized],
  );

  const textClassName = useMemo(
    () =>
      [
        'text-neutral-600 my-auto',
        emphasized
          ? 'text-14-22-em md:text-12-18-em'
          : 'text-14-22 md:text-12-18 text-light',
      ].join(' '),
    [emphasized],
  );

  const isFree = useMemo(() => showFree && amount === 0, [showFree, amount]);

  if (typeof amount === 'undefined') return null;

  return (
    <>
      <p className={textClassName}>{title}</p>
      {isFree ? (
        <Text text="Free" className={`${priceProps.poundsClassName} ml-auto`} />
      ) : (
        <Price amount={amount} {...priceProps} />
      )}
    </>
  );
};

export default AmountDisplay;

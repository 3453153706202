import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useToast } from '@kanda-libs/ks-design-library';
import {
  FirebaseAuthService,
  services,
  useCurrentUser,
  useSubmit,
} from '@kanda-libs/ks-frontend-services';

import { extractErrorMessage } from 'common/helpers';
import { URLS } from 'config';
import useQueryParam from 'hooks/useQueryParam';
import { serviceConvert } from 'utils';

type CodeState = 'valid' | 'invalid' | 'nocode';

interface CustomCodeHook {
  isLoading: boolean;
  codeState: CodeState;
}

function useCustomCode(): CustomCodeHook {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [codeState, setCodeState] = useState<CodeState>('valid');

  const { showError, showSuccess } = useToast();

  const [codeParam] = useQueryParam('code');
  const [continueURLParam] = useQueryParam('continue_url');
  const [emailParam] = useQueryParam('email');

  // Current user methods
  const {
    isUserLoggedIn,
    isValidating: loggedInIsValidating,
    logout,
    revalidate,
  } = useCurrentUser();

  // Get search from location
  // Get push from history
  const { push } = useHistory();

  const { submit: infoSession } = useSubmit(
    serviceConvert(services.infoAuth.infoSession),
  );
  const { submit: infoAuth } = useSubmit(
    serviceConvert(services.infoAuth.infoAuth),
  );

  // Get code from URL search paramaters
  const { code, continueURL, email } = useMemo(() => {
    if (!codeParam)
      return {
        code: undefined,
        continueURL: undefined,
        email: undefined,
      };
    // DEV_NOTE: remove this to test without 10 minute expiry
    // if (APP_ENV === 'qa') return `${codeParam}+qa`;
    return {
      code: codeParam || undefined,
      continueURL: continueURLParam || undefined,
      email: emailParam || undefined,
    };
  }, [codeParam, emailParam, continueURLParam]);

  const resendAuthEmail = useCallback(() => {
    if (!email) return;
    infoAuth({ body: { email, continue_url: continueURL } }).then(
      ({ error: infoAuthError }) => {
        if (infoAuthError) {
          const message = extractErrorMessage(infoAuthError);
          showError(
            message ||
              'Error resending new email - please contact Kanda at help@kanda.co.uk',
          );
        }
        showSuccess(
          'A new authorisation link has been sent in email to you - please follow this new link to continue to Kanda',
        );
      },
    );
  }, [email, continueURL, infoAuth, showError, showSuccess]);

  /**
   * Function logs user out, then logs them in with the code
   */
  const loginWithCode = useCallback(async () => {
    infoSession({ body: { code: code as string } }).then(
      async ({ data: sessionData, error: sessionError }) => {
        if (sessionError) {
          showError(
            'Session has expired - an email containing a new link has been sent',
          );
          setCodeState('invalid');
          resendAuthEmail();
          return;
        }
        const customToken = sessionData?.custom_token;
        if (!customToken) {
          showError(
            'Session has expired - an email containing a new link has been sent',
          );
          setCodeState('invalid');
          resendAuthEmail();
          return;
        }
        FirebaseAuthService.signInWithCustomToken(customToken, false).then(() =>
          revalidate().then(() => {
            setIsLoading(false);
            if (!continueURL) {
              push(URLS.home);
              return;
            }
            window.location.href = continueURL;
          }),
        );
      },
    );
  }, [
    code,
    push,
    infoSession,
    showError,
    revalidate,
    continueURL,
    resendAuthEmail,
  ]);

  /**
   * Function logs user out, then logs them in with the code
   */
  const logoutThenLoginWithCode = useCallback(
    async () => logout().then(() => revalidate().then(() => loginWithCode())),
    [logout, revalidate, loginWithCode],
  );

  // Refereece to hold whether tried logging in or not
  const loginRef = useRef(false);
  useEffect(() => {
    if (loginRef.current) return;
    // Check user is logged in
    if (isUserLoggedIn === null || loggedInIsValidating) return;
    // If no code, don't try logging in, set not loading
    if (!code) {
      setIsLoading(false);
      setCodeState('nocode');
      loginRef.current = true;
      return;
    }
    // If user isn't logged in, login with code
    if (!isUserLoggedIn) {
      loginWithCode();
      loginRef.current = true;
      return;
    }
    // If user is logged in, logout, then login with code
    logoutThenLoginWithCode();
    loginRef.current = true;
  }, [
    code,
    isUserLoggedIn,
    loggedInIsValidating,
    loginWithCode,
    logoutThenLoginWithCode,
  ]);

  return {
    isLoading,
    codeState,
  };
}

export default useCustomCode;

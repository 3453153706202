import { createSelector } from '@reduxjs/toolkit';
import { type RootState } from 'store';

export const getSorting = (state: RootState) => state.sorting;

export const getLeadsSorting = createSelector(
  getSorting,
  (state) => state.leads,
);

const sortingSelectors = {
  getSorting,
  getLeadsSorting,
};

export default sortingSelectors;

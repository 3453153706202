import { Button } from '@kanda-libs/ks-design-library';
import type { PopoverProps } from '..';
import PopoverMobileModal from './Modal';
import usePopoverMobile from './usePopoverMobile';

const PopoverMobile: React.FunctionComponent<PopoverProps> = function ({
  children,
  popoverId,
}) {
  const { onClick, buttonId, modalId } = usePopoverMobile(popoverId);
  return (
    <>
      <Button.Icon
        id={buttonId}
        onClick={onClick}
        icon="info"
        size="28-16"
        className="-my-0.75"
        iconProps={{
          className: 'w-4 cursor-pointer min-w-4 text-neutral-500',
          size: 16,
        }}
      />
      <PopoverMobileModal modalId={modalId}>{children}</PopoverMobileModal>
    </>
  );
};

export default PopoverMobile;

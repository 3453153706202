import { Form } from '@kanda-libs/ks-component-ts';
import { Button, Loader } from '@kanda-libs/ks-design-library';
import { WorkTypesSelect } from 'components';
import useAddWorkTypesForm from './useWorkTypesForm';

const WorkTypesForm: React.FunctionComponent = function () {
  const { form, onSubmit, isLoading, isSubmitting } = useAddWorkTypesForm();
  return (
    <Form
      form={form}
      onSubmit={onSubmit}
      id="add-work-types-form"
      className="flex flex-col w-full h-full text-left"
    >
      <WorkTypesSelect alwaysActive isLoading={isLoading} />
      <Button.Text
        id="add-work-types-form"
        submit
        label="Update Work Types"
        className="mt-4 mr-auto"
        disabled={isLoading || isSubmitting}
      />
      <Loader isLoading={isSubmitting} />
    </Form>
  );
};

export default WorkTypesForm;

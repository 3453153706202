import LeadCustomer from 'pages/Lead/components/LeadCustomer';
import LeadProgressBar from 'pages/Lead/components/LeadProgressBar';
import LeadQuoteDetails from 'pages/Lead/components/LeadQuoteDetails';

const LeadDesktopContent: React.FunctionComponent = function () {
  return (
    <div className="flex flex-col flex-1 -mt-8">
      <LeadProgressBar />
      <div className="grid grid-cols-[2fr_1fr] gap-x-24 w-full max-w-screen-lg mx-auto">
        <LeadQuoteDetails />
        <LeadCustomer />
      </div>
    </div>
  );
};

export default LeadDesktopContent;

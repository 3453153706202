import { Pagination } from '@kanda-libs/ks-design-library';
import { CLASS_NAMES } from './constants';
import LeadCard from './LeadCard';
import useLeadsList from './useLeadsList';

const LeadsList: React.FunctionComponent = function ({}) {
  const { data, showPagination, paginationProps, isLoading } = useLeadsList();
  return (
    <div className={CLASS_NAMES.container}>
      <div className={CLASS_NAMES.list}>
        {data.map((lead) => (
          <LeadCard key={lead.id} lead={lead} isLoading={isLoading} />
        ))}
      </div>
      {!isLoading && showPagination && (
        <div className={CLASS_NAMES.pagination}>
          <Pagination {...paginationProps} />
        </div>
      )}
    </div>
  );
};

export default LeadsList;

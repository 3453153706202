import { useFormContext, useWatch } from '@kanda-libs/ks-component-ts';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import { useEffect, useMemo } from 'react';

const ContainerComponent = ({ children, index, arrayName }) => {
  const { data } = useCreateJobContextValue();

  const initialFile = data?.droppedFile;

  const droppedFile = useWatch({ name: 'droppedFile' });

  const inputFile = initialFile || droppedFile || null;

  const titleName = useMemo(
    () => `${arrayName}.${index}.title`,
    [arrayName, index],
  );
  const quoteDocName = useMemo(
    () => `${arrayName}.${index}.quote_document`,
    [arrayName, index],
  );

  const { setValue } = useFormContext();

  useEffect(() => {
    setValue(titleName, 'quote document');
  }, [titleName, quoteDocName, setValue, inputFile]);

  useEffect(() => {
    setValue(`${quoteDocName}`, undefined);
  }, [quoteDocName, setValue, inputFile]);

  return children;
};

ContainerComponent.displayName = 'Createjob-Quote-Custom-Item-container';

export default ContainerComponent;

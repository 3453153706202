import { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import Context from '../Context';
import { JobsPlaceholder, NoResults, Table } from './components';

import { URLS } from '../../../config';

const ContainerComponent = ({ children }) => {
  const { isLoading, jobs, data, company, pdfIsSubmitting } =
    useContext(Context);

  const { push } = useHistory();

  const Content = useMemo(() => {
    if (isLoading) return Table;
    if (jobs?.length === 0) return JobsPlaceholder;
    if (jobs?.length !== 0 && data?.length === 0) return NoResults;
    return Table;
  }, [isLoading, jobs, data]);

  const onDrop = useCallback(
    (dropped) => {
      if (!dropped) return;
      const newQuote = dropped?.length ? dropped[0] : dropped;
      push(URLS.createJob, { newQuote });
    },
    [push],
  );

  const jobType = company?.quote_preference || null;

  const dropZoneProps = useMemo(
    () => ({
      disabled: jobType && jobType === 'kanda',
      onDrop,
    }),
    [jobType, onDrop],
  );

  return children({
    Content,
    dropZoneProps,
    pdfIsSubmitting,
  });
};

ContainerComponent.displayName = 'Jobs-Desktop-container';

export default ContainerComponent;

import AddWorkTypesContent from './Content';
import AddWorkTypeLayout from './Layout';

const AddWorkTypes: React.FunctionComponent = function ({}) {
  return (
    <AddWorkTypeLayout>
      <AddWorkTypesContent />
    </AddWorkTypeLayout>
  );
};

export default AddWorkTypes;

import type { StringIndexedObject } from '@kanda-libs/ks-component-ts';
import { useToast } from '@kanda-libs/ks-design-library';
import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import { BANNED_CIDS, URLS } from 'config';
import { useCurrentCompany } from 'hooks';
import { useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { urlWithParams } from 'utils';
import { BASE_DEPOSIT, SOLAR_DEPOSIT } from './CreateJob-constants';
import {
  formatDuplicate,
  formatExampleDraft,
  getJobType,
} from './CreateJob-functions';
import useFetchDraft from './CreateJob-useFetchDraft';

export interface ContainerComponentChildrenArgs {
  initialData: StringIndexedObject;
}

export interface ContainerComponentProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({ children }) => {
  const { company } = useCurrentCompany();

  const { draft, hasDraft, isValidating } = useFetchDraft();

  const { showWarning } = useToast();
  const { push, location } = useHistory();
  const { state } = useLocation();

  const jobType = useMemo(() => getJobType(company, draft), [company, draft]);

  // Get dropped file from home if dropped
  const droppedFile = state?.newQuote;
  // Get job data to duplicate if clicked
  const duplicate = state?.duplicate;
  // Get job data to duplicate & archived if clicked
  const duplicateArchive = state?.duplicate;

  const solarJobType = useMemo(() => {
    if (!company) return 'standard';
    if (!company?.solar_company_info) return 'standard';
    return 'solar';
  }, [company]);

  const deposit = useMemo(() => {
    if (!company) return BASE_DEPOSIT;
    if (!company?.solar_company_info) return BASE_DEPOSIT;
    return SOLAR_DEPOSIT;
  }, [company]);

  const initialJobData = useMemo(() => {
    if (hasDraft && !draft) return undefined;
    if (draft) {
      if (draft?.flow_type === 'live')
        return {
          ...draft,
        };
      return { ...formatExampleDraft(draft) };
    }
    if (!company)
      return {
        ...deposit,
        checkout_options: ['decline_job'],
        job_type: solarJobType,
      };
    if (duplicate || duplicateArchive)
      return formatDuplicate(duplicate || duplicateArchive, company);
    return {
      ...deposit,
      checkout_options: ['decline_job'],
      job_type: solarJobType,
    };
  }, [
    hasDraft,
    draft,
    duplicate,
    duplicateArchive,
    solarJobType,
    deposit,
    company,
  ]);

  const draftIsLoading = useMemo(() => hasDraft && !draft, [hasDraft, draft]);

  const initialData = useMemo(
    () => ({
      isLoading: !company || !!isValidating || draftIsLoading,
      company,
      jobType,
      job: initialJobData,
      ...(jobType !== 'kanda' && { droppedFile }),
    }),
    [
      draftIsLoading,
      company,
      jobType,
      droppedFile,
      isValidating,
      initialJobData,
    ],
  );

  const missingRateRef = useRef(false);
  useEffect(() => {
    if (missingRateRef.current) return;
    if (!duplicate || !duplicateArchive || !company) return;
    const formatted = formatDuplicate(duplicate || duplicateArchive, company);
    const formattedRates = formatted.finance_options;
    const secondLine = duplicate.finance_options.some(
      (rate: FinanceRate) => !rate.enabled,
    );
    const duplicateRates = secondLine
      ? duplicate.finance_options.filter((rate: FinanceRate) => !rate.enabled)
      : duplicate.finance_options;
    if (formattedRates?.length === duplicateRates.length) {
      missingRateRef.current = true;
      return;
    }
    missingRateRef.current = true;
    showWarning(
      'Some APRs on this job are no longer available, please select new rates',
    );
  }, [duplicate, duplicateArchive, showWarning, company]);

  useEffect(() => {
    if (!company) return;
    const cid = company?.cid;
    if (!cid) return;
    const banned = BANNED_CIDS.includes(cid);
    if (banned) push(URLS.jobs);
  }, [push, company]);

  useEffect(() => {
    if (!hasDraft || !draft) return;
    const { flow_type: flowType, id = '' } = draft;
    const isExampleUrl = location.pathname.includes('create-example-job');
    if (flowType === 'example' && !isExampleUrl) {
      push(urlWithParams(URLS.createExampleJob, { id }));
      return;
    }
    if (flowType === 'live' && isExampleUrl) {
      push(urlWithParams(URLS.createJob, { id }));
    }
  }, [draft, hasDraft, location, push]);

  return children({ initialData });
};

ContainerComponent.displayName = 'CreateJob-container';

export default ContainerComponent;

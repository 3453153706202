import { APP_ENV } from 'config';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { removeTrailingWhitespace, removeWhitespaceAndNewline } from 'utils';

export interface MeFormHook {
  showForm: boolean;
  onNameBlur: (name: string) => void;
  onContactBlur: (name: string) => void;
}

export default function useMeForm(): MeFormHook {
  const me = useSelector(selectors.getUser);
  const { getValues, setValue } = useFormContext();

  const onNameBlur = useCallback(
    (name: string) => setValue(name, removeTrailingWhitespace(getValues(name))),
    [setValue, getValues],
  );

  const onContactBlur = useCallback(
    (name: string) =>
      setValue(name, removeWhitespaceAndNewline(getValues(name))),
    [setValue, getValues],
  );

  const showForm = useMemo(() => {
    if (!me) return false;
    return !me?.phone && APP_ENV !== 'qa';
  }, [me]);

  return { showForm, onNameBlur, onContactBlur };
}

import type { SelectOption } from '@kanda-libs/ks-component-ts';
import { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { BASE_CONFIGS, type BaseConfig } from '../../constants';
import { matchConfigFromTradeText } from '../../helpers';

export interface FilterableSelectProps {
  options: SelectOption[];
}

export default function useFilterableSelect(): FilterableSelectProps {
  const { setValue } = useFormContext();

  const [tradeText] = useWatch({
    name: ['company.business_config.trade_text'],
  });

  const options = useMemo(
    () =>
      BASE_CONFIGS.map((config: BaseConfig) => ({
        name: config.trade_text,
        value: config.trade_text,
      })).sort((option1: SelectOption, option2: SelectOption) => {
        if (option1.value.toLocaleLowerCase().includes('not listed')) return 1;
        if (option2.value.toLocaleLowerCase().includes('not listed')) return -1;
        return option1.value.localeCompare(option2.value);
      }),
    [],
  );

  useEffect(() => {
    if (!tradeText) return;
    const config = matchConfigFromTradeText(tradeText);
    if (!config) return;
    setValue('company.business_config.business_sector', config.business_sector);
    setTimeout(() => {
      setValue('company.business_config.trade_text', config.trade_text);
    }, 10);
  }, [tradeText, setValue]);

  return {
    options,
  };
}

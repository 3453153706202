import { useCallback, useState } from 'react';

export interface NewTagFormButtonHook {
  showForm: boolean;
  onAddClick: () => void;
  onCancelClick: () => void;
}

export default function useNewTagFormButton(): NewTagFormButtonHook {
  const [showForm, setShowForm] = useState<boolean>(false);

  const onAddClick = useCallback(() => setShowForm(true), []);

  const onCancelClick = useCallback(() => setShowForm(false), []);

  return {
    showForm,
    onAddClick,
    onCancelClick,
  };
}

import { BreakPoints } from '@kanda-libs/ks-design-library';
import { Helmet } from 'components';
import MyAccountAlwaysOnLinkProvider from './context';
import MyAccountAlwaysOnLinkDesktop from './Desktop';
import MyAccountAlwaysOnLinkMobile from './Mobile';

const MyAccountAlwaysOnLink: React.FunctionComponent = function () {
  return (
    <MyAccountAlwaysOnLinkProvider>
      <Helmet title="Team Settings" />
      <BreakPoints
        mobile={<MyAccountAlwaysOnLinkMobile />}
        desktop={<MyAccountAlwaysOnLinkDesktop />}
      />
    </MyAccountAlwaysOnLinkProvider>
  );
};

export default MyAccountAlwaysOnLink;

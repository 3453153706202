import { useForm, type UseFormReturn } from '@kanda-libs/ks-component-ts';
import {
  useToast,
  type StringIndexedObject,
} from '@kanda-libs/ks-design-library';
import {
  actions,
  type Company,
  type CompanyInfo,
  type Job,
  type WorkType,
} from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { URLS } from 'config';
import { pipe } from 'fp-ts/lib/function';
import { useCurrentCompany } from 'hooks';
import useApiError from 'hooks/useApiError';
import useReloadInitialData from 'hooks/useReloadInitialData';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectors } from 'store';
import { changeRenamedWorkTypes, filterDeprecated } from 'utils';

export interface AddWorkTypesFormValues {
  work_types: WorkType[] | undefined;
  work_types__selected: string | undefined;
}

export interface AddWorkTypesFormHook {
  form: UseFormReturn<AddWorkTypesFormValues>;
  onSubmit: (formValues: StringIndexedObject<AddWorkTypesFormValues>) => void;
  isLoading: boolean;
  isSubmitting: boolean;
}

export const getWorkTypes = (jobs: Job[]): WorkType[] =>
  jobs.map((job) => job.work_type).filter((type) => type !== undefined);

export default function useAddWorkTypesForm(): AddWorkTypesFormHook {
  const jobs = useSelector(selectors.job.getEntitiesAsArray);
  const hasFetched = useSelector(selectors.job.getHasFetched);
  const isSubmitting = useSelector(selectors.company.getIsSubmitting);
  const reloadData = useReloadInitialData();
  const { push } = useHistory();

  const dispatch = useAppDispatch();
  const { company } = useCurrentCompany();
  const onError = useApiError(
    'Error updating company info at this time - please try again later',
  );
  const { showSuccess } = useToast();

  const defaultValues = useMemo(() => {
    if (!hasFetched)
      return {
        work_types: undefined,
        work_types__selected: undefined,
      };
    const previousWorkTypes = pipe(
      jobs,
      getWorkTypes,
      filterDeprecated,
      changeRenamedWorkTypes,
      (types) => types.filter((type) => type !== undefined),
      (types) => types.filter((v, i, a) => v && a.indexOf(v) === i),
    );
    if (!previousWorkTypes || previousWorkTypes.length === 0)
      return {
        work_types: undefined,
        work_types__selected: undefined,
      };
    return {
      work_types: previousWorkTypes,
      work_types__selected: previousWorkTypes.join(';'),
    };
  }, [hasFetched, jobs]);

  const form = useForm<AddWorkTypesFormValues>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
  });

  const onSubmit = useCallback(
    (formValues: StringIndexedObject<AddWorkTypesFormValues>) => {
      const cid = company?.cid;
      if (!cid) return;
      const fv = formValues as unknown as AddWorkTypesFormValues;
      const body: Company = {
        ...company,
        company_info: {
          ...(company.company_info as CompanyInfo),
          work_types: fv.work_types,
        },
      };
      dispatch(
        actions.putCompany({
          body,
          params: {
            id: cid,
          },
          onError,
          onSuccess: () => {
            showSuccess('Work types updated!');
            reloadData();
            push(URLS.home);
          },
        }),
      );
    },
    [company, dispatch, onError, reloadData, push, showSuccess],
  );

  const workTypesRef = useRef<boolean>(false);
  useEffect(() => {
    if (!hasFetched || workTypesRef.current) return;
    form.reset(defaultValues);
    workTypesRef.current = true;
  }, [defaultValues, form, hasFetched, workTypesRef]);

  return {
    form,
    onSubmit,
    isLoading: !hasFetched,
    isSubmitting,
  };
}

import useQuery from 'hooks/useQuery';
import useSearchWords from 'hooks/useSearchWords';
import { useMemo } from 'react';
import { type QueryType } from 'store/slices/query';
import { CLASS_NAMES } from './constants';

export interface LeadBudgetClassNames {
  wrapper: string;
  container: string;
  text: string;
  highlight: string;
}

export interface LeadBudgetHook {
  searchWords: string[];
  classNames: LeadBudgetClassNames;
}

export default function useLeadBudget(
  queryType: QueryType,
  textClassName?: string,
  highlightClassName?: string,
): LeadBudgetHook {
  const { fetchedQuery: query } = useQuery(queryType);
  const { searchWords } = useSearchWords(query);

  const classNames = useMemo(
    () => ({
      wrapper: CLASS_NAMES.wrapper,
      container: CLASS_NAMES.container,
      text: textClassName || CLASS_NAMES.text,
      highlight: highlightClassName || CLASS_NAMES.highlight,
    }),
    [textClassName, highlightClassName],
  );

  return {
    searchWords,
    classNames,
  };
}

import { SkeletonLoader, Text } from '@kanda-libs/ks-design-library';
import type { EnquiryState } from '@kanda-libs/ks-frontend-services';
import TableUpdatedAt from 'components/table/TableUpdatedAt';
import { type FunctionComponent } from 'react';
import useLeadActivity from './useLeadActivity';

export interface LeadActivityProps {
  updatedAt: Date;
  currentState: EnquiryState;
  textClassName?: string;
  isLoading?: boolean;
}

const LeadActivity: FunctionComponent<LeadActivityProps> = function ({
  updatedAt,
  currentState,
  textClassName,
  isLoading = false,
}) {
  const { suffix, className } = useLeadActivity(currentState, textClassName);

  return (
    <div className="flex flex-row">
      <SkeletonLoader
        isLoading={isLoading}
        width={100}
        height={16}
        afterLoading={
          <>
            <Text className={className} text={suffix} />
            <TableUpdatedAt
              textClassName={textClassName}
              updatedAt={updatedAt}
            />
          </>
        }
      />
    </div>
  );
};

export default LeadActivity;

import type { WorkType } from '@kanda-libs/ks-frontend-services';
import { useCurrentCompany } from 'hooks';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type MutableRefObject,
  type SyntheticEvent,
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { formatWorkTypesToOptions } from 'utils';
import {
  HOME_IMPROVEMENT_WORKTYPES,
  NON_HOME_IMPROVEMENT_WORKTYPES,
  RENEWABLES_WORKTYPES,
} from './constants';

export interface WorkTypesSelectClassNames {
  button: string;
  chevron: string;
}

export interface WorkTypeSelectOption {
  name: string;
  onClick: () => void;
  active: boolean;
}

export interface WorkTypeSelectedOption {
  name: string;
  onClick: (e: SyntheticEvent<HTMLButtonElement>) => void;
}

export interface WorkTypesSelectHook {
  classNames: WorkTypesSelectClassNames;
  onButtonClick: () => void;
  active: boolean;
  dropdownRef: MutableRefObject<HTMLDivElement | null>;
  options: WorkTypeSelectOption[];
  selectedOptions: WorkTypeSelectedOption[] | undefined;
  error: boolean;
}

export default function useWorkTypesSelect(
  name: string,
  alwaysActive: boolean,
): WorkTypesSelectHook {
  const [active, setActive] = useState<boolean>(alwaysActive);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const selectedName = useMemo(() => `${name}__selected`, [name]);
  const selected = useWatch({ name: selectedName });
  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  const { company } = useCurrentCompany();
  const formSector = useWatch({
    name: 'company.business_config.business_sector',
  });

  const keys = Object.keys(errors);

  const error = useMemo(() => keys.includes(name), [keys, name]);

  const sector = useMemo(() => {
    const companySector = company?.business_config?.business_sector;
    if (companySector) return companySector;
    return formSector;
  }, [company, formSector]);

  const type = useMemo(() => {
    if (!sector) return 'HOME_IMPROVEMENT';
    if (sector === 'Renewables') return 'RENEWABLES';
    return sector === 'Home improvement'
      ? 'HOME_IMPROVEMENT'
      : 'NON_HOME_IMPROVEMENT';
  }, [sector]);

  const baseWorkTypes = useMemo(() => {
    if (type === 'RENEWABLES')
      return [
        ...RENEWABLES_WORKTYPES.sort((t1, t2) => t1.localeCompare(t2)),
        ...HOME_IMPROVEMENT_WORKTYPES.sort((t1, t2) => t1.localeCompare(t2)),
      ];
    if (type === 'HOME_IMPROVEMENT')
      return HOME_IMPROVEMENT_WORKTYPES.sort((t1, t2) => t1.localeCompare(t2));
    return NON_HOME_IMPROVEMENT_WORKTYPES.sort((t1, t2) =>
      t1.localeCompare(t2),
    );
  }, [type]);

  const updateSingleWorkType = useCallback(
    (workType: string) => {
      if (!selected) {
        setValue(selectedName, workType);
        return;
      }
      if (selected !== workType) {
        setValue(selectedName, workType);
        return;
      }
      setValue(selectedName, undefined);
    },
    [selected, setValue, selectedName],
  );

  const updateMultiWorkTypes = useCallback(
    (workType: string) => {
      if (!selected) {
        setValue(selectedName, workType);
        return;
      }
      const included = selected.split(';').includes(workType);
      if (!included) {
        setValue(selectedName, `${selected};${workType}`);
        return;
      }
      const filtered = selected
        .split(';')
        .filter((selectedType: string) => selectedType !== workType);
      if (filtered.length === 0) {
        setValue(selectedName, undefined);
        return;
      }
      setValue(
        selectedName,
        selected
          .split(';')
          .filter((selectedType: string) => selectedType !== workType)
          .join(';'),
      );
    },
    [selected, setValue, selectedName],
  );

  const updateWorkTypes = useCallback(
    (workType: string) => {
      if (type === 'NON_HOME_IMPROVEMENT') {
        updateSingleWorkType(workType);
        return;
      }
      updateMultiWorkTypes(workType);
    },
    [type, updateMultiWorkTypes, updateSingleWorkType],
  );

  const options = useMemo(
    () =>
      formatWorkTypesToOptions(baseWorkTypes as WorkType[]).map(
        (worktypeOption) => ({
          name: worktypeOption.name,
          onClick: () => updateWorkTypes(worktypeOption.value),
          active: selected
            ? selected.split(';').includes(worktypeOption.value)
            : false,
        }),
      ),
    [selected, baseWorkTypes, updateWorkTypes],
  );

  const selectedOptions = useMemo(() => {
    if (!selected) return undefined;
    const types = formatWorkTypesToOptions(
      (selected as string).split(';') as WorkType[],
    );
    return types.map((worktypeOption) => ({
      name: worktypeOption.name,
      onClick: (e: SyntheticEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();
        updateWorkTypes(worktypeOption.value);
      },
    }));
  }, [selected, updateWorkTypes]);

  const onButtonClick = useCallback(() => {
    if (alwaysActive) {
      if (!active) setActive(true);
      return;
    }
    setActive(!active);
  }, [alwaysActive, active]);

  const classNames = useMemo(
    () => ({
      button: [
        'flex flex-row justify-between w-full p-3 border rounded-lg',
        active ? 'border-green-500' : 'border-neutral-300',
        error ? '!border-red-200' : '',
      ].join(' '),
      chevron: [
        'w-4 min-w-4 h-4 transition-all mt-0.75 mb-auto',
        active ? 'rotate-180' : '',
      ].join(' '),
    }),
    [active, error],
  );

  const setInactive = useCallback(() => {
    if (alwaysActive) return;
    setActive(false);
  }, [alwaysActive]);

  const handleEscClick = useCallback(
    (e: KeyboardEvent) => {
      if (e.key !== 'Escape') return;
      setInactive();
    },
    [setInactive],
  );

  const handleOutsideClick = useCallback(
    (e: MouseEvent) => {
      if (!active) return;
      const el = dropdownRef.current;
      const { target } = e;
      if (!el || !target) return;
      if (el.contains(e.target as HTMLElement)) return;
      setInactive();
    },
    [active, setInactive],
  );

  const formSectorRef = useRef<string>(formSector);
  useEffect(() => {
    if (formSector === formSectorRef.current) return;
    setValue(name, '');
    setValue(selectedName, undefined);
  }, [formSector, name, selectedName, setValue]);

  useEffect(() => {
    if (!selected) {
      setValue(name, '');
      return;
    }
    setValue(name, selected.split(';'));
  }, [name, setValue, selected]);

  useEffect(() => {
    window.addEventListener('keydown', handleEscClick);
    window.addEventListener('click', handleOutsideClick);
    return () => {
      window.removeEventListener('keydown', handleEscClick);
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [handleEscClick, handleOutsideClick]);

  return {
    classNames,
    onButtonClick,
    active,
    dropdownRef,
    options,
    selectedOptions,
    error,
  };
}

import { type TagColor } from '@kanda-libs/ks-design-library';
import type { InterestInFinance } from 'pages/Leads/helpers';

export const getLabelColor = (interest: InterestInFinance): TagColor => {
  switch (interest) {
    case 'interested':
      return 'green';
    case 'not_interested':
      return 'violet';
    case 'not_answered':
      return 'blue';
    default:
      return 'grey';
  }
};

export const getLabel = (interest: InterestInFinance): string | undefined => {
  switch (interest) {
    case 'interested':
      return 'finance interest';
    case 'not_interested':
      return 'no finance interest';
    case 'not_answered':
      return 'interest not stated';
    default:
      return undefined;
  }
};

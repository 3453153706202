import type { FinanceRate, JobItem } from '@kanda-libs/ks-frontend-services';
import { optionalToFixed } from '@kanda-utils/library';
import {
  getBuyNowPayLaterRateLabel,
  rateIsBuyNowPayLater,
} from 'utils/FinanceRate-function';

import { getCombinedDurationLabel } from './FinanceOptionNarrow-functions';

import {
  calculateCost,
  calculateJobTotal,
  calculateLoanCosts,
  calculatePayout,
  getFinanceOptionDetails,
  type Payout,
} from '../../utils';

import useCurrentCompany from '../../hooks/useCurrentCompany';
import useLowTotalWarning from '../../hooks/useLowTotalWarning';
import { SHOW_YEARS_LABEL_MINIMUM_MONTHS } from './FinanceOptionNarrow-constants';

interface ContainerComponentChildrenArgs {
  cost: number;
  monthlyLoan: number;
  durationLabel: string;
  interestLabel: string;
  percentage: number;
  baseRate: number;
  baseFee: number;
  lowTotal: boolean;
  payoutProps: Payout;
}

interface ContainerComponentProps {
  /**
   * Payment method
   */
  financeOption?: FinanceRate;
  financeOptions?: FinanceRate[];
  /**
   * Line items
   */
  lineItems: JobItem[];
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
  fee: number;
  deposit: number;
  combined?: boolean;
}

const ContainerComponent = ({
  children,
  financeOption,
  financeOptions = [],
  fee: inputFee,
  lineItems,
  deposit,
  combined = false,
}: ContainerComponentProps) => {
  const { company } = useCurrentCompany();

  const fee = financeOption?.fee || inputFee;

  const lowTotal = useLowTotalWarning(lineItems);

  if (!financeOption) return <></>;

  const { interest, duration } = getFinanceOptionDetails(financeOption);

  const skipDeposit = company?.skip_deposit === 'Yes';

  const currentDeposit = skipDeposit ? deposit || 0 : null;

  const {
    total: cost,
    percentage,
    baseRate,
    baseFee,
  } = calculateCost(lineItems, fee, financeOption, currentDeposit);

  const payoutProps = calculatePayout(
    lineItems,
    fee,
    financeOption,
    deposit,
    skipDeposit,
  );

  const { monthly } = calculateLoanCosts(
    calculateJobTotal(lineItems || []),
    financeOption,
    currentDeposit as number,
  );

  const showYearsLabel = duration >= SHOW_YEARS_LABEL_MINIMUM_MONTHS;

  const durationPrefixLabel = [
    showYearsLabel ? 'year' : 'month',
    (showYearsLabel && duration === 12) || (!showYearsLabel && duration === 1)
      ? ''
      : 's',
  ].join('');

  const durationUnitLabel = showYearsLabel ? duration / 12 : duration;

  const durationLabel = combined
    ? getCombinedDurationLabel(financeOptions)
    : `${durationUnitLabel} ${durationPrefixLabel}`;

  const isBuyNowPayLater = rateIsBuyNowPayLater(financeOption);

  const label = isBuyNowPayLater
    ? getBuyNowPayLaterRateLabel(financeOption, true)
    : durationLabel;

  const interestLabel = `${optionalToFixed(interest)}% APR interest`;

  return children({
    cost,
    monthlyLoan: monthly,
    durationLabel: label,
    interestLabel,
    percentage,
    baseRate,
    baseFee,
    lowTotal,
    payoutProps,
  });
};

ContainerComponent.displayName = 'FinanceOptionNarrow-container';

export default ContainerComponent;

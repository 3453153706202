import {
  Button,
  ModalContainer,
  ModalLayoutCenter,
} from '@kanda-libs/ks-design-library';
import { useCallback, type MutableRefObject } from 'react';
import { BUTTON_ID, MODAL_ID } from '../constants';

export interface FinanceRatesConfirmModalProps {
  isSubmitting: boolean;
}

const FinanceRatesConfirmModal: React.FunctionComponent<FinanceRatesConfirmModalProps> =
  function ({ isSubmitting }) {
    const onClick = useCallback(() => {
      if (isSubmitting) return;
      const button = document.getElementById(BUTTON_ID) as HTMLButtonElement;
      if (!button) return;
      button.click();
    }, [isSubmitting]);

    return (
      <ModalContainer id={MODAL_ID} opacity={60}>
        {({ id, handleClose, ref }) => (
          <ModalLayoutCenter
            id={id}
            onClose={handleClose}
            ref={ref as MutableRefObject<HTMLDivElement>}
            className="w-10/12 md:w-120"
          >
            <p className="text-16-24-em">Request Finance Rates Changes</p>
            <div className="grid grid-cols-2 gap-x-6 mt-6">
              <Button.Text
                id="finance-request-modal-no"
                label="No"
                onClick={handleClose}
                disabled={isSubmitting}
                variant="outline"
              />
              <Button.Text
                id="finance-request-modal-yes"
                label="Yes"
                onClick={onClick}
                disabled={isSubmitting}
              />
            </div>
          </ModalLayoutCenter>
        )}
      </ModalContainer>
    );
  };

export default FinanceRatesConfirmModal;

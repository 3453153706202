import useQuery from 'hooks/useQuery';
import useSearchWords from 'hooks/useSearchWords';
import type { QueryType } from 'store/slices/query';

export interface HighlightWordsHook {
  searchWords: string[];
}

export default function useHighlightWords(
  queryType: QueryType,
): HighlightWordsHook {
  const { fetchedQuery: query } = useQuery(queryType);
  const { searchWords } = useSearchWords(query);
  return {
    searchWords,
  };
}

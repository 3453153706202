import type { Customer } from '@kanda-libs/ks-frontend-services';
import { useLeadContext } from 'pages/Lead/context';
import { useMemo } from 'react';

export interface LeadCustomerHook {
  customer: Customer | undefined;
  isLoading: boolean;
}

export default function useLeadCustomer(): LeadCustomerHook {
  const { lead, isLoading } = useLeadContext();

  const customer = useMemo(() => {
    if (isLoading) return undefined;
    return lead?.enquiry_info?.consumer;
  }, [isLoading, lead]);

  return {
    customer,
    isLoading,
  };
}

import { type WrapperProps } from './useLeadCard';

const DivWrapper: React.FunctionComponent<WrapperProps> = function ({
  children,
  className,
}) {
  return <div className={className}>{children}</div>;
};

export default DivWrapper;

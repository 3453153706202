import { DesktopFooter } from '@kanda-libs/ks-design-library';
import ContactBanner from 'components/ContactBanner';
import type { FunctionComponent } from 'react';

const Footer: FunctionComponent = function () {
  return (
    <DesktopFooter>
      <div className="w-full max-w-screen-lg mx-auto">
        <ContactBanner />
      </div>
    </DesktopFooter>
  );
};

export default Footer;

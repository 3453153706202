import { Button } from '@kanda-libs/ks-design-library';
import { type FunctionComponent } from 'react';
import useAddWorkTypesButton from './useAddWorkTypesButton';

const AddWorkTypesButton: FunctionComponent = function () {
  const { onClick } = useAddWorkTypesButton();
  return (
    <Button.InlineLink
      id="add-work-types"
      label="Add work types"
      variant="turquoise"
      className="whitespace-nowrap mb-2"
      onClick={onClick}
    />
  );
};

export default AddWorkTypesButton;

import useQuery from 'hooks/useQuery';
import useSearchWords from 'hooks/useSearchWords';
import { useMemo } from 'react';
import { type QueryType } from 'store/slices/query';
import { CLASS_NAMES } from './constants';

export interface CellClassNames {
  wrapper: string;
  text: string;
  highlight: string;
}

export interface TableNameHook {
  toDisplay: string;
  searchWords: string[];
  classNames: CellClassNames;
}

export default function useTableCell(
  value: any,
  formatter: (val: any) => string,
  queryType: QueryType,
  textClassName?: string,
  highlightClassName?: string,
): TableNameHook {
  const { fetchedQuery: query } = useQuery(queryType);
  const { searchWords } = useSearchWords(query);

  const toDisplay = useMemo(() => formatter(value), [value, formatter]);

  const classNames = useMemo(
    () => ({
      wrapper: CLASS_NAMES.wrapper,
      text: textClassName || CLASS_NAMES.text,
      highlight: highlightClassName || CLASS_NAMES.highlight,
    }),
    [textClassName, highlightClassName],
  );

  return {
    toDisplay,
    searchWords,
    classNames,
  };
}

import type {
  FinanceRate,
  InfoWorkType,
  JobItem,
} from '@kanda-libs/ks-frontend-services';
import { useCurrentCompany } from 'hooks';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import {
  calculateJobTotal,
  getCurrentInfoWorkType,
  getStoredFinanceRates,
  getValidFinanceRates,
} from 'utils';
import { groupAllRatesByInterest } from './Mobile-functions';

export interface FinanceRatesHook {
  isLoading: boolean;
  rates: FinanceRate[] | undefined;
}

export default function useMobileRates() {
  const { company, isLoading: companyIsLoading } = useCurrentCompany();

  const infoWorkTypes = useSelector(selectors.infoWorkType.getRawResponse) as
    | InfoWorkType[]
    | undefined;
  const infoWorkTypeIsLoading = useSelector(
    selectors.infoWorkType.getHasFetched,
  );

  const isLoading = useMemo(
    () => companyIsLoading || infoWorkTypeIsLoading,
    [companyIsLoading, infoWorkTypeIsLoading],
  );

  const [workType, depositAmount, lineItems, selectedFinanceOptions] = useWatch(
    {
      name: [
        'job.work_type',
        'job.deposit_value.amount',
        'job.job_items',
        'job.finance_options',
      ],
    },
  );

  const total = useMemo(() => {
    const totals = calculateJobTotal(lineItems as JobItem[]);
    return totals.totalIncVat;
  }, [lineItems]);

  const companyRates = useMemo(() => company?.finance_rates, [company]);

  const infoWorkType = useMemo(
    () => getCurrentInfoWorkType(companyRates, infoWorkTypes, workType),
    [companyRates, infoWorkTypes, workType],
  );

  const allRates = useMemo(
    () => getValidFinanceRates(infoWorkType, total, depositAmount),
    [infoWorkType, total, depositAmount],
  );

  const storedRates = useMemo(
    () =>
      getStoredFinanceRates(
        allRates,
        selectedFinanceOptions as unknown as string[],
        total,
        depositAmount,
      ),
    [allRates, selectedFinanceOptions, total, depositAmount],
  );

  const rates = useMemo(
    () => (storedRates ? groupAllRatesByInterest(storedRates) : []),
    [storedRates],
  );

  return {
    isLoading,
    rates,
  };
}

import type { WorkType } from '@kanda-libs/ks-frontend-services';

export const HEADING =
  "We've recently made some changes to our system to ensure we can offer the best finance rates to you and your customers. To enable this, we need to know the all of the types of work your business undertakes.";

export const HEADING2 =
  'We have backfilled the selection from your previous jobs through Kanda. Please select any other work types you undertake, and remove any that are no longer relevant. These can be changes later in your company settings.';

export const CHANGE_TYPE: Partial<Record<WorkType, WorkType>> = {
  roofing_excl_flat_roofs: 'roofing',
  door_internal: 'doors',
};

import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type RefObject,
} from 'react';

export interface OptionPopoverDesktopHook {
  visible: boolean;
  elementRef: RefObject<HTMLDivElement>;
}

export default function useOptionPopoverDesktop(): OptionPopoverDesktopHook {
  const [visible, setVisible] = useState<boolean>(false);
  const elementRef = useRef<HTMLDivElement>(null);

  const onEnter = useCallback(() => setVisible(true), [setVisible]);
  const onLeave = useCallback(() => setVisible(false), [setVisible]);

  useEffect(() => {
    if (!elementRef.current) return () => {};
    const el = elementRef.current;
    el.addEventListener('mouseenter', onEnter);
    el.addEventListener('mouseleave', onLeave);
    el.addEventListener('touchstart', onEnter);
    el.addEventListener('touchend', onLeave);
    return () => {
      el.removeEventListener('mouseenter', onEnter);
      el.removeEventListener('mouseleave', onLeave);
      el.removeEventListener('touchstart', onEnter);
      el.removeEventListener('touchend', onLeave);
    };
  }, [onEnter, onLeave]);

  return { visible, elementRef };
}

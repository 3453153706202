import LeadCustomer from 'pages/Lead/components/LeadCustomer';
import LeadNameStatus from 'pages/Lead/components/LeadNameStatus';
import LeadProgressBar from 'pages/Lead/components/LeadProgressBar';
import LeadQuoteDetails from 'pages/Lead/components/LeadQuoteDetails';

const LeadMobileContent: React.FunctionComponent = function ({}) {
  return (
    <div className="flex flex-col flex-1 gap-y-4 -mt-8 mb-6">
      <LeadProgressBar />
      <LeadNameStatus />
      <LeadCustomer />
      <LeadQuoteDetails />
    </div>
  );
};

export default LeadMobileContent;

import { CopyContent } from '@kanda-libs/ks-design-library';
import type { Tag } from '@kanda-libs/ks-frontend-services';
import TagBanner from './TagBanner';
import useTag from './useTag';

export interface MyAccountAlwaysOnLinkTagProps {
  tag: Tag;
  index: number;
}

const MyAccountAlwaysOnLinkTag: React.FunctionComponent<MyAccountAlwaysOnLinkTagProps> =
  function ({ tag, index }) {
    const { url, id } = useTag(tag);
    return (
      <div className="w-full bg-neutral-000 border border-neutral-300 px-4 py-5 rounded-lg flex flex-col shadow-card">
        <p className="text-16-20-em text-neutral-900 mb-3">Link {index}</p>
        <div className="grid grid-cols-[min-content_auto] gap-x-2 gap-y-1">
          <p className="text-14-22-em">URL:</p>
          <div className="flex flex-col">
            <p className="text-14-22">{url}</p>
            <CopyContent
              className="mr-auto"
              content={url}
              clickedView={
                <p className="text-14-22-em text-green-600">
                  URL copied to clipboard
                </p>
              }
            >
              <p className="text-14-22-em text-green-600 underline">
                Click here to copy URL
              </p>
            </CopyContent>
          </div>
          <p className="text-14-22-em">Source:</p>
          <p className="text-14-22">{tag.source}</p>
          <p className="text-14-22-em col-span-2">QR Code/Banner:</p>
          <TagBanner url={url} id={id} />
        </div>
      </div>
    );
  };

export default MyAccountAlwaysOnLinkTag;

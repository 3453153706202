import { Field } from '@kanda-libs/ks-component-ts';
import { Button, Text } from '@kanda-libs/ks-design-library';
import { FilterableSelect, Sectors } from './components';
import { TYPE_FIELD_PROPS, TYPE_FIELD_VALIDATION } from './constants';
import useWorkTypesForm from './useWorkTypesForm';

export interface WorkTypesFormProps {
  sectorTitle?: string;
  workTypeTitle?: string;
}

const WorkTypesForm: React.FunctionComponent<WorkTypesFormProps> = function ({
  sectorTitle = 'What kind of work do you do?',
  workTypeTitle = 'Which type of work best describes the work you undertake (if you do more than one, select the most relevant)?',
}) {
  const { show, sectors, tradeTypes, showSearch, onShowSearchClick } =
    useWorkTypesForm();
  if (!show) return null;
  return (
    <div className="flex flex-col gap-y-6">
      <Sectors sectors={sectors} title={sectorTitle} />
      {tradeTypes && (
        <div className="flex flex-col">
          <Text text={workTypeTitle} className="text-14-22-em mb-3" />
          <Field.Validator validation={TYPE_FIELD_VALIDATION}>
            <Field.Select {...TYPE_FIELD_PROPS} options={tradeTypes} />
          </Field.Validator>
          <Button.InlineLink
            id="setup-not-listed"
            label="I can't find my business in the list"
            className="mr-auto text-neutral-900 border-neutral-900"
            onClick={onShowSearchClick}
          />
        </div>
      )}
      {showSearch && (
        <>
          <div className="flex flex-row w-full">
            <div className="w-full border-b border-neutral-300 my-auto" />
            <p className="text-14-22-em px-4 text-neutral-700">OR</p>
            <div className="w-full border-b border-neutral-300 my-auto" />
          </div>
          <FilterableSelect />
        </>
      )}
    </div>
  );
};

export default WorkTypesForm;

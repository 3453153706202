import { Tag } from '@kanda-libs/ks-design-library';
import { type FunctionComponent } from 'react';
import Highlighter from 'react-highlight-words';
import { type QueryType } from 'store/slices/query';
import {
  CLASS_NAMES,
  CURRENT_USER_TAG_PROPS,
  HIGHLIGHT_PROPS,
  OWNER_TAG_PROPS,
  TAG_PROPS,
} from './constants';
import useTableName from './useTableName';

export interface TableNameProps {
  firstName: string;
  lastName: string;
  example?: boolean;
  queryType?: QueryType;
  className?: string;
  owner?: boolean;
  current?: boolean;
  hideTag?: boolean;
}

const TableName: FunctionComponent<TableNameProps> = function ({
  firstName,
  lastName,
  example = false,
  queryType = 'leads',
  className = CLASS_NAMES.text,
  owner = false,
  current = false,
  hideTag = false,
}) {
  const { name, searchWords } = useTableName(
    firstName,
    lastName,
    queryType,
    example,
  );

  return (
    <div className={CLASS_NAMES.container}>
      {!hideTag && (
        <>
          {example && <Tag {...TAG_PROPS} />}
          {!example && owner && <Tag {...OWNER_TAG_PROPS} />}
          {!example && current && <Tag {...CURRENT_USER_TAG_PROPS} />}
        </>
      )}
      <Highlighter
        {...HIGHLIGHT_PROPS}
        searchWords={searchWords}
        textToHighlight={name || ''}
        className={className}
      />
    </div>
  );
};

export default TableName;

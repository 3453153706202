import { Button, Logo } from '@kanda-libs/ks-design-library';
import { QRCode } from 'react-qrcode-logo';
import { LOGO_B64 } from '../constants';
import useTagBanner from './useTagBanner';

export interface TagBannerProps {
  url: string;
  id: string;
}

const TagBanner: React.FunctionComponent<TagBannerProps> = function ({
  url,
  id,
}) {
  const { dataUrl, ref, onClick } = useTagBanner(url, id);
  return (
    <>
      <div className="flex col-span-2 w-full h-[196px] relative overflow-x-scroll">
        <div className="absolute">
          <div className="w-0 h-0 overflow-hidden">
            <div
              ref={ref}
              className="bg-neutral-000 border border-neutral-300 p-3 mr-auto w-[440px] h-[196px] min-w-[404px]"
            >
              <div className="flex flex-row justify-between">
                <QRCode
                  value={url}
                  ecLevel="M"
                  qrStyle="dots"
                  eyeRadius={5}
                  logoImage={LOGO_B64}
                  logoWidth={40}
                  logoHeight={40}
                  eyeColor="#0c6355"
                  fgColor="#0c6355"
                />
                <div className="flex flex-col my-auto gap-y-1 pr-3">
                  <p className="text-center text-16-24-em">
                    Finance available through
                  </p>
                  <Logo width={150} height={45} className="mx-auto" />
                  <p className="text-center text-16-24-em mt-1">
                    Scan here to continue
                  </p>
                </div>
              </div>
            </div>
          </div>
          {dataUrl && (
            <img
              src={dataUrl}
              alt="QRCode"
              className="w-[440px] min-w-[440px] h-[196px]"
            />
          )}
        </div>
      </div>
      {dataUrl && (
        <div className="col-span-2">
          <Button.Text
            id="qr-banner-download"
            label="Download Banner"
            className="mt-2"
            onClick={onClick}
          />
        </div>
      )}
    </>
  );
};

export default TagBanner;

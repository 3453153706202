import {
  CompanyTypeButtons,
  LimitedCompanyForm,
  SoleTraderForm,
} from './components';
import useBusinessForm from './useBusinessForm';

export interface BusinessFormProps {
  schemaError?: boolean;
}

const BusinessForm: React.FunctionComponent<BusinessFormProps> = function ({
  schemaError,
}) {
  const { showLimitedFields, showSoleFields } = useBusinessForm();
  return (
    <div className="flex flex-col mb-4">
      <CompanyTypeButtons />
      {showLimitedFields && <LimitedCompanyForm schemaError={schemaError} />}
      {showSoleFields && <SoleTraderForm />}
    </div>
  );
};

export default BusinessForm;

import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import { useMemo } from 'react';
import type { GroupedByKey } from 'utils';
import Row from '../Row';

export interface GroupedRatesProps {
  grouped: GroupedByKey;
}

const GroupedRates: React.FunctionComponent<GroupedRatesProps> = function ({
  grouped,
}) {
  const rates = useMemo(
    () =>
      Object.keys(grouped).reduce((final: FinanceRate[], current: string) => {
        const currentRates = grouped[current];
        return [...final, ...currentRates];
      }, []),
    [grouped],
  );
  return <Row rates={rates} grouped />;
};

export default GroupedRates;

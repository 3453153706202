import { ModalsWrapperContext } from '@kanda-libs/ks-design-library';
import { useCallback, useContext, useMemo } from 'react';

export interface OptionPopoverMobileHook {
  buttonId: string;
  modalId: string;
  onClick: () => void;
  onClose: () => void;
}

export default function useOptionPopoverMobile(
  popoverId: string,
): OptionPopoverMobileHook {
  const { showModal, hideModal } = useContext(ModalsWrapperContext);

  const buttonId = useMemo(() => `button-${popoverId}`, [popoverId]);
  const modalId = useMemo(() => `modal-${popoverId}`, [popoverId]);

  const onClick = useCallback(() => showModal(modalId), [showModal, modalId]);

  const onClose = useCallback(() => hideModal(modalId), [hideModal, modalId]);

  return {
    buttonId,
    modalId,
    onClick,
    onClose,
  };
}

import { Field, Widget } from '@kanda-libs/ks-component-ts';
import { Button, Text } from '@kanda-libs/ks-design-library';

import {
  FIELD_PROPS,
  FIELD_VALIDATION,
  LINK_VALIDATION,
} from './Form-constants';
import useOnlinePresenceForm from './useOnlinePresenceForm';

interface ViewComponentProps {
  isLoading?: boolean;
  isSubmitting?: boolean;
}

const ViewComponent = ({ isLoading, isSubmitting }: ViewComponentProps) => {
  const { showFields, showGoogle, showFacebook } = useOnlinePresenceForm();

  return (
    <>
      <Text
        text="Online Presence"
        className="text-14-22–em text-neutral-800 font-bold mb-2"
        isLoading={isLoading}
      />
      <Widget.CompanyCompanyInfoOnlinePresenceQOnline
        options={[
          {
            name: 'Yes, I can provide the details',
            value: 'Yes, I can provide the details',
          },
          { name: "No, I don't have this", value: "No, I don't have this" },
        ]}
      />
      {showFields && (
        <>
          <Widget.CompanyCompanyInfoOnlinePresenceWebsite
            label="Please provide your website URL if you have one"
            placeholder="Website URL"
          />
          <Field.Validator validation={FIELD_VALIDATION.google}>
            <Field.Select {...FIELD_PROPS.google} />
          </Field.Validator>
          {showGoogle && (
            <Widget.CompanyCompanyInfoOnlinePresenceGoogle
              label="Please provide the Google business page name or link"
              validation={LINK_VALIDATION.google}
            />
          )}

          <Field.Validator validation={FIELD_VALIDATION.facebook}>
            <Field.Select {...FIELD_PROPS.facebook} />
          </Field.Validator>
          {showFacebook && (
            <Widget.CompanyCompanyInfoOnlinePresenceFacebook
              label="Please provide the Facebook business page name or link"
              validation={LINK_VALIDATION.facebook}
            />
          )}
        </>
      )}
      <div className="mt-auto md:mt-0">
        <Button.Text
          id="onboarding-job-online-presence-submit"
          label="Save & Continue"
          icon="check"
          submit
          isLoading={isLoading}
          disabled={isSubmitting}
          className="w-full mt-6"
        />
      </div>
    </>
  );
};
ViewComponent.defaultProps = {
  isLoading: false,
};

ViewComponent.displayName = 'JobInfoForm-view';

export default ViewComponent;

import { Button, Icon, Loader, Text } from '@kanda-libs/ks-design-library';
import useWorkTypeModalModalAdmin from './useWorkTypeModalModalAdmin';

export interface WorkTypeModalModalAdminProps {
  handleClose: () => void;
}

const WorkTypeModalModalAdmin: React.FunctionComponent<WorkTypeModalModalAdminProps> =
  function ({ handleClose }) {
    const { onSaveClick, onExitClick, isSubmitting } =
      useWorkTypeModalModalAdmin(handleClose);

    return (
      <div className="flex flex-col">
        <Icon
          icon="delete-file"
          stroke="red-200"
          size={64}
          className="mx-auto mb-6"
        />
        <Text
          text="You're about to lose all unsaved data"
          className="w-full text-center text-24-28-em"
        />
        <Text
          text="You'll be taken to your companies settings to update your work types. If you don't save your quote as a draft, you'll have to start over"
          className="text-16-24 mt-4"
        />
        <div className="flex flex-col mt-4 mb-8">
          <ul className="pl-5 list-disc text-16-24 text-neutral-600">
            <li className="mb-2">Save the draft to carry on later</li>
            <li>Continue without saving to abandon the job</li>
          </ul>
        </div>
        <Button.Text
          id="create-job-update-worktype-modal-save-draft"
          label="Save and continue"
          variant="gradient"
          className="w-full mb-3"
          onClick={onSaveClick}
          disabled={isSubmitting}
        />
        <Button.Text
          id="create-job-update-worktype-modal-exit"
          label="Continue without saving"
          variant="outline"
          className="w-full"
          onClick={onExitClick}
          disabled={isSubmitting}
        />
        <Loader isLoading={isSubmitting} />
      </div>
    );
  };

export default WorkTypeModalModalAdmin;

import { Text } from '@kanda-libs/ks-design-library';
import MyAccountAlwaysOnLinkTags from './Tags';

const MyAccountAlwaysOnLinkContent: React.FunctionComponent = function () {
  return (
    <div className="flex flex-col">
      <Text
        text="Always On Link"
        className="text-16-20-em text-neutral-900 mb-2"
      />
      <Text
        text="Set up and manage your always on links. You can have more than one to allow for tracking finance applications and leads coming from different marketing locations"
        className="text-14-22 text-neutral-700 mb-6"
      />
      <MyAccountAlwaysOnLinkTags />
    </div>
  );
};

export default MyAccountAlwaysOnLinkContent;

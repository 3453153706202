import Fuse, { type FuseResult, type IFuseOptions } from 'fuse.js';
import { useCallback, useMemo, useState } from 'react';

export interface FuseHook<T> {
  hits: FuseResult<T>[];
  handleSearch: (newQuery: string) => void;
}

export interface FuseOptions<T> extends IFuseOptions<T> {
  limit?: number;
  matchAllOnEmptyQuery?: boolean;
}

const BASE_OPTIONS = {
  limit: undefined,
  matchAllOnEmptyQuery: true,
  includeMatches: true,
  threshold: 0.2,
  includeScore: true,
  minMatchCharLength: 3,
  findAllMatches: true,
  ignoreLocation: true,
};

export function useFuse<T>(
  list: T[],
  options: FuseOptions<T>,
  { initialQuery = '' },
): FuseHook<T> {
  const { limit, matchAllOnEmptyQuery, ...fuseOptions } = {
    ...BASE_OPTIONS,
    ...options,
  };
  const [query, setQuery] = useState<string>(initialQuery);

  const fuse = useMemo(
    () =>
      new Fuse(list, {
        ...fuseOptions,
      }),
    [list, fuseOptions],
  );

  const baseMappedData = useMemo(
    () =>
      list
        .slice(0, limit)
        .map((item: T, refIndex: number) => ({ item, refIndex, score: 1 })),
    [list, limit],
  );

  const hits = useMemo(() => {
    if (!query && matchAllOnEmptyQuery) return baseMappedData;
    return fuse.search(query, limit ? { limit } : undefined);
  }, [fuse, limit, matchAllOnEmptyQuery, query, baseMappedData]);

  // pass a handling helper to speed up implementation
  const handleSearch = useCallback(
    (newQuery: string) => setQuery(newQuery.trim()),
    [setQuery],
  );

  return {
    hits,
    handleSearch,
  };
}

import { DesktopLayout } from 'pages/MyAccount/components';
import MyAccountFinanceRatesContent from '../Content';

const MyAccountFinanceRatesDesktop: React.FunctionComponent = function () {
  return (
    <DesktopLayout>
      <MyAccountFinanceRatesContent />
    </DesktopLayout>
  );
};

export default MyAccountFinanceRatesDesktop;

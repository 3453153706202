import FinanceOptions from './FinanceOptions';
import FinanceRates from './FinanceRates';
import Info from './Info';
import LeadFinanceOption from './LeadFinanceOption';
import PaymentMethods from './PaymentMethods';

export default {
  Info,
  FinanceOptions,
  FinanceRates,
  PaymentMethods,
  LeadFinanceOption,
};

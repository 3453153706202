import { Company } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { URLS } from 'config';
import { pipe } from 'fp-ts/lib/function';
import { useCurrentCompany } from 'hooks';
import usePagination from 'hooks/usePagination';
import useQuery from 'hooks/useQuery';
import useSearchQuery from 'hooks/useSearchQuery';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  type FunctionComponent,
  type ReactNode,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectors } from 'store';
import { getLeadsFilters } from 'store/selectors/filters';
import { getLeadsSorting } from 'store/selectors/sorting';
import type { LeadsFilter } from 'store/slices/filters';
import { setSorting } from 'store/slices/sorting';
import { SEARCH_OPTIONS } from './constants';
import {
  filterLeads,
  filterLeadsForArchiveStatus,
  formatLeads,
  getNewOrder,
  getNewSorting,
  sortLeadData,
  type FormattedLead,
} from './helpers';

export interface LeadsSortingAction {
  type?: string;
}

export interface LeadsContextType {
  leads: FormattedLead[];
  company: Company | undefined;
  noLeads: boolean;
  archive: boolean;
  filter: LeadsFilter;
  query: string;
  hasActiveFilters: boolean;
  numItems: number;
  pageIndex: number;
  perPage: number;
  totalPages: number;
  setPage: (index: number) => void;
  showOptions: boolean;
  onAction: (action?: LeadsSortingAction) => void;
  isLoading: boolean;
}

export interface LeadsProviderProps {
  children: ReactNode;
}

export const LeadsContext = createContext<LeadsContextType>({
  leads: [],
  company: undefined,
  archive: false,
  filter: {},
  query: '',
  hasActiveFilters: false,
  noLeads: false,
  numItems: 0,
  pageIndex: 0,
  perPage: 0,
  totalPages: 0,
  setPage: () => {},
  showOptions: false,
  onAction: () => {},
  isLoading: false,
});

export const useLeadsContext = () => useContext(LeadsContext);

const LeadsProvider: FunctionComponent<LeadsProviderProps> = function ({
  children,
}) {
  const { pathname } = useLocation();

  const archive = useMemo(() => pathname === URLS.leadsArchive, [pathname]);

  const leads = useSelector(selectors.enquiry.getEntitiesAsArray);
  const leadsHasFetched = useSelector(selectors.enquiry.getHasFetched);
  const dispatch = useAppDispatch();

  const { company } = useCurrentCompany();

  const { fetchedQuery: query } = useQuery('leads');

  const filter = useSelector(getLeadsFilters);

  const { sorting, order } = useSelector(getLeadsSorting);

  const onAction = useCallback(
    (action?: LeadsSortingAction) => {
      if (!action) return;
      const { type } = action;
      const newOrder = getNewOrder(type, order);
      const newSorting = getNewSorting(type);
      dispatch(
        setSorting({
          leads: {
            sorting: newSorting,
            order: newOrder,
          },
        }),
      );
    },
    [dispatch, order],
  );

  const filteredLeads = useMemo(
    () =>
      pipe(
        leads,
        (all) => filterLeadsForArchiveStatus(all, archive),
        formatLeads,
        (formattedLeads) => filterLeads(formattedLeads, filter),
      ),
    [leads, archive, filter],
  );

  const { hits } = useSearchQuery<FormattedLead>(
    filteredLeads,
    'leads',
    SEARCH_OPTIONS,
    0,
  );

  const sortedLeads = useMemo(
    () =>
      sortLeadData(
        hits.map((hit) => hit.item),
        sorting,
        order,
      ),
    [hits, sorting, order],
  );

  const numItems = useMemo(() => sortedLeads.length, [sortedLeads]);

  const { pageIndex, perPage, totalPages, setPageIndex } =
    usePagination(numItems);

  const paginatedData = useMemo(
    () => sortedLeads.slice(pageIndex * perPage, pageIndex * perPage + perPage),
    [sortedLeads, pageIndex, perPage],
  );

  const isLoading = useMemo(
    () => !leadsHasFetched || !company,
    [leadsHasFetched, company],
  );

  const noLeads = useMemo(() => {
    if (isLoading) return false;
    return leads.length === 0;
  }, [isLoading, leads]);

  const showOptions = useMemo(
    () => !isLoading && leads?.length > 0,
    [leads, isLoading],
  );

  const hasActiveFilters = useMemo(
    () => Boolean(filter?.state || filter?.source),
    [filter],
  );

  const values: LeadsContextType = useMemo(
    () => ({
      leads: paginatedData,
      company: company || undefined,
      archive,
      noLeads,
      filter,
      query,
      hasActiveFilters,
      numItems,
      pageIndex,
      perPage,
      totalPages,
      setPage: setPageIndex,
      showOptions,
      onAction,
      isLoading,
    }),
    [
      paginatedData,
      company,
      archive,
      noLeads,
      filter,
      query,
      numItems,
      pageIndex,
      perPage,
      totalPages,
      setPageIndex,
      hasActiveFilters,
      showOptions,
      onAction,
      isLoading,
    ],
  );

  return (
    <LeadsContext.Provider value={values}>{children}</LeadsContext.Provider>
  );
};

export default LeadsProvider;

import { Price, Text } from '@kanda-libs/ks-design-library';
import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import OptionPopover from '../OptionPopover';
import RateCostPopover from './RateCostPopover';
import useFinanceOptionRateCosts from './useFinanceOptionRateCosts';

export interface FinanceOptionsRateDetailsRateCostsProps {
  rates: FinanceRate[];
}

const FinanceOptionsRateDetailsRateCosts: React.FunctionComponent<FinanceOptionsRateDetailsRateCostsProps> =
  function ({ rates }) {
    const { from, cost, popoverId, ...rest } = useFinanceOptionRateCosts(rates);
    return (
      <>
        <div className="flex flex-row items-center">
          <Price
            amount={from}
            color="lavender-200"
            poundsClassName="text-12-18-em"
            centsClassName="text-12-18-em"
          />
          <Text className="ml-1 text-12-18-em text-lavender-200" text="/ MO" />
        </div>
        <div className="flex flex-row items-center">
          <Price
            amount={cost}
            color="neutral-600"
            poundsClassName="text-12-18-em"
            centsClassName="text-12-18-em"
            wrapperClassName="mr-auto"
          />
          <OptionPopover popoverId={popoverId}>
            <RateCostPopover {...rest} />
          </OptionPopover>
        </div>
      </>
    );
  };

export default FinanceOptionsRateDetailsRateCosts;

import { Icon } from '@kanda-libs/ks-design-library';
import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import FinanceOptionHandleToggle from './Toggle';
import useFinanceOptionHandle from './useFinanceOptionHandle';

export interface FinanceOptionHandleProps {
  rates: FinanceRate[];
  toggleable: boolean;
}

const FinanceOptionHandle: React.FunctionComponent<FinanceOptionHandleProps> =
  function ({ rates, toggleable }) {
    const { iconProps } = useFinanceOptionHandle(rates);
    if (!toggleable) return <Icon {...iconProps} />;
    return <FinanceOptionHandleToggle rates={rates} />;
  };

export default FinanceOptionHandle;

import { Icon } from '@kanda-libs/ks-design-library';
import type { PopoverProps } from '..';
import usePopoverDesktop from './usePopoverDesktop';

const PopoverDesktop: React.FunctionComponent<PopoverProps> = function ({
  children,
}) {
  const { elementRef, visible } = usePopoverDesktop();
  return (
    <div ref={elementRef} className="z-50 mb-px relative">
      <Icon
        icon="info"
        className="w-4 cursor-pointer min-w-4 text-neutral-500 ml-2"
        size={16}
      />
      {visible && (
        <div className="absolute bottom-8 right-0 px-4 py-5 border border-neutral-200 bg-neutral-000 rounded-lg shadow-popover min-w-60">
          {children}
        </div>
      )}
    </div>
  );
};

export default PopoverDesktop;

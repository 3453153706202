import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import FinanceOptionRateDetailsPending from './Pending';
import FinanceOptionsRateDetailsRateCosts from './RateCosts';
import FinanceOptionRateDetailsUnavailable from './Unavailable';
import useRateDetails from './useRateDetails';

export interface FinanceOptionRateDetailsProps {
  rates: FinanceRate[];
}

const FinanceOptionRateDetails: React.FunctionComponent<FinanceOptionRateDetailsProps> =
  function ({ rates }) {
    const { available, pending } = useRateDetails(rates);
    if (pending) return <FinanceOptionRateDetailsPending rates={rates} />;
    if (!available)
      return <FinanceOptionRateDetailsUnavailable rates={rates} />;
    return <FinanceOptionsRateDetailsRateCosts rates={rates} />;
  };

export default FinanceOptionRateDetails;

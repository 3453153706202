import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import {
  fetchPageInitialData,
  type PathKey,
  type ThunkAPI,
} from '@kanda-libs/ks-frontend-services';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { store, type RootState } from 'store';

export const reloadInitialData = createAsyncThunk(
  'app/reloadInitialData',
  async (_: undefined, thunkAPI: ThunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const pathKey = state?.app?.pathKey;

    fetchPageInitialData(
      store,
      pathKey.pages as unknown as StringIndexedObject,
      pathKey as unknown as PathKey<StringIndexedObject>,
      true,
    );
  },
);

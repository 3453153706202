export const COMPANY_ADDRESS_FIELD_NAMES = {
  name: 'company.limited_company_info.company_name',
  companySearchName: 'company.limited_company_info.search',
  companyFocusName: 'company.limited_company_info.focus',
  companyNumberName: 'company.limited_company_info.company_number',
  buildingNumberName:
    'company.limited_company_info.company_address.building_number',
  addressLineOneName: 'company.limited_company_info.company_address.line_1',
  addressLineTwoName: 'company.limited_company_info.company_address.line_2',
  cityName: 'company.limited_company_info.company_address.city',
  countyName: 'company.limited_company_info.company_address.county',
  countryName: 'company.limited_company_info.company_address.country',
  postalCodeName: 'company.limited_company_info.company_address.postcode',
};

export const COMPANY_NAME_PROPS = {
  name: 'company.limited_company_info.company_name',
  label: 'Company Name',
  placeholder: 'Company name',
  autoCapitalize: 'on',
  autoCorrect: 'off',
};

import useLockedMessage from './useLockedMessage';

const LockedMessage: React.FunctionComponent = function () {
  const { date, onClick } = useLockedMessage();
  return (
    <span className="text-14-22">
      Your finance rates are locked until{' '}
      <span className="text-14-22-em">{date}</span>. If you need to make
      changes, please{' '}
      <button
        type="button"
        className="text-green-500 underline"
        onClick={onClick}
      >
        contact us
      </button>
    </span>
  );
};

export default LockedMessage;

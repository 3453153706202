import { BreakPoints } from '@kanda-libs/ks-design-library';
import type { ReactNode } from 'react';
import PopoverDesktop from './Desktop';
import PopoverMobile from './Mobile';

export interface PopoverProps {
  children: ReactNode;
  popoverId: string;
}

const Popover: React.FunctionComponent<PopoverProps> = function ({
  children,
  popoverId,
}) {
  return (
    <BreakPoints
      desktop={
        <PopoverDesktop popoverId={popoverId}>{children}</PopoverDesktop>
      }
      mobile={<PopoverMobile popoverId={popoverId}>{children}</PopoverMobile>}
    />
  );
};

export default Popover;

import { FormTheme, Widget } from '@kanda-libs/ks-component-ts';
import AddressFields from '../AddressFields';
import { NATIONAL_INSURANCE_NUMBER_VALIDATION } from './constants';

const SoleTraderForm: React.FunctionComponent = function () {
  return (
    <div className="flex flex-col">
      <FormTheme variant="streamline">
        <Widget.CompanySoleTraderInfoTradingName
          label="Business/Trading Name (if different from your name)"
          placeholder="Business/Trading Name"
        />
        <Widget.CompanySoleTraderInfoNationalInsuranceNumber
          validation={NATIONAL_INSURANCE_NUMBER_VALIDATION}
          label="National insurance (NI) number"
          placeholder="e.g. GB 123456 A"
        />
        <AddressFields companyType="sole_trader" />
      </FormTheme>
    </div>
  );
};

export default SoleTraderForm;

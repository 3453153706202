import { Text } from '@kanda-libs/ks-design-library';
import MyAccountFinanceRatesForm from './Form';

const MyAccountFinanceRatesContent: React.FunctionComponent = function () {
  return (
    <div className="flex flex-col">
      <Text
        text="Finance Rates"
        className="text-16-20-em text-neutral-900 mb-2"
      />
      <Text
        text="Decide which finance rates you'd like active on your account. Once submitted, finance rates will be locked for 1 month. If you need to make changes in this period, please contact us."
        className="text-14-22 text-neutral-700 mb-6"
      />
      <span className="text-14-22 text-neutral-700 mb-6">
        <a
          href="https://www.getkanda.com/pricing#financing-options-and-fees"
          target="_blank"
          rel="noreferrer"
          className="text-14-22-em underline text-green-500"
        >
          Click here
        </a>{' '}
        to see fees associated with each finance rate
      </span>
      <MyAccountFinanceRatesForm />
    </div>
  );
};

export default MyAccountFinanceRatesContent;

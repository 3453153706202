import { TimeAgo } from '@kanda-libs/ks-design-library';
import { type FunctionComponent } from 'react';
import useTableUpdatedAt from './useTableUpdatedAt';

export interface TableUpdatedAtProps {
  updatedAt: Date;
  textClassName?: string;
}

const TableUpdatedAt: FunctionComponent<TableUpdatedAtProps> = function ({
  updatedAt,
  textClassName,
}) {
  const { classNames, timestamp } = useTableUpdatedAt(updatedAt, textClassName);

  return (
    <div className={classNames.wrapper}>
      <TimeAgo timestamp={timestamp} className={classNames.text} />
    </div>
  );
};

export default TableUpdatedAt;

import type { ValidationItems } from '@kanda-libs/ks-component-ts';
import type {
  BusinessSector,
  FinanceProvider,
  LenderRateType,
  TradeType,
} from '@kanda-libs/ks-frontend-services';

export const SECTORS: Record<
  Exclude<BusinessSector, 'Training and education'>,
  string
> = {
  'Home improvement': '🛠️',
  Renewables: '☀️',
  'IT and technology Services': '💻',
  Retail: '🛍️',
  Healthcare: '🩺',
  'Automotive services': '🚗',
  'Events (including weddings)': '🎭',
  Other: '❓',
};

export interface BaseConfig {
  business_sector: BusinessSector;
  trade_type: TradeType;
  trade_text: string;
  lender: FinanceProvider;
  lender_rate_type: LenderRateType;
}

export const BASE_CONFIGS: BaseConfig[] = [
  {
    business_sector: 'Home improvement',
    trade_type: 'air_conditioning',
    trade_text: 'Air Conditioning',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'artificial_grass_specialists',
    trade_text: 'Artificial Grass Specialists',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'bathrooms',
    trade_text: 'Bathrooms',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'blinds_and_shutters',
    trade_text: 'Blinds and Shutters',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'carpentry',
    trade_text: 'Carpentry',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'cctv_and_security_installations',
    trade_text: 'CCTV and Security Installations',
    lender: 'humm',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'cladding_sofits_and_guttering',
    trade_text: 'Cladding Sofits and Guttering',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'conservatories',
    trade_text: 'Conservatories',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'curtains',
    trade_text: 'Curtains',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'decking',
    trade_text: 'Decking',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'driveways_and_patios',
    trade_text: 'Driveways and Patios',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'electrician',
    trade_text: 'Electrician',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'ev_charger_installer',
    trade_text: 'EV Charger Installer',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'fencing',
    trade_text: 'Fencing',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'fireplaces',
    trade_text: 'Fireplaces',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'fitted_bedroom_furniture',
    trade_text: 'Fitted Bedroom Furniture',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'floor_layer',
    trade_text: 'Floor Layer',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'garage_doors',
    trade_text: 'Garage Doors',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'garden_rooms',
    trade_text: 'Garden Rooms',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'gas_engineer',
    trade_text: 'Gas Engineer',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'gates',
    trade_text: 'Gates',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Renewables',
    trade_type: 'heat_pumps',
    trade_text: 'Heat Pumps',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'heating_systems',
    trade_text: 'Heating Systems',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'home_insulation_excl_spray_foam',
    trade_text: 'Home Insulation Excluding Spray Foam',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'hot_tub_specialist',
    trade_text: 'Hot Tub Specialist',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'kitchen_fitter',
    trade_text: 'Kitchen Fitter',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'kitchen_supplier',
    trade_text: 'Kitchen Supplier',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'landscaping',
    trade_text: 'Landscaping',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'loft_boarding',
    trade_text: 'Loft Boarding',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'other_trade',
    trade_text: 'Painting and Decorating',
    lender: 'humm',
    lender_rate_type: 'unregulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'plasterer',
    trade_text: 'Plastering',
    lender: 'humm',
    lender_rate_type: 'unregulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'other_trade',
    trade_text: 'Kitchen Respray',
    lender: 'humm',
    lender_rate_type: 'unregulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'other_trade',
    trade_text: 'Carpets',
    lender: 'humm',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Renewables',
    trade_type: 'other_trade',
    trade_text: 'Renewables: Solar & Battery Storage',
    lender: 'humm',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'other_trade',
    trade_text: 'External Power Cleaning & Jet Washing',
    lender: 'humm',
    lender_rate_type: 'unregulated',
  },
  // DEPRECATED
  // {
  //   business_sector: 'Home improvement',
  //   trade_type: 'other_trade',
  //   trade_text: 'Waste removal',
  //   lender: 'humm',
  //   lender_rate_type: 'unregulated',
  // },
  // DEPRECATED
  // {
  //   business_sector: 'Home improvement',
  //   trade_type: 'other_trade',
  //   trade_text: 'Aquascaping/Pond builders',
  //   lender: 'humm',
  //   lender_rate_type: 'regulated',
  // },
  // DEPRECATED
  // {
  //   business_sector: 'Home improvement',
  //   trade_type: 'other_trade',
  //   trade_text: 'Locksmith',
  //   lender: 'humm',
  //   lender_rate_type: 'unregulated',
  // },
  // DEPRECATED
  // {
  //   business_sector: 'Home improvement',
  //   trade_type: 'other_trade',
  //   trade_text: 'Tree Surgery',
  //   lender: 'humm',
  //   lender_rate_type: 'unregulated',
  // },
  // DEPRECATED
  // {
  //   business_sector: 'Home improvement',
  //   trade_type: 'other_trade',
  //   trade_text: 'Architectural Services',
  //   lender: 'humm',
  //   lender_rate_type: 'unregulated',
  // },
  {
    business_sector: 'Home improvement',
    trade_type: 'other_trade',
    trade_text: 'Handyman',
    lender: 'humm',
    lender_rate_type: 'unregulated',
  },
  // DEPRECATED
  // {
  //   business_sector: 'Home improvement',
  //   trade_type: 'other_trade',
  //   trade_text: 'Swimming Pools',
  //   lender: 'humm',
  //   lender_rate_type: 'regulated',
  // },
  {
    business_sector: 'Retail',
    trade_type: 'other_trade',
    trade_text: 'Furniture',
    lender: 'humm',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Retail',
    trade_type: 'other_trade',
    trade_text: 'Jewllery',
    lender: 'humm',
    lender_rate_type: 'unregulated',
  },
  {
    business_sector: 'Retail',
    trade_type: 'other_trade',
    trade_text: 'Electronics',
    lender: 'humm',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Retail',
    trade_type: 'other_trade',
    trade_text: 'Tool Sales',
    lender: 'humm',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Retail',
    trade_type: 'other_trade',
    trade_text: 'Sports Equipment',
    lender: 'humm',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Retail',
    trade_type: 'other_trade',
    trade_text: 'High End Clothing',
    lender: 'humm',
    lender_rate_type: 'unregulated',
  },
  {
    business_sector: 'Retail',
    trade_type: 'other_trade',
    trade_text: 'Appliances',
    lender: 'humm',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Retail',
    trade_type: 'other_trade',
    trade_text: 'Mobillity and Mobility Scooters',
    lender: 'humm',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'IT and technology Services',
    trade_type: 'other_trade',
    trade_text: 'Website Builder',
    lender: 'humm',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'IT and technology Services',
    trade_type: 'other_trade',
    trade_text: 'Network Engineer',
    lender: 'humm',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'IT and technology Services',
    trade_type: 'other_trade',
    trade_text: 'Graphic Designer',
    lender: 'humm',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'IT and technology Services',
    trade_type: 'other_trade',
    trade_text: 'Computer Builder',
    lender: 'humm',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'IT and technology Services',
    trade_type: 'other_trade',
    trade_text: 'Technology Repair Shop',
    lender: 'humm',
    lender_rate_type: 'unregulated',
  },
  {
    business_sector: 'Automotive services',
    trade_type: 'other_trade',
    trade_text: 'Car Detailing and Alloys',
    lender: 'humm',
    lender_rate_type: 'unregulated',
  },
  {
    business_sector: 'Automotive services',
    trade_type: 'other_trade',
    trade_text: 'Car Body Shop',
    lender: 'humm',
    lender_rate_type: 'unregulated',
  },
  {
    business_sector: 'Automotive services',
    trade_type: 'other_trade',
    trade_text: 'Vehicle Wrapping',
    lender: 'humm',
    lender_rate_type: 'unregulated',
  },
  {
    business_sector: 'Automotive services',
    trade_type: 'other_trade',
    trade_text: 'Tyres',
    lender: 'humm',
    lender_rate_type: 'unregulated',
  },
  {
    business_sector: 'Automotive services',
    trade_type: 'other_trade',
    trade_text: 'Number Plates',
    lender: 'humm',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Training and education',
    trade_type: 'other_trade',
    trade_text: 'Training Courses',
    lender: 'humm',
    lender_rate_type: 'unregulated',
  },
  {
    business_sector: 'Training and education',
    trade_type: 'other_trade',
    trade_text: 'Driving Lessons',
    lender: 'humm',
    lender_rate_type: 'unregulated',
  },
  {
    business_sector: 'Events (including weddings)',
    trade_type: 'other_trade',
    trade_text: 'Full Package',
    lender: 'humm',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Events (including weddings)',
    trade_type: 'other_trade',
    trade_text: 'Planners',
    lender: 'humm',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Events (including weddings)',
    trade_type: 'other_trade',
    trade_text: 'Catering',
    lender: 'humm',
    lender_rate_type: 'unregulated',
  },
  {
    business_sector: 'Events (including weddings)',
    trade_type: 'other_trade',
    trade_text: 'Entertainers',
    lender: 'humm',
    lender_rate_type: 'unregulated',
  },
  {
    business_sector: 'Events (including weddings)',
    trade_type: 'other_trade',
    trade_text: 'Photography',
    lender: 'humm',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Events (including weddings)',
    trade_type: 'other_trade',
    trade_text: 'Flowers',
    lender: 'humm',
    lender_rate_type: 'unregulated',
  },
  {
    business_sector: 'Events (including weddings)',
    trade_type: 'other_trade',
    trade_text: 'Venues',
    lender: 'humm',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Events (including weddings)',
    trade_type: 'other_trade',
    trade_text: 'Amenities',
    lender: 'humm',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Events (including weddings)',
    trade_type: 'other_trade',
    trade_text: 'Events (e.g festivals)',
    lender: 'humm',
    lender_rate_type: 'unregulated',
  },
  {
    business_sector: 'Healthcare',
    trade_type: 'other_trade',
    trade_text: 'Dental',
    lender: 'humm',
    lender_rate_type: 'unregulated',
  },
  {
    business_sector: 'Healthcare',
    trade_type: 'other_trade',
    trade_text: 'Aesthetics',
    lender: 'humm',
    lender_rate_type: 'unregulated',
  },
  {
    business_sector: 'Healthcare',
    trade_type: 'other_trade',
    trade_text: 'Medical Assessments',
    lender: 'humm',
    lender_rate_type: 'unregulated',
  },
  {
    business_sector: 'Other',
    trade_type: 'other_trade',
    trade_text: 'Home Removals',
    lender: 'humm',
    lender_rate_type: 'unregulated',
  },
  {
    business_sector: 'Other',
    trade_type: 'other_trade',
    trade_text: 'Personal Security',
    lender: 'humm',
    lender_rate_type: 'unregulated',
  },
  {
    business_sector: 'Other',
    trade_type: 'other_trade',
    trade_text: 'Memberships (e.g. Golf Memberships)',
    lender: 'humm',
    lender_rate_type: 'unregulated',
  },
  {
    business_sector: 'Other',
    trade_type: 'other_trade',
    trade_text: 'Experiences',
    lender: 'humm',
    lender_rate_type: 'unregulated',
  },
  {
    business_sector: 'Other',
    trade_type: 'other_trade',
    trade_text: 'Other - Not Listed',
    lender: 'humm',
    lender_rate_type: 'unregulated',
  },
  {
    business_sector: 'Retail',
    trade_type: 'other_trade',
    trade_text: 'Media Walls',
    lender: 'humm',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'resin_driveways',
    trade_text: 'Resin Driveways',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'roofing__excluding_flat_roofs',
    trade_text: 'Roofing Excluding Flat Roofs',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'other_trade',
    trade_text: 'Roofline',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'rendering',
    trade_text: 'Rendering',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'other_trade',
    trade_text: 'Staircases',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'security_systems',
    trade_text: 'Security Systems and Lighting',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'tiler',
    trade_text: 'Tiling',
    lender: 'humm',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'other_trade',
    trade_text: 'Underfloor Heating',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'verandas',
    trade_text: 'Verandas',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'other_trade',
    trade_text: 'Wall Insulation and Cladding',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'other_trade',
    trade_text: 'Water Softeners',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'windows_and_doors',
    trade_text: 'Windows and Doors',
    lender: 'propensio',
    lender_rate_type: 'regulated',
  },
  {
    business_sector: 'Home improvement',
    trade_type: 'other_trade',
    trade_text: 'Damp Proofing',
    lender: 'humm',
    lender_rate_type: 'unregulated',
  },
];

export const TYPE_FIELD_PROPS = {
  name: 'company.business_config.trade_text',
  placeholder: 'Select...',
};

export const TYPE_FIELD_VALIDATION: ValidationItems = {
  required: { value: true, message: 'Business type is required.' },
};

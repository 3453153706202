import useQuery from 'hooks/useQuery';
import useSearchWords from 'hooks/useSearchWords';
import { useMemo } from 'react';
import { type QueryType } from 'store/slices/query';

export interface TableNameHook {
  name: string;
  searchWords: string[];
}

export default function useTableName(
  firstName: string,
  lastName: string,
  queryType: QueryType,
  example: boolean,
): TableNameHook {
  const { fetchedQuery: query } = useQuery(queryType);
  const { searchWords } = useSearchWords(query);

  const name = useMemo(() => {
    if (example) return 'Joe Bloggs';
    return [firstName, lastName].filter(Boolean).join(' ');
  }, [example, firstName, lastName]);

  return {
    name,
    searchWords,
  };
}

import { Dropzone } from '@kanda-libs/ks-component-ts';
import {
  DesktopLayoutDefault,
  DesktopLayoutDefaultContent,
  DesktopLayoutDefaultContentHeader,
} from '@kanda-libs/ks-design-library';

import { SideBar } from './components';
import Container from './DesktopLayout-container';

import CompanyHeader from '../CompanyHeader';

interface ViewComponentProps {
  /**
   * Children
   */
  children?: JSX.Element[] | JSX.Element;
  /**
   * Children
   */
  fullWidthContent?: boolean;
}

const ViewComponent = ({
  children,
  fullWidthContent = false,
}: ViewComponentProps) => (
  <Container fullWidthContent={fullWidthContent}>
    {({ dropZoneProps, classNames }) => (
      <>
        <Dropzone {...dropZoneProps}>
          <DesktopLayoutDefault>
            <DesktopLayoutDefaultContentHeader>
              <CompanyHeader />
            </DesktopLayoutDefaultContentHeader>
            <DesktopLayoutDefaultContent>
              <div className={classNames.container}>
                <div className={classNames.sidebar}>
                  <SideBar />
                </div>
                <div className={classNames.content}>{children}</div>
              </div>
            </DesktopLayoutDefaultContent>
          </DesktopLayoutDefault>
        </Dropzone>
      </>
    )}
  </Container>
);

ViewComponent.defaultProps = {
  children: null,
};

ViewComponent.displayName = 'MyAccount-DesktopLayout-view';

export default ViewComponent;

import { useCurrentUser } from '@kanda-libs/ks-frontend-services';
import { useCurrentCompany, useMe } from 'hooks';
import { useMemo } from 'react';
import {
  LOGOUT_LINK,
  MENU_ADMIN_LINKS,
  MENU_LINKS,
  MENU_VERIFICATION_LINKS,
  STAFF_IMITATE_USER_MENU_LINK,
} from './constants';

export interface MenuItem {
  icon: string;
  name: string;
  stroke?: string;
  size?: number;
  to?: string;
  onClick?: () => void;
}

export interface NavMenuHook {
  isLoading: boolean;
  items?: MenuItem[];
}

export default function useNavMenu(): NavMenuHook {
  const { isValidating: isUserLoggedInIsValidating } = useCurrentUser();
  const { me, isValidating: meIsValidating } = useMe();
  const { isLoading: companyIsLoading, directorsAreVerified } =
    useCurrentCompany();

  const isLoading = useMemo(
    () => isUserLoggedInIsValidating || meIsValidating || companyIsLoading,
    [isUserLoggedInIsValidating, meIsValidating, companyIsLoading],
  );

  const items = useMemo(() => {
    if (isLoading) return undefined;
    const role = me?.role;
    if (!role) return undefined;
    const isStaff = role === 'staff';
    const isAdmin = !['company-staff', 'company-manager'].includes(role);
    if (!isStaff)
      return [
        ...MENU_LINKS,
        ...(isAdmin ? MENU_ADMIN_LINKS : []),
        ...(!directorsAreVerified && isAdmin ? [MENU_VERIFICATION_LINKS] : []),
        LOGOUT_LINK,
      ];

    return [...MENU_LINKS, STAFF_IMITATE_USER_MENU_LINK, LOGOUT_LINK];
  }, [me, directorsAreVerified, isLoading]);

  return { isLoading, items };
}

import {
  SkeletonLoader,
  Tag,
  type TagProps,
} from '@kanda-libs/ks-design-library';
import type { InterestInFinance } from 'pages/Leads/helpers';
import useInterestInFinanceTag from './useInterestInFinanceTag';

export interface InterestInFinanceTagProps {
  interest: InterestInFinance;
  size?: TagProps['size'];
  className?: string;
  isLoading?: boolean;
}

const InterestInFinanceTag: React.FunctionComponent<InterestInFinanceTagProps> =
  function ({ interest, size = 24, className = '', isLoading = false }) {
    const { label, color } = useInterestInFinanceTag(interest);
    if (!label) return null;
    return (
      <SkeletonLoader
        isLoading={isLoading}
        height={size}
        width={80}
        afterLoading={
          <Tag
            color={color}
            label={label}
            size={size}
            className={className}
            variant="solid"
          />
        }
      />
    );
  };

export default InterestInFinanceTag;

import type {
  FinanceRate,
  InfoWorkType,
  WorkType,
} from '@kanda-libs/ks-frontend-services';
import { useCurrentCompany } from 'hooks';
import useIsExampleJob from 'pages/CreateJob/CreateJob-useIsExampleJob';
import {
  createContext,
  useContext,
  useMemo,
  type FunctionComponent,
  type ReactNode,
} from 'react';
import { useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import {
  calculateJobTotal,
  formatLenderConfigs,
  getCurrentInfoWorkType,
  getStoredFinanceRates,
  getValidFinanceRates,
  type FormattedLenderConfig,
} from 'utils';

export interface FinanceRatesContextType {
  lenderConfigs: FormattedLenderConfig | undefined;
  companyRates: FinanceRate[] | undefined;
  infoWorkTypes: InfoWorkType[] | undefined;
  infoWorkType: InfoWorkType | undefined;
  rates: FinanceRate[] | undefined;
  allRates: FinanceRate[] | undefined;
  storedRates: FinanceRate[] | undefined;
  total: number;
  depositAmount: number;
  workType: WorkType | undefined;
  showStoredRates: boolean;
  isExample: boolean;
  isLoading: boolean;
}

export const FinanceRatesContext = createContext<FinanceRatesContextType>({
  lenderConfigs: undefined,
  companyRates: undefined,
  infoWorkTypes: undefined,
  infoWorkType: undefined,
  rates: undefined,
  allRates: undefined,
  storedRates: undefined,
  total: 0,
  depositAmount: 0,
  workType: undefined,
  showStoredRates: false,
  isExample: false,
  isLoading: true,
});

export const useFinanceRatesContext = () => useContext(FinanceRatesContext);

export interface FinanceRatesProviderProps {
  children: ReactNode;
  showStoredRates: boolean;
}

const FinanceRatesProvider: FunctionComponent<FinanceRatesProviderProps> =
  function ({ children, showStoredRates }) {
    const { company, isLoading: companyIsLoading } = useCurrentCompany();
    const isExample = useIsExampleJob();

    const lenderConfigs = useMemo(() => {
      if (!company) return undefined;
      return formatLenderConfigs(company);
    }, [company]);

    const infoWorkTypes = useSelector(selectors.infoWorkType.getRawResponse) as
      | InfoWorkType[]
      | undefined;
    const infoWorkTypeIsLoading = useSelector(
      selectors.infoWorkType.getHasFetched,
    );

    const isLoading = useMemo(
      () => companyIsLoading || infoWorkTypeIsLoading,
      [companyIsLoading, infoWorkTypeIsLoading],
    );

    const [workType, depositAmount, lineItems, selectedFinanceOptions] =
      useWatch({
        name: [
          'job.work_type',
          'job.deposit_value.amount',
          'job.job_items',
          'job.finance_options',
        ],
      });

    const total = useMemo(() => {
      const totals = calculateJobTotal(lineItems);
      return totals.totalIncVat;
    }, [lineItems]);

    const companyRates = useMemo(() => company?.finance_rates, [company]);

    const infoWorkType = useMemo(
      () => getCurrentInfoWorkType(companyRates, infoWorkTypes, workType),
      [companyRates, infoWorkTypes, workType],
    );

    const allRates = useMemo(
      () => getValidFinanceRates(infoWorkType, total, depositAmount),
      [infoWorkType, total, depositAmount],
    );

    const storedRates = useMemo(
      () =>
        getStoredFinanceRates(
          allRates,
          selectedFinanceOptions,
          total,
          depositAmount,
        ),
      [allRates, selectedFinanceOptions, total, depositAmount],
    );

    const rates = useMemo(() => {
      if (showStoredRates) return storedRates;
      return allRates;
    }, [showStoredRates, storedRates, allRates]);

    const values = useMemo(
      () => ({
        lenderConfigs,
        companyRates,
        infoWorkTypes,
        infoWorkType,
        rates,
        allRates,
        storedRates,
        total,
        depositAmount,
        workType,
        showStoredRates,
        isExample,
        isLoading,
      }),
      [
        lenderConfigs,
        companyRates,
        infoWorkTypes,
        infoWorkType,
        rates,
        allRates,
        storedRates,
        total,
        depositAmount,
        workType,
        showStoredRates,
        isExample,
        isLoading,
      ],
    );

    return (
      <FinanceRatesContext.Provider value={values}>
        {children}
      </FinanceRatesContext.Provider>
    );
  };

export default FinanceRatesProvider;

import { Button, type ButtonTextProps } from '@kanda-libs/ks-design-library';
import useLeadUnarchive from './useLeadUnarchive';

export interface LeadUnarchiveProps {
  size?: ButtonTextProps['size'];
}

const LeadUnarchive: React.FunctionComponent<LeadUnarchiveProps> = function ({
  size = 48,
}) {
  const { onClick, isSubmitting } = useLeadUnarchive();
  return (
    <Button.Text
      id="lead-unarchive-to-job"
      size={size}
      onClick={onClick}
      disabled={isSubmitting}
      icon="check"
      label="Unarchive Lead"
    />
  );
};

export default LeadUnarchive;

import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import { useMemo } from 'react';

export interface FinanceOptionPendingHook {
  popoverId: string;
}

export default function useFinanceOptionPending(
  rates: FinanceRate[],
): FinanceOptionPendingHook {
  const rate = rates[0];

  const popoverId = useMemo(() => {
    const { provider, name } = rate;
    return [provider, name].join('-');
  }, [rate]);

  return {
    popoverId,
  };
}

import {
  useFormContext,
  useWatch,
  Widget,
  type AddressApiData,
  type ValidationItems,
  type ValidationProps,
} from '@kanda-libs/ks-component-ts';
import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import type { Company } from '@kanda-libs/ks-frontend-services';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getValidationConditions,
  getValidationErrors,
  validateAddressSelect,
} from 'utils';

interface AddressFields {
  Postcode: (props: any) => JSX.Element;
  Line1: (props: any) => JSX.Element;
  Line2: (props: any) => JSX.Element;
  City: (props: any) => JSX.Element;
  County: (props: any) => JSX.Element;
  Country: (props: any) => JSX.Element;
}

interface AddressFieldsHook {
  Fields: AddressFields;
  addresses: AddressApiData | undefined;
  postcodeName: string;
  postcodeCallback: (res: AddressApiData) => void;
  manual: boolean;
  addManualInput: () => void;
  showSelect: boolean;
  selectProps: ValidationProps;
  labelPrefix: string;
}

const getPostcodeName = (companyType: Company['company_type']): string => {
  if (companyType === 'limited_company')
    return 'company.limited_company_info.company_address.postcode';
  return 'company.sole_trader_info.trading_address.postcode';
};

const getAddressName = (companyType: Company['company_type']): string => {
  if (companyType === 'limited_company')
    return 'company.limited_company_info.company_address';
  return 'company.sole_trader_info.trading_address';
};

export default function useAddressFields(
  companyType: Company['company_type'],
): AddressFieldsHook {
  const [addresses, setAddresses] = useState<AddressApiData | undefined>(
    undefined,
  );
  const [manual, setManual] = useState(false);

  const { setValue, getValues } = useFormContext();

  const postcodeCallback = useCallback(
    (results: AddressApiData) => setAddresses(results),
    [],
  );

  const postcodeName = useMemo(
    () => getPostcodeName(companyType),
    [companyType],
  );

  const addressName = useMemo(() => getAddressName(companyType), [companyType]);

  const [postcode, address] = useWatch({
    name: [postcodeName, addressName],
  });

  const showSelect = postcode && postcode !== '' && addresses;

  const addManualInput = () => setManual(!manual);

  const Fields = useMemo(() => {
    if (companyType === 'limited_company')
      return {
        Postcode: Widget.CompanyLimitedCompanyInfoCompanyAddressPostcode,
        Line1: Widget.CompanyLimitedCompanyInfoCompanyAddressLine1,
        Line2: Widget.CompanyLimitedCompanyInfoCompanyAddressLine2,
        City: Widget.CompanyLimitedCompanyInfoCompanyAddressCity,
        County: Widget.CompanyLimitedCompanyInfoCompanyAddressCounty,
        Country: Widget.CompanyLimitedCompanyInfoCompanyAddressCountry,
      };
    return {
      Postcode: Widget.CompanySoleTraderInfoTradingAddressPostcode,
      Line1: Widget.CompanySoleTraderInfoTradingAddressLine1,
      Line2: Widget.CompanySoleTraderInfoTradingAddressLine2,
      City: Widget.CompanySoleTraderInfoTradingAddressCity,
      County: Widget.CompanySoleTraderInfoTradingAddressCounty,
      Country: Widget.CompanySoleTraderInfoTradingAddressCountry,
    };
  }, [companyType]);

  const validation = {
    validate: {
      value: () => validateAddressSelect(getValues(), addressName),
      message:
        'You must select an address or enter the address details manually',
    },
  } as ValidationItems;

  const selectProps = {
    validationConditions: getValidationConditions(validation),
    validationErrors: getValidationErrors(validation),
  } as unknown as ValidationProps;

  const labelPrefix = useMemo(
    () => (companyType === 'limited_company' ? 'Company' : 'Home'),
    [companyType],
  );

  useEffect(() => {
    if (
      !address?.line_1 ||
      !addresses?.addresses ||
      addresses?.addresses?.length === 0
    )
      return;
    const match = addresses.addresses.findIndex(
      (addr) => (addr as StringIndexedObject).line_1 === address.line_1,
    );
    if (match === address?.selected) return;
    setValue(`${addressName}.selected`, match);
  }, [address, addresses, addressName, setValue]);

  return {
    Fields,
    addresses,
    postcodeName,
    postcodeCallback,
    manual,
    addManualInput,
    showSelect,
    selectProps,
    labelPrefix,
  };
}

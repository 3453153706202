import {
  AnimatedModalContainer,
  Animations,
  Icon,
  ModalLayoutSlideLeft,
  useIsDesktop,
} from '@kanda-libs/ks-design-library';
import { type FunctionComponent } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { NAV_MENU_ID } from './constants';
import NavMenuHeader from './NavMenuHeader';
import useNavMenu from './useNavMenu';

const NavMenu: FunctionComponent = function () {
  const { items } = useNavMenu();
  const isDesktop = useIsDesktop();
  const { pathname } = useLocation();
  return (
    <AnimatedModalContainer id={NAV_MENU_ID} opacity={50}>
      {({ isVisible, hideModal, handleClose }) => (
        <Animations.SlideLeft show={isVisible} onEnd={hideModal}>
          <ModalLayoutSlideLeft>
            <div className="flex flex-col -my-8 -mx-6 h-vvp max-h-vvp">
              <NavMenuHeader onClose={handleClose} />
              <div className="h-[calc(100%-80px)] overflow-scroll">
                <div className="px-6 pb-8 pt-4 ">
                  {items &&
                    items.map(
                      ({ icon, name, stroke, size, to, onClick }, index) => {
                        const Tag = to ? Link : 'button';
                        const tagProps = {
                          ...(to ? { to } : {}),
                          ...(onClick
                            ? {
                                onClick: () => {
                                  onClick();
                                  handleClose();
                                },
                              }
                            : { onClick: handleClose }),
                        };
                        const active =
                          to && !onClick
                            ? matchPath(pathname, {
                                path: to,
                                exact: true,
                                strict: true,
                              })
                            : false;
                        return (
                          <Tag
                            {...tagProps}
                            className="flex flex-col cursor-pointer"
                            key={name}
                          >
                            {index > 0 && (
                              <div className="w-full border-b border-neutral-200 mx-auto" />
                            )}
                            <div className="flex flex-1 w-full h-8 items-center py-2 md:py-2.5">
                              <Icon
                                icon={icon}
                                stroke={active ? 'green-500' : stroke}
                                size={size || isDesktop ? 28 : 20}
                              />
                              <p
                                className={`ml-4 style-d-em ${
                                  active ? ' text-green-500' : ''
                                }`}
                              >
                                {name}
                              </p>
                            </div>
                          </Tag>
                        );
                      },
                    )}
                </div>
              </div>
            </div>
          </ModalLayoutSlideLeft>
        </Animations.SlideLeft>
      )}
    </AnimatedModalContainer>
  );
};

export default NavMenu;

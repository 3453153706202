import { useForm } from '@kanda-libs/ks-component-ts';
import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import type { Job } from '@kanda-libs/ks-frontend-services';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import useIsExampleJob from 'pages/CreateJob/CreateJob-useIsExampleJob';
import { useCallback } from 'react';
import { getSelectedFinanceRates } from 'utils';

import { BASE_BUTTON_PROPS } from './Summary-constants';

import useIsSubscribed from '../../../../../../hooks/useIsSubscribed';
import useSubmitDraft from '../../../../CreateJob-useSubmitDraft';
import useSubmitJob from '../../../../CreateJob-useSubmitJob';

export interface SummaryContainerFormValues {
  job: Partial<Job>;
}

export interface SummaryContainerChildrenArgs {
  prevStep: () => void;
  isSubmitting: boolean;
  showSubmit: boolean;
  nextButtonProps: {
    disabled: boolean;
    icon: string;
    label: string;
    left: boolean;
    submit: boolean;
  };
  form: ReturnType<typeof useForm<SummaryContainerFormValues>>;
  handleSubmit: (formValues: StringIndexedObject) => void;
}

export interface SummaryContainerProps {
  children: (args: SummaryContainerChildrenArgs) => JSX.Element;
}

const SummaryContainer = ({ children }: SummaryContainerProps) => {
  const { data, prevStep } = useCreateJobContextValue();

  const company = data?.company;

  const { handleSubmit: submitJob, isSubmitting } = useSubmitJob();

  const { handleSubmit: submitDraft } = useSubmitDraft();

  const form = useForm<SummaryContainerFormValues>({
    mode: 'onBlur',
    defaultValues: {
      ...data,
      job: {
        ...data?.job,
        notes: [''],
      },
    },
  });

  const handleSubmit = useCallback(
    (formValues: StringIndexedObject) => {
      const notes = formValues?.job?.notes || null;
      const selectedRates = formValues?.job?.finance_options || undefined;
      const formData = {
        ...data,
        job: {
          ...(data.job as Job),
          finance_options: getSelectedFinanceRates(
            data.company?.finance_rates || [],
            selectedRates,
          ),
          ...(notes ? { notes } : {}),
        },
      };
      const handler = data?.job?.id ? submitDraft : submitJob;
      handler(formData);
    },
    [data, submitDraft, submitJob],
  );

  const { isSubscribed } = useIsSubscribed();

  const isExampleJob = useIsExampleJob();

  const showSubmit =
    isExampleJob || (company?.lifecycle === 'verified' && isSubscribed);

  return children({
    prevStep,
    isSubmitting,
    showSubmit,
    nextButtonProps: {
      ...BASE_BUTTON_PROPS,
      disabled: isSubmitting || !showSubmit,
    },
    form,
    handleSubmit,
  });
};

SummaryContainer.displayName = 'CreateJob-Summary-Desktop-container';

export default SummaryContainer;

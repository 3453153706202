import HummWrapper from 'components/HummWrapper';
import UserHasCompletedOnboardingWrapper from 'components/UserHasCompletedOnboardingWrapper';
import WorkTypesWrapper from 'components/WorkTypesWrapper';
import type { FunctionComponent } from 'react';

export interface CombinedStateWrapperProps {
  children: JSX.Element;
}

const CombinedStateWrapper: FunctionComponent<CombinedStateWrapperProps> =
  function ({ children }) {
    return (
      <WorkTypesWrapper>
        <HummWrapper>
          <UserHasCompletedOnboardingWrapper>
            {children}
          </UserHasCompletedOnboardingWrapper>
        </HummWrapper>
      </WorkTypesWrapper>
    );
  };

export default CombinedStateWrapper;

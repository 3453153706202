import type { WelcomeSkipBanner } from '@kanda-libs/ks-frontend-services/src/generated/components/schemas/WelcomeSkipBanner';

export const CALENDLY_PRODUCT_CALL_LINK =
  'https://calendly.com/kanda-team/call-with-kanda-product';

export const DOB_VALIDATION = {
  required: {
    value: true,
    message: 'Date of birth is required',
  },
  pattern: {
    value: /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}/,
    message: 'Date Of Birth input is invalid',
  },
};

export const EMPTY_WELCOME_PACK: WelcomeSkipBanner = {
  invite_team_member: 'no',
  update_website: 'no',
  advert_finance_calculator: 'no',
};

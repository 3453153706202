import useIsSubscribed from 'hooks/useIsSubscribed';
import { useLeadsContext } from 'pages/Leads/context';
import { useMemo } from 'react';
import { checkCompanySetupInProgress } from './helpers';

export interface LeadsBannerHook {
  isLoading: boolean;
  showOptions: boolean;
  setupInProgress: boolean;
  hasActiveFilters: boolean;
  showSubscriptionBanner: boolean;
  archive: boolean;
  showSearch: boolean;
}

export default function useLeadsBanner(): LeadsBannerHook {
  const {
    isLoading,
    noLeads,
    company,
    hasActiveFilters,
    archive,
    showOptions,
  } = useLeadsContext();

  const setupInProgress = useMemo(
    () => checkCompanySetupInProgress(company),
    [company],
  );

  const { isSubscribed } = useIsSubscribed();

  const showSubscriptionBanner = useMemo(
    () => !isLoading && !setupInProgress && !archive && !isSubscribed,
    [isLoading, setupInProgress, archive, isSubscribed],
  );

  const showSearch = useMemo(() => {
    if (isLoading) return true;
    return !noLeads;
  }, [isLoading, noLeads]);

  return {
    isLoading,
    showOptions,
    setupInProgress,
    hasActiveFilters,
    showSubscriptionBanner,
    archive,
    showSearch,
  };
}

import { useForm } from '@kanda-libs/ks-component-ts';
import type { JobItem } from '@kanda-libs/ks-frontend-services';
import useMostRecentJob from 'hooks/useMostRecentJob';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import type { CreateJobContextValue } from 'pages/CreateJob/types';
import { useCallback, useEffect, useMemo } from 'react';

import { BASE_FORM, BASE_ITEM } from '../../../../CreateJob-constants';

import { calculateJobTotal } from '../../../../../../utils';

const ContainerComponent = ({ children }) => {
  const { data, handleContinue } = useCreateJobContextValue();

  const recentJob = useMostRecentJob();

  const isLoading = data?.isLoading;

  const isSolar = useMemo(() => {
    if (!data) return false;
    return data?.job?.job_type === 'solar';
  }, [data]);

  const depositPercentage = useMemo(() => {
    if (!data?.job?.job_items) return 0;
    if (data?.job?.deposit_type !== 'partial_deposit') return 0;
    const deposit = data?.job?.deposit_value?.amount;
    const { totalIncVat: total } = calculateJobTotal(data?.job?.job_items);
    return Math.round((deposit / total) * 100);
  }, [data]);

  const formatJobItems = (jobItems: JobItem[]) => {
    if (!jobItems) return [BASE_ITEM];
    return jobItems.map((item) => ({
      ...BASE_ITEM,
      ...item,
      price: {
        ...BASE_ITEM.price,
        ...item?.price,
      },
      vat: {
        ...BASE_ITEM.vat,
        ...item?.vat,
      },
    }));
  };

  const defaultValues = useMemo(() => {
    if (!data?.job)
      return { ...BASE_FORM, work_type: recentJob?.work_type || '' };

    return {
      job: {
        ...data.job,
        work_type: data.job?.work_type || recentJob?.work_type || '',
        job_items: formatJobItems(data?.job?.job_items),
      },
      depositPercentage,
    } as CreateJobContextValue;
  }, [data, depositPercentage, recentJob?.work_type]);

  const form = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const { reset } = form;

  const onSubmit = useCallback(
    (submittedData: CreateJobContextValue) => {
      handleContinue({
        ...data,
        ...submittedData,
      });
    },
    [data, handleContinue],
  );

  const nextButtonProps = {
    submit: true,
  };

  // Effect updates the form with new default values after a draft has loaded
  useEffect(() => {
    if (!defaultValues) return;
    reset(defaultValues as CreateJobContextValue);
    setTimeout(() => reset(defaultValues as CreateJobContextValue), 10);
  }, [reset, defaultValues]);

  return children({
    form,
    onSubmit,
    nextButtonProps,
    isLoading,
    isSolar,
  });
};

ContainerComponent.displayName = 'CreateJob-Mobile-Details-container';

export default ContainerComponent;

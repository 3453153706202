import type { SelectOption } from '@kanda-libs/ks-component-ts';
import type { BusinessSector } from '@kanda-libs/ks-frontend-services';
import type { FieldValues } from 'pages/Setup/Account/useSetupAccountForm';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { BASE_CONFIGS, SECTORS } from './constants';
import { matchConfig } from './helpers';

export type SectorProps = {
  label: string;
  onClick: () => void;
  active: boolean;
};

export interface WorkTypesFormHook {
  show: boolean;
  sectors: SectorProps[];
  tradeTypes: SelectOption[] | undefined;
  showSearch: boolean;
  onShowSearchClick: () => void;
}

export default function useWorkTypesForm(): WorkTypesFormHook {
  const [showSearch, setShowSearch] = useState<boolean>(false);

  const [sector, companyType, tradeText] = useWatch<
    FieldValues,
    [
      'company.business_config.business_sector',
      'company.company_type',
      'company.business_config.trade_text',
    ]
  >({
    name: [
      'company.business_config.business_sector',
      'company.company_type',
      'company.business_config.trade_text',
    ],
  });
  const { setValue } = useFormContext();

  const sectors = useMemo(
    () =>
      (Object.keys(SECTORS) as BusinessSector[]).map((key) => ({
        onClick: () => {
          setValue('company.business_config.business_sector', key);
          setValue('company.business_config.trade_text', '');
        },
        label: `${SECTORS[key]}\u00A0\u00A0${key}`,
        active: sector === key,
      })),
    [sector, setValue],
  );

  const tradeTypes = useMemo(() => {
    if (!sector) return undefined;
    return BASE_CONFIGS.filter((config) => config.business_sector === sector)
      .map((config) => ({
        name: config.trade_text,
        value: config.trade_text,
      }))
      .sort((option1: SelectOption, option2: SelectOption) => {
        if (option1.value.toLocaleLowerCase().includes('not listed')) return 1;
        if (option2.value.toLocaleLowerCase().includes('not listed')) return -1;
        return option1.value.localeCompare(option2.value);
      });
  }, [sector]);

  const onShowSearchClick = useCallback(() => setShowSearch(true), []);

  useEffect(() => {
    if (sector === undefined || tradeText === undefined) return;
    if (tradeText === '') {
      setValue('company.business_config.trade_type', undefined);
      setValue('company.business_config.lender', undefined);
      setValue('company.business_config.lender_rate_type', undefined);
      setValue('company.business_config.extra', undefined);
      return;
    }
    const config = matchConfig(sector, tradeText);
    setValue('company.business_config', config);
  }, [sector, tradeText, setValue]);

  return {
    show: Boolean(companyType),
    sectors,
    tradeTypes,
    showSearch,
    onShowSearchClick,
  };
}

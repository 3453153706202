import { ReactComponent as TrustpilotLogo } from 'assets/truspilot-logo-black.svg';
import { useMemo, type FunctionComponent } from 'react';
import { TRUST_PILOT_LINK } from './constants';
import TrustpilotStars from './Stars';

export type TrustPilotRating =
  | 'Bad'
  | 'Poor'
  | 'Average'
  | 'Great'
  | 'Excellent';

export interface TrustPilotRatingProps {
  href?: string;
  wrapperClassName?: string;
  reviews?: number;
  stars?: number;
  rating?: TrustPilotRating;
}

const TrustPilotRating: FunctionComponent<TrustPilotRatingProps> = function ({
  href = TRUST_PILOT_LINK,
  wrapperClassName,
  reviews = 386,
  stars = 4.6,
  rating = 'Excellent',
}) {
  const className = useMemo(
    () => [wrapperClassName, 'flex w-full'].filter(Boolean).join(' '),
    [wrapperClassName],
  );

  return (
    <div className={className}>
      <a
        href={href}
        rel="noreferrer"
        target="_blank"
        className="flex flex-col gap-y-1 mx-auto"
        aria-label="Trustpilot"
      >
        <TrustpilotLogo height={36} />
        <p className="mt-2 text-20-24-em">{rating}</p>
        <TrustpilotStars stars={stars} />
        <div className="flex flex-row text-16-24">
          <p>
            TrustScore <span className="text-16-24-em">{stars}</span> |{' '}
            <span className="text-16-24-em">{reviews}</span> reviews
          </p>
        </div>
      </a>
    </div>
  );
};

export default TrustPilotRating;

import { RowFields, Widget } from '@kanda-libs/ks-component-ts';

import { DOB_VALIDATION } from 'common/constants';
import DirectorAddress from 'components/DirectorAddress';
import DirectorNameCard from '../../../../../../../../../../components/DirectorNameCard';
import { DATE_OF_BIRTH_NAME } from './constants';
import Container from './DirectorCard-container';

const ViewComponent = ({ ...props }) => (
  <Container {...props}>
    {({
      name,
      number,
      buttonProps,
      index,
      shortName,
      show,
      emailValidation,
      mobileValidation,
      isLoading,
    }) => {
      if (!show) return <div />;
      return (
        <div className="flex flex-col mt-10 first:mt-0">
          <DirectorNameCard
            name={name}
            number={number}
            isLoading={isLoading}
            buttonProps={buttonProps}
          />
          <Widget.CompanyUsersEmailArrayInput
            index={index}
            label="Email address"
            placeholder={`${shortName}'s email address`}
            validation={emailValidation}
            isLoading={isLoading}
          />
          <RowFields>
            <Widget.CompanyUsersMobileArrayInput
              index={index}
              label="Phone number"
              placeholder="07123456789"
              validation={mobileValidation}
              isLoading={isLoading}
            />
            <Widget.CompanyUsersDirectorInfoDateOfBirthArrayInput
              name={DATE_OF_BIRTH_NAME}
              index={index}
              validation={DOB_VALIDATION}
            />
          </RowFields>
          <DirectorAddress
            isArray
            widgetTagName="CompanyUsersDirectorInfoHomeAddress"
            labelPrefix={`${shortName}'s home address`}
            index={index}
          />
        </div>
      );
    }}
  </Container>
);

ViewComponent.displayName = 'SetupCompany-DirectorInfo-Form-Limited-Card-view';

export default ViewComponent;

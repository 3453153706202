import { useMemo } from 'react';
import { BASE_STYLE } from './constants';
import { ReactComponent as Star } from './Star.svg';

export interface TrustpilotStarsProps {
  stars: number;
  filledColor?: string;
  emptyColor?: string;
}

const TrustpilotStars: React.FunctionComponent<TrustpilotStarsProps> =
  function ({ stars, filledColor = '#00B67A', emptyColor = '#DCDCE6' }) {
    const formatted = useMemo(() => {
      const numFullStars = Math.floor(stars);
      const numEmptyStars = Math.floor(5 - stars);
      const pct = Math.round((stars - numFullStars) * 10) * 10;
      return [
        ...Array(numFullStars)
          .fill(null)
          .map((_, i) => ({
            key: `filled-star-${i + 1}`,
            style: {
              ...BASE_STYLE,
              backgroundColor: filledColor,
            },
          })),
        {
          key: '',
          style: {
            ...BASE_STYLE,
            background: `linear-gradient(90deg, ${filledColor} ${pct}%, ${emptyColor} ${pct}%)`,
          },
        },
        ...Array(numEmptyStars)
          .fill(null)
          .map((_, i) => ({
            key: `empty-star-${i + 1}`,
            style: {
              ...BASE_STYLE,
              backgroundColor: emptyColor,
            },
          })),
      ];
    }, [stars, emptyColor, filledColor]);

    return (
      <div className="flex flex-row gap-x-1">
        {formatted.map((star) => (
          <div key={star.key} style={star.style}>
            <Star width={28} height={28} className="m-auto text-neutral-000" />
          </div>
        ))}
      </div>
    );
  };

export default TrustpilotStars;

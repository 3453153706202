import { type CellClassNames } from './useTableCell';

export const HIGHLIGHT_PROPS = {
  autoEscape: true,
};

export const CLASS_NAMES: CellClassNames = {
  highlight: 'text-green-300 bg-transparent',
  wrapper: 'flex flex-row',
  text: 'text-16-20 text-neutral-900 text-left',
};

import { BreakPoints } from '@kanda-libs/ks-design-library';

import { Provider } from './Context';
import Desktop from './Desktop';
import Mobile from './Mobile';

import { Helmet } from '../../components';

const ViewComponent = ({}) => (
  <Provider>
    <Helmet title="Jobs" />
    <BreakPoints mobile={<Mobile />} desktop={<Desktop />} />
  </Provider>
);

ViewComponent.displayName = 'Jobs-view';

export default ViewComponent;

import { Dropzone, Form, FormTheme } from '@kanda-libs/ks-component-ts';
import { Loader } from '@kanda-libs/ks-design-library';

import { Form as InfoForm, Info } from './components';
import Container from './DirectorInfo-container';

const ViewComponent = () => (
  <Container>
    {({ form, onSubmit, isLoading, isSubmitting, ...rest }) => (
      <Form
        id="onboarding-director-info"
        form={form}
        onSubmit={onSubmit}
        isLoading={isLoading}
      >
        <FormTheme variant="streamline">
          <Dropzone>
            <div className="flex flex-col -mt-6 md:mt-0 md:flex-row flex-1 md:p-16 lg:p-20 w-full max-w-256 mx-auto">
              <Info />
              <InfoForm
                isLoading={isLoading}
                isSubmitting={isSubmitting}
                {...rest}
              />
            </div>
          </Dropzone>
        </FormTheme>
        <Loader isLoading={isSubmitting} />
      </Form>
    )}
  </Container>
);

ViewComponent.displayName = 'SetupCompany-DirectorInfo-view';

export default ViewComponent;

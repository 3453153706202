import type { GroupedByAprAndFee } from 'utils';
import Option from './Option';

export interface OptionsProps {
  rates: GroupedByAprAndFee;
  toggleable: boolean;
}

const Options: React.FunctionComponent<OptionsProps> = function ({
  rates,
  toggleable,
}) {
  const aprs = Object.keys(rates);
  return (
    <div className="flex flex-col gap-y-3">
      {aprs.map((apr) => (
        <Option
          key={apr}
          apr={apr}
          rates={rates[apr]}
          toggleable={toggleable}
        />
      ))}
    </div>
  );
};

export default Options;

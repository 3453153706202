import type { SelectOption } from '@kanda-libs/ks-component-ts';
import type { WorkType } from '@kanda-libs/ks-frontend-services';

export interface WorkTypeOption extends Omit<SelectOption, 'name'> {
  name?: string;
}
export const HOME_IMPROVEMENT_WORKTYPES = [
  'air_conditioning',
  'air_source_heat_pumps',
  'alarm_systems',
  'artificial_grass',
  'bathrooms_supply_and_fitted',
  'bathrooms_supply_only',
  'battery_storage',
  'bedrooms_furnitue_custom',
  'blinds_awnings_shutters',
  'boiler_swap',
  'cctv',
  'central_heating_system',
  'conservatory',
  'conservatory_roof',
  'damp_proofing',
  'decking',
  'door_canopy',
  'doors',
  'driveways',
  'electrical_installation',
  'ev_charger',
  'fencing',
  'fireplaces',
  'fitted_furniture',
  'flooring_excluding_carpets',
  'garage_doors',
  'garden_buildings',
  'gates',
  'glass_splash_backs',
  'granite_worktops',
  'ground_source_heat_pumps',
  'home_lifts',
  'home_study',
  'hot_tubs_and_spas',
  'insulation_roof_foil_wrap_only',
  'kitchens_fitted',
  'kitchens_supply_only',
  'landscaping',
  'loft_boarding',
  'loft_conversion',
  'media_walls',
  'painting_and_decorating',
  'patios',
  'plastering',
  'porch',
  'rendering',
  'resin_driveways',
  'roofing',
  'roofline',
  'security_lighting',
  'solar_pv_panels',
  'stair_lifts',
  'staircases',
  'tiling',
  'under_stair_storage',
  'underfloor_heating',
  'upvc_doors',
  'verandas',
  'wall_insulation_and_cladding',
  'wardrobes',
  'water_softeners',
  'windows',
  'windows_and_doors',
];

export const NON_HOME_IMPROVEMENT_WORKTYPES = [
  'aesthetics',
  'appliances',
  'car_body_shop',
  'car_detailing_and_alloys',
  'catering',
  'computer_builder',
  'dental',
  'electronics',
  'entertainers',
  'events',
  'experiences',
  'flowers',
  'full_events_package',
  'furniture',
  'graphic_designer',
  'high_end_clothing',
  'home_removals',
  'jewllery',
  'medical_assessments',
  'memberships',
  'mobillity_and_mobility_scooters',
  'network_engineer',
  'number_plates',
  'personal_security',
  'photography',
  'planners',
  'sports equipment',
  'technology_repair',
  'tool sales',
  'tyres',
  'vehicle_wrapping',
  'venues',
  'website_builder',
];

export const WORK_TYPES = [
  ...HOME_IMPROVEMENT_WORKTYPES,
  ...NON_HOME_IMPROVEMENT_WORKTYPES,
];

export const NAME = 'job.work_type';

interface WorkTypeLabelProps {
  label: string;
  helperText: string;
  inlineHelperText: boolean;
}

export const LABEL_PROPS: WorkTypeLabelProps = {
  label: 'Job type',
  helperText: '(visible only to the lender)',
  inlineHelperText: true,
};

export const NO_RATES_LABEL =
  "You won't be able to attach any finance options to this job due to the selected work type.";

export const MAPPING: Partial<Record<WorkType, string>> = {
  bedrooms_furnitue_custom: 'Bedrooms Furniture (Custom)',
  blinds_awnings_shutters: 'Blinds, Awnings and/or Shutters',
  cctv: 'CCTV',
  central_heating_system: '',
  conservatory: '',
  conservatory_roof: '',
  decking: '',
  door_canopy: '',
  doors: '',
  driveways: '',
  electrical_installation: '',
  ev_charger: '',
  fencing: '',
  fireplaces: '',
  fitted_furniture: '',
  flooring_excluding_carpets: '',
  garage_doors: '',
  garden_buildings: '',
  gates: '',
  glass_splash_backs: '',
  granite_worktops: '',
  ground_source_heat_pumps: '',
  home_lifts: '',
  home_study: '',
  hot_tubs_and_spas: '',
  insulation_roof_foil_wrap_only: '',
  kitchens_fitted: '',
  kitchens_supply_only: '',
  landscaping: '',
  loft_boarding: '',
  loft_conversion: '',
  media_walls: '',
  painting_and_decorating: '',
  patios: '',
  plastering: '',
  porch: '',
  rendering: '',
  resin_driveways: '',
  roofing: '',
  roofline: '',
  security_lighting: '',
  solar_pv_panels: '',
  stair_lifts: '',
  staircases: '',
  tiling: '',
  under_stair_storage: '',
  underfloor_heating: '',
  upvc_doors: '',
  verandas: '',
  wall_insulation_and_cladding: '',
  wardrobes: '',
  water_softeners: '',
  windows: '',
  windows_and_doors: '',
  aesthetics: '',
  appliances: '',
  car_body_shop: '',
  car_detailing_and_alloys: '',
  catering: '',
  computer_builder: '',
  dental: '',
  electronics: '',
  entertainers: '',
  events: '',
  experiences: '',
  flowers: '',
  full_events_package: '',
  furniture: '',
  graphic_designer: '',
  high_end_clothing: '',
  home_removals: '',
  jewllery: '',
  medical_assessments: '',
  memberships: '',
  mobillity_and_mobility_scooters: '',
  network_engineer: '',
  number_plates: '',
  personal_security: '',
  photography: '',
  planners: '',
  'sports equipment': '',
  technology_repair: '',
  'tool sales': '',
  tyres: '',
  vehicle_wrapping: '',
  venues: '',
  website_builder: '',
};

export const EXAMPLE_COPY =
  "You set the type of job here - for this example job, you won't be able to change it. On live jobs, you can select the most relevant";

import type {
  BusinessConfig,
  BusinessSector,
} from '@kanda-libs/ks-frontend-services';
import { BASE_CONFIGS } from './constants';

export const matchConfig = (
  sector: BusinessSector,
  tradeText: string,
): BusinessConfig => {
  const base = BASE_CONFIGS.filter(
    (config) =>
      config.business_sector === sector && config.trade_text === tradeText,
  )[0];
  return {
    ...base,
    show: 'yes',
  };
};

export const matchConfigFromTradeText = (
  tradeText: string,
): BusinessConfig | undefined => {
  const base = BASE_CONFIGS.filter(
    (config) => config.trade_text === tradeText,
  )?.[0];
  if (!base) return undefined;
  return {
    ...base,
    show: 'yes',
  };
};

import { Text } from '@kanda-libs/ks-design-library';
import type { SectorProps } from '../../useWorkTypesForm';
import Sector from './Sector';

export interface WorkTypesSectorProps {
  sectors: SectorProps[];
}

const WorkTypesSector: React.FunctionComponent<WorkTypesSectorProps> =
  function ({ sectors }) {
    return (
      <div className="flex flex-col">
        <Text
          text="What kind of work do you do?"
          className="text-14-22-em mb-3"
        />
        <div className="flex flex-row flex-wrap gap-x-2 gap-y-2">
          {sectors.map((sector) => (
            <Sector key={sector.label} {...sector} />
          ))}
        </div>
      </div>
    );
  };

export default WorkTypesSector;

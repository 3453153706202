import { useIsDesktop } from '@kanda-libs/ks-design-library';
import { useMemo, type CSSProperties } from 'react';
import type { ProgressColor, ProgressStep } from '.';
import {
  CLASS_NAMES,
  COLORS,
  type ProgressHeaderClassNames,
} from './constants';
import { getBarSize } from './helpers';

export interface ProgressHeaderItem {
  text: string;
  className: string;
}

export interface ProgressIndicatorHook {
  style: CSSProperties;
  classNames: ProgressHeaderClassNames;
  headers: ProgressHeaderItem[];
}

export default function useProgressIndicator(
  steps: ProgressStep[],
  color: ProgressColor,
  isLoading: boolean,
): ProgressIndicatorHook {
  const isDesktop = useIsDesktop();

  const size = useMemo(() => {
    if (isLoading) return '0%';
    return getBarSize(steps, isDesktop);
  }, [isLoading, steps, isDesktop]);

  const style = useMemo(() => {
    if (isDesktop)
      return {
        width: size,
      };
    return {
      height: size,
    };
  }, [size, isDesktop]);

  const classNames = useMemo(
    () => ({
      ...CLASS_NAMES,
      progress: [CLASS_NAMES.progress, COLORS[color].background].join(' '),
    }),
    [color],
  );

  const headers = useMemo(() => {
    if (isLoading)
      return steps.map((step) => ({
        text: step.text,
        className: [CLASS_NAMES.base, 'text-neutral-500'].join(' '),
      }));
    return steps.map((step) => ({
      text: step.text,
      className: [
        CLASS_NAMES.base,
        step.active ? COLORS[color].text : 'text-neutral-500',
      ].join(' '),
    }));
  }, [isLoading, steps, color]);

  return {
    style,
    classNames,
    headers,
  };
}

import { SkeletonLoader } from '@kanda-libs/ks-design-library';
import type { InterestInFinance } from 'pages/Leads/helpers';
import { type FunctionComponent } from 'react';
import Highlighter from 'react-highlight-words';
import { type QueryType } from 'store/slices/query';
import InterestInFinanceTag from '../InterestInFinanceTag';
import { HIGHLIGHT_PROPS } from './constants';
import useLeadBudget from './useLeadBudget';

export interface LeadBudgetProps {
  budget: string;
  interestInFinance: InterestInFinance;
  queryType?: QueryType;
  className?: string;
  highlightClassName?: string;
  hideTag?: boolean;
  isLoading?: boolean;
}

const LeadBudget: FunctionComponent<LeadBudgetProps> = function ({
  budget,
  interestInFinance,
  queryType = 'leads',
  className,
  highlightClassName,
  hideTag = false,
  isLoading = false,
}) {
  const { classNames, searchWords } = useLeadBudget(
    queryType,
    className,
    highlightClassName,
  );

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.container}>
        <SkeletonLoader
          isLoading={isLoading}
          height={24}
          width={100}
          afterLoading={
            <Highlighter
              {...HIGHLIGHT_PROPS}
              highlightClassName={classNames.highlight}
              searchWords={searchWords}
              textToHighlight={budget}
              className={classNames.text}
            />
          }
        />
        {!hideTag && (
          <InterestInFinanceTag
            interest={interestInFinance}
            className="mt-2 md:mt-1 -ml-2 mr-auto md:mx-auto"
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
};

export default LeadBudget;

import type { GroupedByAprAndDuration } from 'utils';
import MyAccountFinanceRatesFormRateCard from './RateCard';

export interface MyAccountFinanceRatesFormRatesProps {
  grouped: GroupedByAprAndDuration;
  groupDurations?: boolean;
}

const MyAccountFinanceRatesFormRates: React.FunctionComponent<MyAccountFinanceRatesFormRatesProps> =
  function ({ grouped, groupDurations = false }) {
    const aprs = Object.keys(grouped);
    return (
      <div className="flex flex-col gap-y-4">
        {aprs.map((apr) => (
          <MyAccountFinanceRatesFormRateCard
            grouped={grouped[apr]}
            apr={apr}
            key={apr}
            groupDurations={groupDurations}
          />
        ))}
      </div>
    );
  };

export default MyAccountFinanceRatesFormRates;

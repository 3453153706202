import { Card, NoRates, Options } from '../components';
import { SUBTITLE, TITLE } from './constants';
import useRegulatedRates from './useRegulatedRates';

const FinanceRatesRegulated: React.FunctionComponent = function () {
  const { rates } = useRegulatedRates();
  return (
    <Card title={TITLE} subtitle={SUBTITLE}>
      {rates ? <Options rates={rates} toggleable={false} /> : <NoRates />}
    </Card>
  );
};

export default FinanceRatesRegulated;

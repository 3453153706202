import { type CompanyTypeButtonClassNames } from './useCompanyTypeButtons';

export interface TypeButtonProps {
  classNames: CompanyTypeButtonClassNames;
  title: string;
  subtitle: string;
  onClick: () => void;
}

const TypeButton: React.FunctionComponent<TypeButtonProps> = function ({
  classNames,
  title,
  subtitle,
  onClick,
}) {
  return (
    <button type="button" onClick={onClick} className={classNames.button}>
      <p className="text-16-24-em mb-1">{title}</p>
      <p className="text-14-22">{subtitle}</p>
      <div className={classNames.indicator}>
        <div className="w-2 h-2 m-auto rounded-full bg-neutral-000" />
      </div>
    </button>
  );
};

export default TypeButton;

import { ModalsWrapperContext } from '@kanda-libs/ks-design-library';
import { URLS } from 'config';
import { useMe } from 'hooks';
import { WORK_TYPE_MODAL_ID } from 'pages/CreateJob/components/WorkTypeModal/constants';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import { useCallback, useContext, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { shouldShowSave } from './helpers';

export interface AddWorkTypesButtonHook {
  onClick: () => void;
}

export default function useAddWorkTypesButton(): AddWorkTypesButtonHook {
  const { data: formData } = useCreateJobContextValue();
  const { showModal } = useContext(ModalsWrapperContext);
  const { push } = useHistory();
  const { me } = useMe();

  const enteredData = useWatch();

  const isAdmin = useMemo(() => {
    const role = me?.role;
    if (!role) return false;
    return ['company-admin', 'staff'].includes(role);
  }, [me]);

  const onClick = useCallback(() => {
    const compiledData = {
      ...formData,
      job: {
        ...formData?.job,
        ...enteredData?.job,
      },
    };
    const showSave = shouldShowSave(compiledData);
    if (!showSave && isAdmin) {
      push(URLS.jobSettings);
      return;
    }
    showModal(WORK_TYPE_MODAL_ID);
  }, [isAdmin, push, showModal, formData, enteredData]);

  return {
    onClick,
  };
}

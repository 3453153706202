import { BreakPoints } from '@kanda-libs/ks-design-library';
import { Helmet } from 'components';
import LeadsProvider from './context';
import LeadsDesktop from './Desktop';
import LeadsMobile from './Mobile';

const Leads: React.FunctionComponent = function () {
  return (
    <LeadsProvider>
      <Helmet title="Leads" />
      <BreakPoints mobile={<LeadsMobile />} desktop={<LeadsDesktop />} />
    </LeadsProvider>
  );
};

export default Leads;

import { Tag } from '@kanda-libs/ks-design-library';
import Popover from 'components/Popover';
import type { ReactNode } from 'react';

export interface PendingPopoverProps {
  children: ReactNode;
  popoverId: string;
}

const PendingPopover: React.FunctionComponent<PendingPopoverProps> = function ({
  children,
  popoverId,
}) {
  return (
    <div className="flex flex-row">
      <Tag
        variant="solid"
        color="violet"
        label="pending"
        size={21}
        className="my-auto"
      />
      <div className="my-auto">
        <Popover popoverId={popoverId}>{children}</Popover>
      </div>
    </div>
  );
};

export default PendingPopover;

import type { Address } from '@kanda-libs/ks-frontend-services';

export const numNotInLine1 = (address: Address): boolean => {
  const { building_number: number, line_1: line1 } = address;
  if (!number) return false;
  return !line1.includes(number as string);
};

export const nameNotInLine1 = (address: Address): boolean => {
  const { building_name: name, line_1: line1 } = address;
  if (!name) return false;
  return !line1.includes(name as string);
};

export const formatAddress = (
  address: Address | undefined,
  fallback = 'No Address',
): string => {
  if (!address) return fallback;
  return [
    ...(numNotInLine1(address) ? [address?.building_number] : []),
    ...(nameNotInLine1(address) ? [address?.building_name] : []),
    address.line_1,
    address.line_2,
    address.city,
    address.postcode,
  ]
    .filter(Boolean)
    .join(', ');
};

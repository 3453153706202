import {
  ModalsWrapperContext,
  useFormContext,
} from '@kanda-libs/ks-component-ts';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { CLOSE_MODAL_ID } from '../../../CreateJob-constants';

import { URLS } from '../../../../../config';
import useQuoteErrorScroll from '../Pages/Form/useQuoteErrorScroll';

const ContainerComponent = ({ children }) => {
  const { push } = useHistory();
  const { showModal } = useContext(ModalsWrapperContext);
  const { step, steps } = useCreateJobContextValue();
  const formContext = useFormContext();

  const isDirty = useMemo(() => {
    if (formContext == null) return true;
    const dirty = formContext?.formState?.isDirty;
    if (dirty == null) return true;
    return dirty;
  }, [formContext]);

  useQuoteErrorScroll();

  const onClick = useCallback(() => {
    // If form hasn't been changed, or user is on success step, return home
    if (!isDirty || step === steps.slice(-1)[0]) {
      push(URLS.jobs);
      return;
    }
    showModal(CLOSE_MODAL_ID);
  }, [push, showModal, step, steps, isDirty]);

  useEffect(() => {
    const location = window?.location;
    if (!location) return;
    const url = new URL(location as unknown as string);
    url.searchParams.set('step', step as string);
    window.history.pushState({}, '', url);
  }, [step]);

  return children({
    onClick,
  });
};

export default ContainerComponent;

import type { EnquiryState } from '@kanda-libs/ks-frontend-services';

export const getLabel = (status: EnquiryState | undefined): string => {
  switch (status) {
    case 'viewed':
      return 'No Contact Provided';
    case 'contact_only':
      return 'No Contact Requested';
    case 'contact_agreed':
    case 'preferences_set':
      return 'Contact agreed';
    case 'ask_for_quote':
    case 'submitted':
      return 'Quote Requested';
    case 'quoted':
      return 'Quoted';
    case 'converted_to_job':
      return 'Converted';
    case 'not_interested':
      return 'Not Interested';
    case 'unreachable':
      return 'Unreachable';
    case 'other_reason':
      return 'Ended For Other Reason';
    default:
      return 'No Status';
  }
};

export const CLASS_NAMES = {
  container:
    'py-5 px-4 flex flex-col flex-1 border-b last:border-0 border-neutral-200 md:py-3',
  wrapper: 'flex flex-row flex-1',
  secondLine: {
    container: 'mb-3 pb-3 border-b border-neutral-300',
    text: 'text-14-22-em text-neutral-600',
  },
  info: 'flex flex-col flex-1 mr-3',
  header: 'flex flex-row flex-wrap items-center',
  headerItem: 'text-style-g-em text-neutral-600 whitespace-nowrap',
  cost: 'text-style-g-em text-turquoise-300',
  costPrice: {
    wrapperClassName: 'ml-1',
    poundsClassName: 'text-style-g-em',
    centsClassName: 'text-style-g-em',
  },
  bullet: 'inline-block w-0.75 h-0.75 bg-neutral-500 mx-1.5 rounded-full',
  description: 'flex flex-row flex-1 items-center',
  customerSees: 'text-style-h-em text-neutral-500',
  from: 'ml-1 text-style-h-em text-lavender-200',
  fromPrice: {
    wrapperClassName: 'ml-1',
    color: 'lavender-200',
    poundsClassName: 'text-style-h-em',
    centsClassName: 'text-style-h-em',
  },
};

export const CUSTOMER_SEES_LABEL = 'Customer sees:';

export const COST_LABEL = 'Cost:';

export const FROM_LABEL = 'from';

export const MONTHS_LABEL = '/ mo';

export const SKELETONS = {
  interest: {
    width: 20,
  },
  duration: {
    width: 70,
  },
  cost: {
    width: 90,
  },
  customerSees: {
    width: 90,
  },
  from: {
    width: 30,
  },
};

export const SHOW_YEARS_LABEL_MINIMUM_MONTHS = 12;

import { useMemo } from 'react';

export interface SearchWordsHook {
  searchWords: string[];
}

export default function useSearchWords(query?: string): SearchWordsHook {
  const searchWords = useMemo(() => query?.split(' ') || [], [query]);

  return {
    searchWords,
  };
}

import { BreakPoints } from '@kanda-libs/ks-design-library';
import type { ReactNode } from 'react';
import AddWorkTypeLayoutDesktop from './Desktop';
import AddWorkTypeLayoutMobile from './Mobile';

export interface AddWorkTypeLayoutProps {
  children: ReactNode;
}

const AddWorkTypeLayout: React.FunctionComponent<AddWorkTypeLayoutProps> =
  function ({ children }) {
    return (
      <BreakPoints
        mobile={<AddWorkTypeLayoutMobile>{children}</AddWorkTypeLayoutMobile>}
        desktop={
          <AddWorkTypeLayoutDesktop>{children}</AddWorkTypeLayoutDesktop>
        }
      />
    );
  };

export default AddWorkTypeLayout;

import LeadConvertToJob from 'pages/Lead/components/LeadConvertToJob';
import LeadUnarchive from 'pages/Lead/components/LeadUnarchive';
import { useLeadContext } from 'pages/Lead/context';

const LeadMobileLayoutFooter: React.FunctionComponent = function () {
  const { archived } = useLeadContext();
  return (
    <div className="flex flex-row w-full justify-end">
      {archived ? <LeadUnarchive /> : <LeadConvertToJob />}
    </div>
  );
};

export default LeadMobileLayoutFooter;

import {
  ModalsWrapperContext,
  useFormContext,
  useWatch,
} from '@kanda-libs/ks-component-ts';
import get from 'lodash.get';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { AI_DOC_MODAL_ID } from './Document-constants';
import { getError } from './Document-functions';

const ContainerComponent = ({ children, arrayName, index }) => {
  const { data } = useCreateJobContextValue();
  const { showModal } = useContext(ModalsWrapperContext);
  const isLoading = useSelector(selectors.document.getIsLoading);

  const [inputFile, setInputFile] = useState(null);

  const {
    formState: { errors },
    setValue,
  } = useFormContext();

  const contentName = useMemo(
    () => `${arrayName}.${index}.quote_document`,
    [arrayName, index],
  );
  const titleName = useMemo(
    () => `${arrayName}.${index}.title`,
    [arrayName, index],
  );

  const doc = useWatch({
    name: contentName,
  });

  const errorName = `${arrayName}.${index}.quote_document`;
  const errorObject = get(errors, errorName);
  const error = getError(errorObject);

  const initialFile = data?.droppedFile;

  const droppedFile = useWatch({ name: 'droppedFile' });

  const showAnalyseModal = useCallback(() => {
    setValue(titleName, 'quote document');
    showModal(AI_DOC_MODAL_ID);
  }, [titleName, setValue, showModal]);

  // Effect sets file from dropper or input file, uses timeout as initialisation
  // container sets value to undefined (DEV_NOTE: find a better fix)
  useEffect(() => {
    setTimeout(() => setInputFile(droppedFile || initialFile), 10);
  }, [initialFile, droppedFile]);

  useEffect(() => {
    if (!doc || !doc.content) return;
    showAnalyseModal();
  }, [doc, showAnalyseModal]);

  return children({
    inputFile,
    error,
    content: doc?.content,
    showAnalyseModal,
    isLoading,
  });
};

ContainerComponent.displayName =
  'Createjob-Quote-Custom-Item-Document-container';

export default ContainerComponent;

import type { ProgressStep } from 'components/ProgressIndicator';
import { useLeadContext } from 'pages/Lead/context';
import { useMemo } from 'react';
import { STEPS, VALID_STEPS } from './constants';

export interface LeadProgressBarHook {
  showBar: boolean;
  steps: ProgressStep[];
  isLoading: boolean;
}

export default function useLeadProgressBar(): LeadProgressBarHook {
  const { lead, archived, isLoading } = useLeadContext();

  const status = useMemo(() => {
    if (!lead) return 'viewed';
    return lead.current_state;
  }, [lead]);

  const showBar = useMemo(() => {
    if (isLoading) return true;
    if (!lead || archived) return false;
    const currentStatus = lead.current_state;
    return VALID_STEPS.includes(currentStatus);
  }, [isLoading, lead, archived]);

  const steps: ProgressStep[] = useMemo(
    () =>
      STEPS.map(({ text, states }) => ({
        text,
        active: states.includes(status),
      })),
    [status],
  );

  return {
    showBar,
    steps,
    isLoading,
  };
}

import { useAtlasChatWidget } from '@kanda-libs/ks-design-library';
import { useMyAccountFinanceRatesContext } from 'pages/MyAccount/FinanceRates/context';
import { useCallback, useMemo } from 'react';
import { OFFSET } from './constants';
import { formatDate } from './helpers';

export interface LockedMessageHook {
  date: string;
  onClick: () => void;
}

export default function useLockedMessage(): LockedMessageHook {
  const { enrolment } = useMyAccountFinanceRatesContext();

  const { openChat } = useAtlasChatWidget();

  const date = useMemo(() => {
    const updated = enrolment?.metadata?.updated_at;
    if (!updated) return '--';
    const updatedDate = new Date(updated);
    const until = new Date(updatedDate.getTime() + OFFSET);
    return formatDate(until);
  }, [enrolment]);

  const onClick = useCallback(() => openChat(), [openChat]);

  return {
    date,
    onClick,
  };
}

import HighlightWords from 'components/HighlightWords';
import InterestInFinanceTag from 'pages/Leads/components/InterestInFinanceTag';
import LeadActivity from 'pages/Leads/components/LeadActivity';
import LeadBudget from 'pages/Leads/components/LeadBudget';
import LeadStatusTag from 'pages/Leads/components/LeadStatusTag';
import type { FormattedLead } from 'pages/Leads/helpers';
import useLeadCard from './useLeadCard';

export interface LeadCardProps {
  lead: FormattedLead;
  isLoading?: boolean;
}

const LeadCard: React.FunctionComponent<LeadCardProps> = function ({
  lead,
  isLoading = false,
}) {
  const { Wrapper, wrapperProps, classNames } = useLeadCard(lead, isLoading);
  return (
    <Wrapper {...wrapperProps} className={classNames.wrapper}>
      <div className={classNames.card}>
        <div className="flex flex-row justify-between gap-x-3 w-full">
          <HighlightWords
            queryType="leads"
            text={[lead.firstName, lead.lastName].join(' ')}
            isLoading={isLoading}
            skeletonProps={{
              width: 100,
            }}
            className="text-16-24-em"
          />
          <LeadActivity
            updatedAt={lead.updatedAt}
            currentState={lead.currentState}
            textClassName="text-12-18"
            isLoading={isLoading}
          />
        </div>
        <div className="flex flex-row w-full justify-between gap-x-2">
          <div className="flex flex-col">
            <HighlightWords
              queryType="leads"
              text={lead.workType}
              className="text-14-22 mb-3"
              isLoading={isLoading}
              skeletonProps={{
                width: 100,
              }}
            />
            <LeadBudget
              budget={lead.budget}
              interestInFinance={lead.interestInFinance}
              hideTag
              isLoading={isLoading}
            />
          </div>
          <div className="flex flex-col">
            <LeadStatusTag status={lead.currentState} isLoading={isLoading} />
            <InterestInFinanceTag
              interest={lead.interestInFinance}
              className=" mt-2"
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default LeadCard;

import { WorkType } from '@kanda-libs/ks-frontend-services';
import { pipe } from 'fp-ts/lib/function';
import { capitalise } from './String-functions';

export const CHANGE_TYPE: Partial<Record<WorkType, WorkType>> = {
  roofing_excl_flat_roofs: 'roofing',
  door_internal: 'doors',
};

export const REPLACE_WORK_TYPE_NAME: Partial<Record<WorkType, string>> = {
  bedrooms_furnitue_custom: 'Bedrooms Furniture (Custom)',
  blinds_awnings_shutters: 'Blinds, Awnings and/or Shutters',
  cctv: 'CCTV',
  ev_charger: 'EV Charger',
  flooring_excluding_carpets: 'Flooring (Exlcuding Carpets)',
  insulation_roof_foil_wrap_only: 'Insulation (Roof Foil Wrap Only)',
  kitchens_fitted: 'Kitchens (Fitted)',
  kitchens_supply_only: 'Kitchens (Supply Only)',
  roofing_excl_flat_roofs: 'Roofing (Exclusing Flat Roofs)',
};

export const changeRenamedWorkTypes = (workTypes: WorkType[]): WorkType[] =>
  workTypes
    .map((type) =>
      Object.keys(CHANGE_TYPE).includes(type)
        ? CHANGE_TYPE[type] || undefined
        : type,
    )
    .filter((type) => type !== undefined);

export const filterDeprecated = (workTypes: WorkType[]): WorkType[] =>
  workTypes.filter((type) => !['other', 'carpentry'].includes(type));

export const stripDeprecatedWorkTypes = (workTypes: WorkType[]): WorkType[] =>
  pipe(workTypes, filterDeprecated);

export interface WorkTypeOption {
  name: string;
  value: string;
}

export const formatWorkType = (type: WorkType): string => {
  if (Object.keys(REPLACE_WORK_TYPE_NAME).includes(type))
    return REPLACE_WORK_TYPE_NAME[type] as string;
  return type.split('_').map(capitalise).join(' ');
};

export const mapWorkTypesToOptions = (
  workTypes: WorkType[],
): WorkTypeOption[] =>
  workTypes.map((type) => {
    if (Object.keys(REPLACE_WORK_TYPE_NAME).includes(type))
      return {
        name: REPLACE_WORK_TYPE_NAME[type] as string,
        value: type,
      };
    return {
      name: type.split('_').map(capitalise).join(' '),
      value: type,
    };
  });

export const formatWorkTypesToOptions = (
  workTypes: WorkType[],
): WorkTypeOption[] =>
  pipe(
    workTypes,
    stripDeprecatedWorkTypes,
    changeRenamedWorkTypes,
    mapWorkTypesToOptions,
  );

export const formatWorkTypeToString = (workType: WorkType): string =>
  pipe(workType, formatWorkType);

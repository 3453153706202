import type { Document, Job, JobItem } from '@kanda-libs/ks-frontend-services';
import type { Dictionary } from '@reduxjs/toolkit';
import useCurrentJob from 'pages/JobDetails/JobDetails-useCurrentJob';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { checkJobIsCustom } from 'utils/Job-functions';

export interface FetchDraftHook {
  draft: Job | null;
  hasDraft: boolean;
  isCustom: boolean;
  itemDocs: Document[] | undefined;
  isValidating: boolean;
}

const appendJobItems = (job: Job, documents: Dictionary<Document>): Job => {
  const custom = checkJobIsCustom(job);
  if (!custom) return job;
  const ids = job.job_items
    .map((item) => item.quote_document?.id)
    .filter((val) => val !== undefined);
  if (ids.length === 0) return job;
  const filteredDocs = ids
    .map((docId: string) => documents?.[docId])
    .filter((val) => val !== undefined);
  if (filteredDocs.length === 0) return job;
  return {
    ...job,
    job_items: job.job_items.map((item: JobItem) => {
      const docId = item.quote_document?.id;
      if (!docId) return item;
      const doc = filteredDocs?.filter((d) => d?.id === docId)?.[0];
      return {
        ...item,
        ...(doc && {
          quote_document: doc,
        }),
      };
    }),
  };
};

/**
 * Handles job submit
 */
const useFetchDraft = (): FetchDraftHook => {
  const { id } = useSelector(selectors.getPathKey);
  const { refetchJobFromId } = useCurrentJob();

  const isLoading = useSelector(selectors.getIsLoading);
  const jobs = useSelector(selectors.job.getEntitiesAsArray);
  const job = useSelector(selectors.job.getEntity);
  const documents = useSelector(selectors.document.getEntities);

  // Fetch stored job from cache
  const storedJob = useMemo(() => {
    if (!id || !jobs) return null;
    const filtered = jobs.filter((current) => current?.id === id);
    if (filtered.length !== 1) return null;
    return filtered[0];
  }, [id, jobs]);

  // Ensure fetched job is null until revalidated
  const fetchedJob = useMemo(() => {
    if (!id || !job || isLoading) return null;
    if (job?.id !== id) {
      refetchJobFromId();
      return null;
    }
    return job;
  }, [id, job, isLoading, refetchJobFromId]);

  const isCustom = useMemo(() => {
    if (!id || !job) return false;
    return checkJobIsCustom(job);
  }, [id, job]);

  const draft = useMemo(() => {
    if (!storedJob && !fetchedJob) return null;
    if (!fetchedJob) return null;
    const custom = checkJobIsCustom(fetchedJob);
    if (!custom) return fetchedJob;
    if (!documents) return null;
    return appendJobItems(fetchedJob, documents);
  }, [fetchedJob, documents, storedJob]);

  const itemDocs = useMemo(() => {
    if (!isCustom || !job) return undefined;
    const ids = job.job_items
      .map((item) => item.quote_document?.id)
      .filter((val) => val !== undefined);
    if (ids.length === 0) return undefined;
    const filteredDocs = ids
      .map((docId: string) => documents?.[docId])
      .filter((val) => val !== undefined);
    if (filteredDocs.length === 0) return undefined;
    return filteredDocs;
  }, [documents, job, isCustom]);

  return {
    draft,
    hasDraft: !!id,
    isCustom,
    itemDocs,
    isValidating: (id && !draft) || false,
  };
};

export default useFetchDraft;

import type { FieldValues } from 'pages/Setup/Account/useSetupAccountForm';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

export interface BusinessFormHook {
  showLimitedFields: boolean;
  showSoleFields: boolean;
  showWorkTypes: boolean;
}

export default function useBusinessForm(): BusinessFormHook {
  const [companyType] = useWatch<FieldValues, ['company.company_type']>({
    name: ['company.company_type'],
  });

  const showLimitedFields = useMemo(
    () => companyType === 'limited_company',
    [companyType],
  );
  const showSoleFields = useMemo(
    () => companyType === 'sole_trader',
    [companyType],
  );

  const showWorkTypes = useMemo(() => Boolean(companyType), [companyType]);

  return {
    showLimitedFields,
    showSoleFields,
    showWorkTypes,
  };
}

import { useForm, type UseFormReturn } from '@kanda-libs/ks-component-ts';
import {
  useToast,
  type StringIndexedObject,
} from '@kanda-libs/ks-design-library';
import {
  actions,
  type Company,
  type CompanyInfo,
  type WorkType,
} from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { useCurrentCompany } from 'hooks';
import useApiError from 'hooks/useApiError';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';

export interface WorkTypesFormValues {
  work_types: WorkType[] | undefined;
  work_types__selected: string | undefined;
}

export interface WorkTypesFormHook {
  form: UseFormReturn<WorkTypesFormValues>;
  onSubmit: (formValues: StringIndexedObject<WorkTypesFormValues>) => void;
  isLoading: boolean;
  isSubmitting: boolean;
}

export default function useAddWorkTypesForm(): WorkTypesFormHook {
  const isSubmitting = useSelector(selectors.company.getIsSubmitting);

  const dispatch = useAppDispatch();
  const { company } = useCurrentCompany();
  const onError = useApiError(
    'Error updating company info at this time - please try again later',
  );
  const { showSuccess } = useToast();

  const defaultValues = useMemo(() => {
    if (!company)
      return {
        work_types: undefined,
        work_types__selected: undefined,
      };
    const current = company?.company_info?.work_types;
    if (!current)
      return {
        work_types: undefined,
        work_types__selected: undefined,
      };
    return {
      work_types: current,
      work_types__selected: current.join(';'),
    };
  }, [company]);

  const form = useForm<WorkTypesFormValues>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
  });

  const onSubmit = useCallback(
    (formValues: StringIndexedObject<WorkTypesFormValues>) => {
      const cid = company?.cid;
      if (!cid) return;
      const fv = formValues as unknown as WorkTypesFormValues;
      const body: Company = {
        ...company,
        company_info: {
          ...(company.company_info as CompanyInfo),
          work_types: fv.work_types,
        },
      };
      dispatch(
        actions.putCompany({
          body,
          params: {
            id: cid,
          },
          onError,
          onSuccess: () => {
            showSuccess('Work types updated!');
          },
        }),
      );
    },
    [company, dispatch, onError, showSuccess],
  );

  const workTypesRef = useRef<boolean>(false);
  useEffect(() => {
    if (!company || workTypesRef.current) return;
    form.reset(defaultValues);
    workTypesRef.current = true;
  }, [defaultValues, form, company, workTypesRef]);

  return {
    form,
    onSubmit,
    isLoading: !company,
    isSubmitting,
  };
}

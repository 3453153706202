import { Form } from '@kanda-libs/ks-component-ts';

import NextButton from '../../../../components/NextButton';
import Container from './PaymentAndFinanceOptions-container';

import { Page } from '../..';
import { PaymentAndFinanceOptions } from '../../../../components';

const ViewComponent = () => (
  <Container>
    {({ form, onSubmit, prevButtonProps }) => (
      <Form
        id="create-job-finance-payment-mobile"
        form={form}
        onSubmit={onSubmit}
      >
        <Page
          nextButton={<NextButton />}
          nextSubmit
          prevButtonProps={prevButtonProps}
        >
          <PaymentAndFinanceOptions.Info />
          <PaymentAndFinanceOptions.FinanceRates />
          <PaymentAndFinanceOptions.PaymentMethods />
          <PaymentAndFinanceOptions.LeadFinanceOption />
        </Page>
      </Form>
    )}
  </Container>
);

ViewComponent.displayName = 'CreateJob-PaymentAndFinanceOptions-view';

export default ViewComponent;

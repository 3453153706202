import { Button } from '@kanda-libs/ks-design-library';
import { Helmet } from 'components';
import { URLS } from 'config';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout } from '../components';
import { Rates, SubHeaderPoints, VideoEmbed } from './components';

const SetupRates: React.FunctionComponent = function () {
  const { push } = useHistory();
  useEffect(() => push(URLS.setupInfo), [push]);
  return (
    <Layout
      mobileFooter={
        <Button.Text
          id="setup-rates-next"
          className="w-full"
          label="Apply Now and Pay"
          icon="arrow-right"
          variant="gradient"
          onClick={() => push(URLS.setupSubscription)}
        />
      }
    >
      <Helmet title="Setup Rates" />
      <div className="flex flex-col w-full max-w-120 md:max-w-full mx-auto">
        <p className="text-24-28-em md:text-40-48-em mb-6 md:mb-16">
          Preview the rates you can offer
        </p>
        <SubHeaderPoints />
        <Rates />
        <VideoEmbed />
      </div>
    </Layout>
  );
};

export default SetupRates;

import type { LeadBudgetClassNames } from './useLeadBudget';

export const HIGHLIGHT_PROPS = {
  autoEscape: true,
};

export const CLASS_NAMES: LeadBudgetClassNames = {
  highlight: 'text-green-300 bg-transparent',
  wrapper: 'flex w-full md:w-[180px] md:min-w-[180px]',
  container: 'flex flex-col mr-auto',
  text: 'text-16-20-em text-neutral-900 text-left',
};

import { Button } from '@kanda-libs/ks-design-library';
import useSetupAccountSubmitButton from './useSetupAccountSubmitButton';

export interface SetupAccountSubmitButtonProps {
  isSubmitting: boolean;
}

const SetupAccountSubmitButton: React.FunctionComponent<SetupAccountSubmitButtonProps> =
  function ({ isSubmitting }) {
    const { disabled, show } = useSetupAccountSubmitButton();
    if (!show) return null;
    return (
      <Button.Text
        id="setup-account-submit"
        submit
        className="w-full mt-12"
        label="Next"
        icon="arrow-right"
        variant="gradient"
        disabled={isSubmitting || disabled}
      />
    );
  };

export default SetupAccountSubmitButton;

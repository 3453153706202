import {
  DesktopLayoutDefault,
  DesktopLayoutDefaultContent,
  DesktopLayoutDefaultContentHeader,
} from '@kanda-libs/ks-design-library';
import ContactConfigWrapper from 'components/ContactConfigWrapper';
import { Content, Header } from './components';

const LeadDesktop: React.FunctionComponent = function () {
  return (
    <ContactConfigWrapper>
      <DesktopLayoutDefault>
        <DesktopLayoutDefaultContentHeader>
          <Header />
        </DesktopLayoutDefaultContentHeader>
        <DesktopLayoutDefaultContent>
          <Content />
        </DesktopLayoutDefaultContent>
      </DesktopLayoutDefault>
    </ContactConfigWrapper>
  );
};

export default LeadDesktop;

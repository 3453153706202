import { useDispatch } from 'react-redux';
import type { AppDispatch } from 'store';
import { reloadInitialData } from 'store/slices/app';

export type ReloadInitialDataHook = () => void;

export default function useReloadInitialData(): ReloadInitialDataHook {
  const dispatch = useDispatch<AppDispatch>();

  return () => {
    dispatch(reloadInitialData());
  };
}

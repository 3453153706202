import type { Tag } from '@kanda-libs/ks-frontend-services';
import MyAccountAlwaysOnLinkNewTagForm from '../NewTagForm';
import MyAccountAlwaysOnLinkTag from '../Tag';

export interface TagsWrapperProps {
  tags: Tag[];
}

const TagsWrapper: React.FunctionComponent<TagsWrapperProps> = function ({
  tags,
}) {
  return (
    <div className="flex flex-col gap-y-4">
      {tags.map((tag, index) => (
        <MyAccountAlwaysOnLinkTag tag={tag} key={tag.id} index={index + 1} />
      ))}
      <MyAccountAlwaysOnLinkNewTagForm />
    </div>
  );
};

export default TagsWrapper;

import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import FinanceOptionHandle from './Handle';
import useLabelHandle from './useLabelHandle';

export interface LabelHandleProps {
  rates: FinanceRate[];
  toggleable: boolean;
}

const LabelHandle: React.FunctionComponent<LabelHandleProps> = function ({
  rates,
  toggleable,
}) {
  const { showHandle, rateName } = useLabelHandle(rates);
  return (
    <div className="flex flex-row items-center">
      {showHandle && (
        <FinanceOptionHandle rates={rates} toggleable={toggleable} />
      )}
      <p className="text-12-18-em text-neutral-700">{rateName}</p>
    </div>
  );
};

export default LabelHandle;

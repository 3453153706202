import { Loader, PopoverMenu } from '@kanda-libs/ks-design-library';
import useLeadActionsMenu from './useLeadActionsMenu';

export interface LeadActionsMenuProps {
  callback?: () => void;
  handleClose: () => void;
}

const LeadActionsMenu: React.FunctionComponent<LeadActionsMenuProps> =
  function ({ handleClose, callback }) {
    const { items, size, isSubmitting } = useLeadActionsMenu(callback);
    return (
      <>
        <PopoverMenu
          className="-mx-6 md:m-0 md:w-64"
          items={items}
          onClose={handleClose}
          size={size}
        />
        <Loader isLoading={isSubmitting} />
      </>
    );
  };

export default LeadActionsMenu;

import {
  ModalContainer,
  ModalLayoutSlideUp,
} from '@kanda-libs/ks-design-library';
import LeadActionsMenu from 'pages/Lead/components/LeadActionsMenu';
import type { MutableRefObject } from 'react';
import { LEAD_SLIDE_UP_MODAL_ID } from './constants';

const LeadSlideUpModal: React.FunctionComponent = function ({}) {
  return (
    <ModalContainer id={LEAD_SLIDE_UP_MODAL_ID}>
      {({ id, handleClose, ref }) => (
        <ModalLayoutSlideUp
          id={id}
          onClose={handleClose}
          ref={ref as MutableRefObject<HTMLDivElement>}
          variant="MARKETING"
        >
          <LeadActionsMenu handleClose={handleClose} callback={handleClose} />
        </ModalLayoutSlideUp>
      )}
    </ModalContainer>
  );
};

export default LeadSlideUpModal;

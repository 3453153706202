import { Button } from '@kanda-libs/ks-design-library';
import useFooterProps from 'pages/CreateJob/Desktop/components/Pages/SendConfirmation/components/Footer/useFooterProps';
import { Link } from 'react-router-dom';

import { BUTTON_PROPS, EXAMPLE_FINISH_BUTTON_PROPS } from './Footer-constants';

import { URLS } from '../../../../../../../../config';

const ViewComponent = () => {
  const { isExampleJob } = useFooterProps();
  return (
    <div className="flex flex-col">
      <Link to={URLS.jobs} className="w-full">
        <Button.Text
          {...(isExampleJob ? EXAMPLE_FINISH_BUTTON_PROPS : BUTTON_PROPS)}
        />
      </Link>
    </div>
  );
};

ViewComponent.displayName = 'CreateJob-Mobile-SendConfirmation-Footer-view';

export default ViewComponent;

import {
  SkeletonLoader,
  type SkeletonLoaderProps,
} from '@kanda-libs/ks-design-library';
import Highlighter from 'react-highlight-words';
import type { QueryType } from 'store/slices/query';
import { HIGHLIGHT_PROPS } from './constants';
import useHighlightWords from './useHighlightWords';

export interface HighlightWordsProps {
  queryType: QueryType;
  text: string;
  skeletonProps?: SkeletonLoaderProps;
  className?: string;
  isLoading?: boolean;
}

const HighlightWords: React.FunctionComponent<HighlightWordsProps> = function ({
  queryType,
  text,
  skeletonProps,
  className = 'text-16-20-em',
  isLoading = false,
}) {
  const { searchWords } = useHighlightWords(queryType);
  return (
    <SkeletonLoader
      {...skeletonProps}
      isLoading={isLoading}
      afterLoading={
        <Highlighter
          {...HIGHLIGHT_PROPS}
          searchWords={searchWords}
          textToHighlight={text || ''}
          className={className}
        />
      }
    />
  );
};

export default HighlightWords;

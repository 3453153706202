import type { Job } from '@kanda-libs/ks-frontend-services';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';

export type MostRecentJobHook = Job | undefined;

export default function useMostRecentJob(): MostRecentJobHook {
  const jobs = useSelector(selectors.job.getEntitiesAsArray);

  const sorted = useMemo(
    () =>
      jobs.sort(
        (j1, j2) =>
          new Date(j2?.metadata?.created_at || 0).getTime() -
          new Date(j1?.metadata?.created_at || 0).getTime(),
      ),
    [jobs],
  );

  return sorted?.[0];
}

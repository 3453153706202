import { Icon, Text } from '@kanda-libs/ks-design-library';
import { isBlackFriday } from 'utils';
import { useSubscriptionContext } from '../../context';

const SubHeaderPoints: React.FunctionComponent = function () {
  const { isLoading } = useSubscriptionContext();
  const blackFriday = isBlackFriday();
  return (
    <div className="flex flex-col md:flex-row flex-wrap gap-3 md:gap-8 w-full">
      <div className="flex flex-row">
        <Icon icon="shield-alt" stroke="lavender-200" isLoading={isLoading} />
        <Text
          text="Apply to offer finance"
          className="text-16-24-em text-lavender-200 my-auto ml-3"
          isLoading={isLoading}
          skeletonProps={{
            width: 100,
          }}
        />
      </div>
      <div className="flex flex-row">
        <Icon icon="coin-pound" stroke="lavender-200" isLoading={isLoading} />
        <Text
          text="Volume based pricing available"
          className="text-16-24-em text-lavender-200 my-auto ml-3"
          isLoading={isLoading}
          skeletonProps={{
            width: 100,
          }}
        />
      </div>
      {blackFriday && (
        <div className="flex flex-row">
          <Icon
            icon="alarm"
            stroke="lavender-200"
            isLoading={isLoading}
            className="mb-auto"
          />
          <Text
            text="Black friday savings now applied"
            className="text-16-24-em text-lavender-200 my-auto ml-3"
            isLoading={isLoading}
            skeletonProps={{
              width: 100,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SubHeaderPoints;

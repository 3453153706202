import { useToast } from '@kanda-libs/ks-design-library';
import { actions, Job } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { useCurrentCompany } from 'hooks';
import useApiError from 'hooks/useApiError';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectors } from 'store';
import { DEV_CIDS, URLS } from '../../config';
import { formatJobPayload } from './CreateJob-functions';

export interface SubmitDraftHook {
  handleSubmit: (redirect?: string | null) => void;
  isSubmitting: boolean;
}

/**
 * Handles job submit
 * @returns {Array} handleSubmit, isSubmitting, error
 */
const useSubmitDraft = (): SubmitDraftHook => {
  const dispatch = useAppDispatch();
  const isSubmitting = useSelector(selectors.job.getIsSubmitting);
  const { data: formData, setData } = useCreateJobContextValue();
  const { push } = useHistory();
  const { company } = useCurrentCompany();

  const { showSuccess } = useToast();
  const onError = useApiError('Error saving draft');

  /**
   * Handles submit job
   */
  const handleSubmit = useCallback(
    (redirect: string | null = URLS.home) => {
      if (!company) return;
      const cid = company?.cid;
      const body = formatJobPayload(formData, undefined, company);
      const id = formData?.job?.id;

      const onSuccess = (data: Job) => {
        showSuccess('Draft');
        setData({
          ...formData,
          job: {
            ...data,
          },
        });

        if (redirect) {
          push(redirect);
        }
      };

      if (id) {
        dispatch(
          actions.putJob({
            body,
            useDevHeader: cid ? DEV_CIDS.includes(cid) : false,
            params: { id },
            onError,
            onSuccess,
          }),
        );
      } else {
        dispatch(
          actions.postJob({
            body,
            useDevHeader: cid ? DEV_CIDS.includes(cid) : false,
            onError,
            onSuccess,
          }),
        );
      }
    },
    [formData, onError, showSuccess, setData, push, company, dispatch],
  );

  return {
    handleSubmit,
    isSubmitting,
  };
};

export default useSubmitDraft;

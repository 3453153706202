import { type CSSProperties } from 'react';

export const TRUST_PILOT_LINK = 'https://uk.trustpilot.com/review/getkanda.com';

export const BASE_CLASSNAME = 'w-6 h-6 min-w-6 min-h-6';

export const FULL_CLASSNAME = `${BASE_CLASSNAME} bg-green-600`;

export const EMPTY_CLASSNAME = `${BASE_CLASSNAME} bg-neutral-300`;

export const BASE_STYLE: CSSProperties = {
  width: '44px',
  height: '44px',
  minWidth: '44px',
  minHeight: '44px',
  display: 'flex',
};

import { Text } from '@kanda-libs/ks-design-library';
import InterestInFinanceTag from 'pages/Leads/components/InterestInFinanceTag';
import LeadStatusTag from 'pages/Leads/components/LeadStatusTag';
import useLeadNameStatus from './useLeadNameStatus';

const LeadNameStatus: React.FunctionComponent = function () {
  const {
    name,
    archived,
    interestInFinance,
    isLoading,
    showStatus,
    currentStatus,
  } = useLeadNameStatus();
  return (
    <div className="flex flex-col">
      <div className="hidden md:flex md:h-12 mb-2">
        <Text
          text={name}
          isLoading={isLoading}
          className="text-28-32-em md:text-32-40-em text-neutral-900 my-auto"
          skeletonProps={{
            width: 200,
          }}
        />
      </div>
      <div className="flex flex-row gap-x-6">
        {showStatus && currentStatus ? (
          <LeadStatusTag
            status={currentStatus}
            size={28}
            archived={archived}
            isLoading={isLoading}
          />
        ) : (
          <div className="h-7" />
        )}
        {!archived && (
          <InterestInFinanceTag
            interest={interestInFinance}
            isLoading={isLoading}
            size={28}
          />
        )}
      </div>
    </div>
  );
};

export default LeadNameStatus;

import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import { pipe } from 'fp-ts/lib/function';
import { useMyAccountFinanceRatesContext } from 'pages/MyAccount/FinanceRates/context';
import { useCallback, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { getCombinedFinanceRatesDurationLabel } from 'utils';
import type { MyAccountFinanceRatesFormValues } from '../../useMyAccountFinanceRatesForm';
import { getButtonClasses } from './helpers';

export interface RowHook {
  label: string;
  enabled: boolean;
  pending: boolean;
  pendingRemoval: boolean;
  onClick: () => void;
  buttonClassName: string;
  isSubmitting: boolean;
  popoverId: string;
}

export default function useRow(rates: FinanceRate[]): RowHook {
  const { enrolment, isSubmitting } = useMyAccountFinanceRatesContext();
  const { setValue } = useFormContext<MyAccountFinanceRatesFormValues>();

  const enabled = useWatch<MyAccountFinanceRatesFormValues, 'enabled'>({
    name: 'enabled',
  });

  const rateKeys = useMemo(
    () => rates.map((rate) => `${rate.provider}-${rate.name}`),
    [rates],
  );

  const rateIsEnabled = useMemo(
    () => rateKeys.some((key) => enabled?.includes(key)),
    [rateKeys, enabled],
  );

  const toAdd = useMemo(
    () => enrolment?.rate_request?.to_add || [],
    [enrolment],
  );

  const toRemove = useMemo(
    () => enrolment?.rate_request?.to_remove || [],
    [enrolment],
  );

  const rateIsPending = useMemo(
    () => rateKeys.some((key) => toAdd?.includes(key)),
    [rateKeys, toAdd],
  );

  const rateRemovalIsPending = useMemo(
    () => rateKeys.some((key) => toRemove?.includes(key)),
    [rateKeys, toRemove],
  );

  const label = useMemo(
    () => pipe(rates, getCombinedFinanceRatesDurationLabel),
    [rates],
  );

  const popoverId = useMemo(() => {
    const apr = String(rates[0].apr || 0);
    return [apr, label.split(' ').join('-')].join('-');
  }, [rates, label]);

  const onClick = useCallback(() => {
    if (rateIsEnabled) {
      const removed = enabled.filter((val) => !rateKeys.includes(val));
      setValue('enabled', removed);
      return;
    }
    const updated = [...enabled, ...rateKeys];
    setValue('enabled', updated);
  }, [enabled, rateKeys, rateIsEnabled, setValue]);

  const buttonClassName = useMemo(
    () =>
      [
        'w-5 h-5 min-w-5 min-h-5 rounded my-auto',
        getButtonClasses(rateIsPending, rateRemovalIsPending, rateIsEnabled),
        '',
      ].join(' '),
    [rateIsEnabled, rateRemovalIsPending, rateIsPending],
  );

  return {
    label,
    enabled: rateIsEnabled,
    pending: rateIsPending,
    pendingRemoval: rateRemovalIsPending,
    onClick,
    buttonClassName,
    isSubmitting,
    popoverId,
  };
}

import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import type {
  NewService,
  RequestFunction,
} from '@kanda-libs/ks-frontend-services';

export interface ModifiedService<
  Value extends StringIndexedObject | undefined | void,
  Args extends StringIndexedObject | undefined = undefined,
> {
  key: string;
  method: RequestFunction<Args, Value>;
}

export const serviceConvert = <
  Entity extends StringIndexedObject | undefined | void,
  Args extends StringIndexedObject | undefined = undefined,
>(
  service: NewService<Entity, Args>,
): ModifiedService<Entity, Args> => ({
  key: service.key,
  method: service.method({}),
});

export const extractErrorMessage = (
  error: StringIndexedObject | string,
): string => {
  if (typeof error === 'string') return error;
  if (!error.message) return 'Unknown error';
  const mapping = error.message
    .split(', ')
    .reduce((errorObj: StringIndexedObject, part: string) => {
      const parts = part.split('=');
      if (parts.length !== 2) return errorObj;
      return {
        ...errorObj,
        [parts[0]]: parts[1],
      };
    }, {});
  if (Object.keys(mapping).length === 0) return 'Unknown error';
  if (!mapping.message) return 'Unknown error';
  return mapping.message;
};

export const convertErrorToObject = (
  error: StringIndexedObject | string,
): StringIndexedObject => {
  if (typeof error === 'string')
    return {
      code: 400,
      message: error,
    };
  if (!error.message)
    return {
      code: 400,
      message: 'Unknown error',
    };
  const mapping = error.message
    .split(', ')
    .reduce((errorObj: StringIndexedObject, part: string) => {
      const parts = part.split('=');
      if (parts.length !== 2) return errorObj;
      return {
        ...errorObj,
        [parts[0]]: parts[1],
      };
    }, {});
  if (Object.keys(mapping).length === 0)
    return {
      code: 400,
      message: 'Unknown error',
    };
  return mapping;
};

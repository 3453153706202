import {
  DesktopLayoutDefault,
  DesktopLayoutDefaultContent,
} from '@kanda-libs/ks-design-library';
import LeadsBanner from '../components/Banner';
import useLeadsDesktop from './useLeadsDesktop';

const LeadsDesktop: React.FunctionComponent = function () {
  const { Content } = useLeadsDesktop();
  return (
    <DesktopLayoutDefault>
      <DesktopLayoutDefaultContent>
        <LeadsBanner />
        {Content && <Content />}
      </DesktopLayoutDefaultContent>
    </DesktopLayoutDefault>
  );
};

export default LeadsDesktop;

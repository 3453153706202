import { Icon } from '@kanda-libs/ks-design-library';
import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import Fee from '../Fee';
import PendingPopover from '../PendingPopover';
import { PENDING, PENDING_REMOVAL } from './constants';
import useRow from './useRow';

export interface RowProps {
  rates: FinanceRate[];
  grouped?: boolean;
}

const Row: React.FunctionComponent<RowProps> = function ({
  rates,
  grouped = false,
}) {
  const {
    label,
    onClick,
    enabled,
    pending,
    pendingRemoval,
    buttonClassName,
    isSubmitting,
    popoverId,
  } = useRow(rates);
  return (
    <>
      <div className="flex flex-row py-2 gap-x-4 justify-between">
        <div className="flex flex-row gap-x-3">
          <button
            type="button"
            onClick={onClick}
            disabled={pending || pendingRemoval || isSubmitting}
            className={buttonClassName}
          >
            {enabled && (
              <Icon
                icon="check"
                size={12}
                className="m-auto"
                stroke="neutral-000"
              />
            )}
          </button>
          <h4 className="text-14-22-em text-neutral-700">{label}</h4>
          {pending && (
            <PendingPopover popoverId={popoverId}>
              <p className="text-14-22-em text-neutral-700 mb-1">
                Pending Activation
              </p>
              <p className="text-14-22">{PENDING}</p>
            </PendingPopover>
          )}
          {pendingRemoval && (
            <PendingPopover popoverId={popoverId}>
              <p className="text-14-22-em text-neutral-700 mb-1">
                Pending Removal
              </p>
              <p className="text-14-22">{PENDING_REMOVAL}</p>
            </PendingPopover>
          )}
        </div>
      </div>
      <Fee rates={rates} grouped={grouped} />
      <div className="border-b last:border-0 border-neutral-100 col-span-2" />
    </>
  );
};

export default Row;

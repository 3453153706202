/**
 * Conifguration related to the app
 */

export type PageKeys =
  | 'home'
  | 'login'
  | 'loginSso'
  | 'logout'
  | 'leads'
  | 'lead'
  | 'leadsArchive'
  | 'jobs'
  | 'job'
  | 'createJob'
  | 'createJobFromDraft'
  | 'createExampleJobInvalid'
  | 'createExampleJob'
  | 'archive'
  | 'staff'
  | 'staffLogin'
  | 'forgotPassword'
  | 'forgotPasswordConfirmation'
  | 'resetPassword'
  | 'resetPasswordConfirmation'
  | 'authLink'
  | 'myAccount'
  | 'alwaysOnLink'
  | 'directorVerification'
  | 'companyInformation'
  | 'financeRates'
  | 'jobSettings'
  | 'accountSettings'
  | 'teamSettings'
  | 'changeEmail'
  | 'changePassword'
  | 'billingSuccess'
  | 'subscription'
  | 'subscriptionReturn'
  | 'subscriptionSuccess'
  | 'notificationPreferences'
  | 'claimAccount'
  | 'claimAccountResent'
  | 'exampleJob'
  | 'leadAccept'
  | 'signUp'
  | 'referrals'
  | 'referralsForm'
  | 'referralSignUp'
  | 'identify'
  | 'identifySuccess'
  | 'setupCompany'
  | 'setupPartnerCompany'
  | 'verifyEmail'
  | 'verifyEmailSuccess'
  | 'veriff'
  | 'onboardingSteps'
  | 'onboarding'
  | 'customCode'
  | 'updateYourWebsiteRedirect'
  | 'updateYourWebsite'
  | 'developerRedirect'
  | 'developer'
  | 'updateYourWebsite'
  | 'advertisingRedirect'
  | 'advertising'
  | 'invite'
  | 'inviteForm'
  | 'inviteSummary'
  | 'setupAccount'
  | 'setupRates'
  | 'setupInfo'
  | 'setupSubscription'
  | 'accountInReview'
  | 'enterpriseRates'
  | 'enterpriseRatesSuccess'
  | 'marketingPackage'
  | 'addWorkTypes';

export const URLS: Record<PageKeys, string> = {
  home: '/',
  login: '/login',
  logout: '/logout',
  leads: '/leads',
  lead: '/lead-details/:id',
  leadsArchive: '/leads/archive',
  jobs: '/jobs',
  job: '/job-details/:id',
  createJob: '/create-job',
  createJobFromDraft: '/create-job/:id',
  createExampleJobInvalid: '/create-example-job',
  createExampleJob: '/create-example-job/:id',
  archive: '/archive',
  staff: '/staff',
  staffLogin: '/staff-login',
  forgotPassword: '/forgot-password',
  forgotPasswordConfirmation: '/forgot-password/confirmation',
  resetPassword: '/reset-password',
  resetPasswordConfirmation: '/reset-password/confirmation',
  authLink: '/auth-link',
  myAccount: '/my-account',
  directorVerification: '/my-account/directory-verification',
  alwaysOnLink: '/my-account/always-on-link',
  companyInformation: '/my-account/company-information',
  financeRates: '/my-account/finance-rates',
  jobSettings: '/my-account/job-settings',
  accountSettings: '/my-account/account-settings',
  teamSettings: '/my-account/team-settings',
  changeEmail: '/my-account/account-settings/change-email',
  changePassword: '/my-account/account-settings/change-password',
  billingSuccess: '/billing/success',
  subscription: '/my-account/subscription',
  notificationPreferences: '/my-account/notification-preferences',
  subscriptionReturn: '/subscription',
  subscriptionSuccess: '/subscription/success',
  claimAccount: '/claim-account',
  claimAccountResent: '/claim-account/resent-email',
  loginSso: '/login/sso',
  exampleJob: '/example-job',
  leadAccept: '/lead-accept/:id',
  signUp: '/signup',
  referrals: '/referrals',
  referralsForm: '/referrals/invite',
  referralSignUp: '/referral-signup/:id',
  identify: '/identify/:id',
  identifySuccess: '/identify/:id/success',
  setupCompany: '/setup-company',
  setupPartnerCompany: '/setup-company/:id',
  verifyEmail: '/verify-email',
  verifyEmailSuccess: '/verify-email/success',
  veriff: '/verify',
  onboardingSteps: '/onboarding/:id',
  onboarding: '/onboarding',
  customCode: '/custom-code',
  updateYourWebsiteRedirect: '/welcome/update-your-website',
  updateYourWebsite: '/welcome/update-your-website/:id',
  developerRedirect: '/welcome/developer',
  developer: '/welcome/developer/:id',
  advertisingRedirect: '/welcome/advertising',
  advertising: '/welcome/advertising/:id',
  invite: '/welcome/invite',
  inviteForm: '/welcome/invite/form',
  inviteSummary: '/welcome/invite/summary',
  setupAccount: '/setup/account',
  setupRates: '/setup/rates',
  setupInfo: '/setup/info',
  setupSubscription: '/setup/subscription',
  accountInReview: '/account-in-review',
  enterpriseRates: '/enterprise-rates',
  enterpriseRatesSuccess: '/enterprise-rates/success',
  marketingPackage: '/marketing-package',
  addWorkTypes: '/add-work-types',
};

export const APP_ENV = process.env.REACT_APP_ENV || 'qa';

export const TERMS_URL = 'https://www.getkanda.com/legal-page';

export const BASE_URL =
  process.env.REACT_APP_BASE_URL || 'https://dashboard-qa.kanda.co.uk/';

// Refresh token timer of 10 minutes
export const REFRESH_TOKEN_TIMER = 1 * 60 * 1000;

export const BANNED_CIDS = [
  '55559b9c-21c0-4772-a6c8-d288b05e1988',
  '5c5a2032-3af5-47b2-904d-2a29b30dd8a3',
];

export const DEV_CIDS = [
  '9674767b-b5df-452f-a0a7-51b78275ca89',
  '4f526f8e-2ef9-44a9-8da5-1f8a5091379a',
];

export const CLASS_NAMES = {
  highlighter: 'text-green-300 bg-transparent',
  container: 'flex flex-col text-14-22 text-neutral-700 text-left',
  text: 'text-16-20-em text-neutral-900',
  tag: 'mr-3',
};

export const HIGHLIGHT_PROPS = {
  highlightClassName: CLASS_NAMES.highlighter,
  autoEscape: true,
};

import { useMemo, type FunctionComponent } from 'react';
import LeadsNoResults from '../components/LeadsNoResults';
import LeadsPlaceholder from '../components/LeadsPlaceholder';
import { useLeadsContext } from '../context';
import LeadsList from './LeadsList';

export interface LeadsMobileHook {
  Content: FunctionComponent;
}

export default function useLeadsMobile(): LeadsMobileHook {
  const { leads, noLeads, isLoading } = useLeadsContext();

  const Content = useMemo(() => {
    if (isLoading) return LeadsList;
    if (noLeads) return LeadsPlaceholder;
    if (leads.length === 0 && !noLeads) return LeadsNoResults;
    return LeadsList;
  }, [isLoading, noLeads, leads]);

  return {
    Content,
  };
}

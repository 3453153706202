import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import { useFinanceOptionsContext } from 'components/FinanceOptions/context';
import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { filterRatesByTotalAndDeposit, getSelectedNameFromRate } from 'utils';

type HandleClassNames = {
  iconWrapper: string;
  handleWrapper: string;
};

export interface FinanceOptionHandleToggleHook {
  id: string;
  isSelected: boolean;
  pending: boolean;
  invalid: boolean;
  onClick: () => void;
  classNames: HandleClassNames;
}

export default function useFinanceOptionHandleToggle(
  rates: FinanceRate[],
): FinanceOptionHandleToggleHook {
  const { total, lenderConfigs, deposit, fieldName, isExample } =
    useFinanceOptionsContext();
  const { setValue } = useFormContext();

  const id = useMemo(() => getSelectedNameFromRate(rates[0]), [rates]);

  const options = useWatch({ name: fieldName });

  const pending = useMemo(() => {
    if (isExample) return false;
    if (!lenderConfigs) return true;
    const rateLenders = rates
      .map((rate) => rate.provider)
      .filter((provider, idx, arr) => arr.indexOf(provider) === idx);
    const configs = rateLenders.map((lender) => lenderConfigs[lender]);
    return configs.some((config) => !config);
  }, [rates, lenderConfigs, isExample]);

  const validRates = useMemo(
    () => filterRatesByTotalAndDeposit(rates, total, deposit),
    [rates, total, deposit],
  );

  const invalid = useMemo(() => validRates.length === 0, [validRates]);

  const isSelected = useMemo(() => options?.includes(id), [options, id]);

  const classNames = useMemo(
    () => ({
      iconWrapper: invalid || pending ? 'flex' : 'hidden',
      handleWrapper: invalid || pending ? 'hidden' : 'flex',
    }),
    [invalid, pending],
  );

  const onClick = useCallback(() => {
    if (invalid) return;
    if (!options) {
      setValue(fieldName, [id]);
      return;
    }
    if (isSelected) {
      const removed = options.filter((rate: string) => rate !== id);
      setValue(fieldName, removed);
      return;
    }
    setValue(fieldName, [...options, id]);
  }, [invalid, isSelected, fieldName, options, id, setValue]);

  useEffect(() => {
    if (!invalid || !isSelected) return;
    const removed = options.filter((rate: string) => rate !== id);
    setValue(fieldName, removed);
  }, [invalid, isSelected, options, id, setValue, fieldName]);

  return {
    id,
    isSelected,
    pending,
    invalid,
    onClick,
    classNames,
  };
}

import { BreakPoints } from '@kanda-libs/ks-design-library';
import { Helmet } from 'components';
import LeadProvider from './context';
import LeadDesktop from './Desktop';
import LeadMobile from './Mobile';

const Lead: React.FunctionComponent = function () {
  return (
    <LeadProvider>
      <Helmet title="Lead Details" />
      <BreakPoints mobile={<LeadMobile />} desktop={<LeadDesktop />} />
    </LeadProvider>
  );
};

export default Lead;

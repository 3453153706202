import type { AuthUser, Company, Tag } from '@kanda-libs/ks-frontend-services';
import { useCurrentCompany, useMe } from 'hooks';
import {
  createContext,
  useContext,
  useMemo,
  type FunctionComponent,
  type ReactNode,
} from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';

export interface MyAccountAlwaysOnLinkContextType {
  tags: Tag[];
  company: Company | undefined;
  me: AuthUser | undefined;
  cid: string | undefined;
  isLoading: boolean;
  isSubmitting: boolean;
}

export const MyAccountAlwaysOnLinkContext =
  createContext<MyAccountAlwaysOnLinkContextType>({
    tags: [],
    company: undefined,
    me: undefined,
    cid: undefined,
    isLoading: false,
    isSubmitting: false,
  });

export const useMyAccountAlwaysOnLinkContext = () =>
  useContext(MyAccountAlwaysOnLinkContext);

export interface MyAccountAlwaysOnLinkProviderProps {
  children: ReactNode;
}

const MyAccountAlwaysOnLinkProvider: FunctionComponent<MyAccountAlwaysOnLinkProviderProps> =
  function ({ children }) {
    const { company, isLoading: companyIsLoading } = useCurrentCompany();
    const { me } = useMe();

    const tags = useSelector(selectors.tag.getEntitiesAsArray);
    const tagsIsLoading = useSelector(selectors.tag.getIsLoading);
    const isSubmitting = useSelector(selectors.tag.getIsSubmitting);

    const isLoading = useMemo(
      () => tagsIsLoading || companyIsLoading,
      [tagsIsLoading, companyIsLoading],
    );

    const cid = useMemo(() => company?.cid, [company]);

    const values = useMemo(
      () => ({
        tags,
        company: company || undefined,
        me,
        cid,
        isLoading,
        isSubmitting,
      }),
      [tags, company, me, cid, isLoading, isSubmitting],
    );

    return (
      <MyAccountAlwaysOnLinkContext.Provider value={values}>
        {children}
      </MyAccountAlwaysOnLinkContext.Provider>
    );
  };

export default MyAccountAlwaysOnLinkProvider;

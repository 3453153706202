import {
  aiReducers,
  type AIExtraState,
} from '@kanda-libs/kai-frontend-services';
import { createSelectors, createStore } from '@kanda-libs/ks-frontend-services';
import type { pages } from 'components/App';
import { APP_ENV, type PageKeys } from 'config';
import filters, { type FilterState } from './slices/filters';
import query, { type QueryState } from './slices/query';
import sorting, { type SortingState } from './slices/sorting';

export interface ExtraState extends AIExtraState {
  query: QueryState;
  filters: FilterState;
  sorting: SortingState;
}

export const store = createStore<PageKeys, ExtraState>({
  ...aiReducers,
  query,
  filters,
  sorting,
});

store.subscribe(() => {
  if (APP_ENV === 'qa') {
    // eslint-disable-next-line no-console
    console.log('store changed', store.getState());
  }
});

export type RootState = ReturnType<typeof store.getState> & ExtraState;
export type AppDispatch = typeof store.dispatch;

export const selectors = createSelectors<RootState, typeof pages>();

import { useMemo } from 'react';
import type { GroupedByKey } from 'utils';
import Row from '../Row';

export interface IndividualRatesProps {
  grouped: GroupedByKey;
  apr: string;
}

const IndividualRates: React.FunctionComponent<IndividualRatesProps> =
  function ({ grouped, apr }) {
    const durations = useMemo(() => Object.keys(grouped), [grouped]);
    return durations.map((duration) => (
      <Row rates={grouped[duration]} key={`${apr}-${duration}`} />
    ));
  };

export default IndividualRates;

import { Button } from '@kanda-libs/ks-design-library';
import type { OptionPopoverProps } from '..';
import OptionPopoverMobileModal from './Modal';
import useOptionPopoverMobile from './useOptionPopoverMobile';

const OptionPopoverMobile: React.FunctionComponent<OptionPopoverProps> =
  function ({ children, popoverId }) {
    const { onClick, buttonId, modalId } = useOptionPopoverMobile(popoverId);
    return (
      <>
        <Button.Icon
          id={buttonId}
          onClick={onClick}
          icon="info"
          iconProps={{
            className: 'w-4 cursor-pointer min-w-4 text-neutral-500 ml-2',
            size: 16,
          }}
        />
        <OptionPopoverMobileModal modalId={modalId}>
          {children}
        </OptionPopoverMobileModal>
      </>
    );
  };

export default OptionPopoverMobile;

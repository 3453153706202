import type { FunctionComponent } from 'react';

import { TrainingBanner } from 'components';
import { CompanyBanner } from 'pages/MyAccount/components';
import { Graphs } from '../components';

const HomeContent: FunctionComponent = function () {
  return (
    <>
      <CompanyBanner homeBanner className="mb-6 md:mb-8" />
      <TrainingBanner className="mb-6 md:mb-12" />
      <Graphs />
    </>
  );
};

export default HomeContent;

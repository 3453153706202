import { useAppDispatch } from 'components/App';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getQueryTerms } from 'store/selectors/query';
import { setQuery, type QueryTerms, type QueryType } from 'store/slices/query';

export interface QueryHook {
  fetchedQuery: string;
  onSetQuery: (nextQuery: string) => void;
}

export default function useQuery(queryType: QueryType | ''): QueryHook {
  const dispatch = useAppDispatch();
  const query = useSelector(getQueryTerms);

  const onSetQuery = useCallback(
    (nextQuery: string) => {
      dispatch(setQuery([queryType as QueryType, nextQuery]));
    },
    [dispatch, queryType],
  );

  const fetchedQuery = useMemo(
    () => query[queryType as keyof QueryTerms] || '',
    [query, queryType],
  );

  return {
    fetchedQuery,
    onSetQuery,
  };
}

import { useFormContext } from '@kanda-libs/ks-component-ts';
import {
  actions,
  type InfoValidationEmail,
} from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { useDebounce } from 'use-debounce';

const createSuggestion = (verify: InfoValidationEmail | undefined) => {
  if (!verify || verify?.verdict === 'valid') return null;
  if (!verify?.suggestion)
    return 'This email address may not be correct. Please check it before submission';
  const email = [verify?.email?.split('@')[0], verify?.suggestion]?.join('@');
  return `This email may not be correct. Did you mean to enter ${email}?`;
};

const ContainerComponent = ({ children }) => {
  const [emailName, setEmailName] = useState(null);

  const { watch, setValue, trigger } = useFormContext();

  const email = emailName ? watch(emailName) : null;

  const [query] = useDebounce(email, 2000);

  const dispatch = useAppDispatch();
  const verify = useSelector(selectors.infoValidateEmail.getRawResponse) as
    | InfoValidationEmail
    | undefined;
  const isSubmitting = useSelector(selectors.infoValidateEmail.getIsSubmitting);

  const suggestion = createSuggestion(verify);
  const iconProps = useMemo(() => {
    if (!verify)
      return {
        icon: 'check',
        stroke: 'green-500',
      };
    if (verify?.verdict === 'invalid')
      return {
        icon: 'error',
        stroke: 'red-200',
      };
    if (verify?.verdict === 'risky')
      return {
        icon: 'info',
        stroke: 'orange-200',
      };
    return {
      icon: 'check',
      stroke: 'green-500',
    };
  }, [verify]);
  const showIcon = useMemo(() => {
    if (!query) return false;
    if (isSubmitting) return false;
    return true;
  }, [query, isSubmitting]);

  const queryRef = useRef<string>('');
  useEffect(() => {
    if (!query) return;
    const emailQuery = query as unknown as string;
    if (emailQuery === queryRef.current) return;
    queryRef.current = emailQuery;
    const name = (emailName as unknown as string)?.replace(
      'email',
      'email_validation',
    );
    dispatch(
      actions.infoValidateEmail({
        params: {
          email: emailQuery,
        },
        onError: () => {
          setValue(name, 'valid');
          trigger(emailName as unknown as string);
        },
        onSuccess: (val) => {
          const verdict = val?.verdict;
          setValue(name, verdict);
          trigger(emailName as unknown as string);
        },
      }),
    );
  }, [isSubmitting, query, dispatch, emailName, setValue, trigger]);

  return children({
    setEmailName,
    isSubmitting,
    suggestion,
    iconProps,
    showIcon,
  });
};

ContainerComponent.displayName = 'ValidateEmail-container';

export default ContainerComponent;

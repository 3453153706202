import { Form } from '@kanda-libs/ks-component-ts';
import { Button, Loader, Text } from '@kanda-libs/ks-design-library';
import { WorkTypesSelect } from 'components';
import { HEADING, HEADING2 } from './constants';
import useAddWorkTypesForm from './useAddWorkTypesForm';

const AddWorkTypesContent: React.FunctionComponent = function ({}) {
  const { form, onSubmit, isLoading, isSubmitting } = useAddWorkTypesForm();
  return (
    <Form
      form={form}
      onSubmit={onSubmit}
      id="add-work-types-form"
      className="flex flex-col w-full h-full text-left"
    >
      <Text
        isLoading={isLoading}
        className="text-24-28-em text-center mb-6"
        text="Welcome Back to Kanda!"
      />
      <Text text={HEADING} className="text-14-22 mb-4" isLoading={isLoading} />
      <Text text={HEADING2} className="text-14-22 mb-6" isLoading={isLoading} />
      <WorkTypesSelect alwaysActive isLoading={isLoading} />
      <Button.Text
        id="add-work-types-form"
        submit
        label="Update Work Types"
        icon="check"
        variant="gradient"
        className="mt-6"
        disabled={isLoading || isSubmitting}
      />
      <Loader isLoading={isSubmitting} />
    </Form>
  );
};

export default AddWorkTypesContent;

import type { Customer } from '@kanda-libs/ks-frontend-services';
import { useMemo } from 'react';
import { formatAddress } from 'utils/Address-functions';

export interface CustomerInfoCardHook {
  name: string;
  address: string;
  email?: string;
  phone?: string;
}

export default function useCustomerInfoCard(
  customer: Customer | undefined,
  isLoading: boolean,
): CustomerInfoCardHook {
  const name = useMemo(() => {
    if (isLoading) return 'Loading name';
    return (
      [customer?.first_name, customer?.last_name].filter(Boolean).join(' ') ||
      'No name'
    );
  }, [isLoading, customer]);

  const address = useMemo(() => {
    if (isLoading) return 'Loading address';
    return formatAddress(customer?.address, 'No address given yet');
  }, [isLoading, customer]);

  const email = useMemo(() => {
    if (isLoading) return 'Loading email';
    return customer?.email;
  }, [isLoading, customer]);

  const phone = useMemo(() => {
    if (isLoading) return 'Loading phone';
    return customer?.phone;
  }, [isLoading, customer]);

  return {
    name,
    address,
    email,
    phone,
  };
}

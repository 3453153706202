import { Icon, Text } from '@kanda-libs/ks-design-library';
import InfoCard from 'components/InfoCard';
import InterestInFinanceTag from 'pages/Leads/components/InterestInFinanceTag';
import LeadConvertToJob from '../LeadConvertToJob';
import useLeadQuoteDetails from './useLeadQuoteDetails';

const LeadQuoteDetails: React.FunctionComponent = function ({}) {
  const {
    iconProps,
    label,
    budget,
    workType,
    interestInFinance,
    showButton,
    isLoading,
  } = useLeadQuoteDetails();
  return (
    <InfoCard title="Lead Details">
      <div className="flex flex-col md:px-16 md:py-8">
        <div className="flex flex-row md:flex-col mb-2 md:mb-4 mr-auto md:mx-auto items-center gap-y-4 gap-x-3">
          <Icon {...iconProps} isLoading={isLoading} />
          <Text
            text={label}
            isLoading={isLoading}
            className="text-16-24-em md:text-20-24-em"
          />
        </div>
        <div className="grid grid-cols-[min-content_auto] gap-x-3 md:gap-y-1 md:mx-auto">
          <Text
            text="Work Type:"
            className="text-16-24-em md:text-18-22-em whitespace-nowrap"
            isLoading={isLoading}
          />
          <Text
            text={workType}
            className="text-16-24-em md:text-18-22-em text-neutral-700"
            isLoading={isLoading}
          />
          <Text
            text="Budget:"
            className="text-16-24-em md:text-18-22-em whitespace-nowrap"
            isLoading={isLoading}
          />
          <div className="flex flex-col gap-y-1">
            <Text
              text={budget}
              className="text-16-24-em md:text-18-22-em text-neutral-700"
              isLoading={isLoading}
            />
            <InterestInFinanceTag
              interest={interestInFinance}
              isLoading={isLoading}
              className="mr-auto"
            />
          </div>
        </div>
        {showButton && (
          <div className="w-60 mx-auto mt-6">
            <LeadConvertToJob size={40} />
          </div>
        )}
      </div>
    </InfoCard>
  );
};

export default LeadQuoteDetails;

import { type TagColor } from '@kanda-libs/ks-design-library';
import type { EnquiryState } from '@kanda-libs/ks-frontend-services';

export const getLabelColor = (
  status: EnquiryState | undefined,
  archived: boolean,
): TagColor => {
  if (archived) return 'orange';
  switch (status) {
    case 'viewed':
    case 'contact_only':
      return 'grey';
    case 'contact_agreed':
    case 'preferences_set':
    case 'ask_for_quote':
    case 'submitted':
      return 'blue';
    case 'quoted':
    case 'converted_to_job':
      return 'green';
    case 'not_interested':
    case 'unreachable':
      return 'violet';
    case 'other_reason':
      return 'orange';
    default:
      return 'grey';
  }
};

export const getLabel = (
  status: EnquiryState | undefined,
): string | undefined => {
  switch (status) {
    case 'viewed':
    case 'contact_only':
      return undefined;
    case 'contact_agreed':
    case 'preferences_set':
      return 'Contact agreed';
    case 'ask_for_quote':
    case 'submitted':
      return 'Quote Requested';
    case 'quoted':
      return 'Quoted';
    case 'converted_to_job':
      return 'Converted';
    case 'not_interested':
      return 'Not Interested';
    case 'unreachable':
      return 'Unreachable';
    case 'other_reason':
      return 'Other';
    default:
      return undefined;
  }
};

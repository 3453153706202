import { useLeadContext } from 'pages/Lead/context';

export interface ActionButtonsHook {
  archived: boolean;
}

export default function useActionButtons(): ActionButtonsHook {
  const { archived } = useLeadContext();
  return { archived };
}

import { DesktopLayout } from 'pages/MyAccount/components';
import MyAccountAlwaysOnLinkContent from '../Content';

const MyAccountAlwaysOnLinkDesktop: React.FunctionComponent = function () {
  return (
    <DesktopLayout>
      <MyAccountAlwaysOnLinkContent />
    </DesktopLayout>
  );
};

export default MyAccountAlwaysOnLinkDesktop;

import type {
  Company,
  FinanceProvider,
} from '@kanda-libs/ks-frontend-services';

export type FormattedLenderConfig = { [key in FinanceProvider]?: string };

// TODO: find a better way to handle allium having no config
export const formatLenderConfigs = (
  company: Company,
): FormattedLenderConfig | undefined => {
  const verified = company?.lifecycle === 'verified';
  const configs = company?.lender_configs;
  if (!configs || !verified) return undefined;
  if (!configs)
    return {
      allium: 'placeholder',
    };
  return {
    ...configs.reduce(
      (final, config) => ({
        ...final,
        [config.provider]: config.xid,
      }),
      {},
    ),
    allium: 'placeholder',
  };
};

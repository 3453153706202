import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import { useMemo } from 'react';
import { formatAprString, getFinanceRatesLabel } from 'utils';

export interface FinanceOptionUnavailableHook {
  minDepositPercentage: number | undefined;
  minDepositAmount: number | undefined;
  maxDepositPercentage: number | undefined;
  maxDepositAmount: number | undefined;
  minJobAmount: number | undefined;
  maxJobAmount: number | undefined;
  popoverId: string;
  rateName: string;
  aprLabel: string;
}

export default function useFinanceOptionUnavailable(
  rates: FinanceRate[],
): FinanceOptionUnavailableHook {
  const rate = rates[0];

  const popoverId = useMemo(() => {
    const { provider, name } = rate;
    return [provider, name].join('-');
  }, [rate]);

  const rateName = useMemo(() => getFinanceRatesLabel(rates), [rates]);

  const aprLabel = useMemo(
    () => `${formatAprString(String(rate.apr || 0))}% APR`,
    [rate],
  );

  return {
    minDepositPercentage: rate.min_deposit_pct,
    minDepositAmount: rate.min_deposit_value,
    maxDepositPercentage: rate.max_deposit_pct,
    maxDepositAmount: rate.max_deposit_value,
    minJobAmount: rate.min_job_value,
    maxJobAmount: rate.max_job_value,
    popoverId,
    rateName,
    aprLabel,
  };
}

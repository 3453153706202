import { useMemo } from 'react';

export interface TradeBodyButtonProps {
  active: boolean;
  onClick: () => void;
  label: string;
}

const TradeBodyButton: React.FunctionComponent<TradeBodyButtonProps> =
  function ({ active, label, onClick }) {
    const classNames = useMemo(
      () => ({
        container: [
          'flex flex-row w-full text-14-22 rounded-xl gap-x-3 px-4 py-3 border',
          active
            ? 'border-green-500 bg-green-100'
            : 'border-neutral-400 bg-neutral-000',
        ].join(' '),
        indicator: [
          'w-5 h-5 min-w-5 min-h-5 rounded-full flex bg-neutral-000 my-auto',
          active
            ? 'border-[6px] box-border border-green-500'
            : 'border border-neutral-600',
        ].join(' '),
      }),
      [active],
    );

    return (
      <button type="button" onClick={onClick} className={classNames.container}>
        <div className={classNames.indicator} />
        {label}
      </button>
    );
  };

export default TradeBodyButton;

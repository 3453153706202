import {
  Button,
  Card,
  Text,
  useAtlasChatWidget,
} from '@kanda-libs/ks-design-library';
import type { FunctionComponent } from 'react';
import { CLASS_NAMES } from './constants';

const ContactBanner: FunctionComponent = function () {
  const { openChat } = useAtlasChatWidget();

  return (
    <Card className={CLASS_NAMES.wrapper}>
      <div className={CLASS_NAMES.innerWrapper}>
        <div>
          <Text text="Customer support" className={CLASS_NAMES.title} />
          <Text
            text="Have a question for us? Get in touch"
            className={CLASS_NAMES.subTitle}
          />
        </div>
        <div className={CLASS_NAMES.ctas}>
          <Button.Text
            id="footer-chat"
            size={40}
            label="Chat with us"
            variant="outline-primary"
            onClick={() => {
              openChat();
            }}
          />
          <a href="mailto:help@kanda.com">
            <Button.Text
              left
              id="footer-email"
              size={40}
              label="Email"
              icon="email"
              variant="outline"
            />
          </a>
          <a href="tel:03308187500">
            <Button.Text
              left
              id="footer-call"
              size={40}
              label="Call"
              icon="phone"
              variant="outline"
            />
          </a>
        </div>
      </div>
    </Card>
  );
};

export default ContactBanner;

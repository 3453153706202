import { Button, Icon, Text } from '@kanda-libs/ks-design-library';

export interface WorkTypeModalModalMemberProps {
  handleClose: () => void;
}

const WorkTypeModalModalMember: React.FunctionComponent<WorkTypeModalModalMemberProps> =
  function ({ handleClose }) {
    return (
      <div className="flex flex-col">
        <Icon
          icon="error"
          stroke="red-200"
          size={64}
          className="mx-auto mb-6"
        />
        <Text
          text="You'll need an admin to add work types"
          className="w-full text-center text-24-28-em"
        />
        <Text
          text="You're current permission level won't allow you to add additional work types to your company. Please contact an admin within your company to update the work types for you."
          className="text-16-24 mt-4"
        />
        <Button.Text
          id="create-job-update-worktype-modal-insufficient-permissions"
          label="Continue job"
          variant="gradient"
          className="w-full mt-6"
          onClick={handleClose}
        />
      </div>
    );
  };

export default WorkTypeModalModalMember;

import { FieldHandle, FormTheme } from '@kanda-libs/ks-component-ts';
import { Icon } from '@kanda-libs/ks-design-library';
import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import useFinanceOptionHandleToggle from './useFinanceOptionHandleToggle';

export interface FinanceOptionHandleToggleProps {
  rates: FinanceRate[];
}

const FinanceOptionHandleToggle: React.FunctionComponent<FinanceOptionHandleToggleProps> =
  function ({ rates }) {
    const { id, isSelected, invalid, pending, onClick, classNames } =
      useFinanceOptionHandleToggle(rates);
    return (
      <div className="mr-2 -my-0.75">
        {pending ? (
          <div className={classNames.iconWrapper}>
            <Icon
              icon="warning"
              size={16}
              className="w-4 h-4 text-orange-200"
            />
          </div>
        ) : (
          <>
            {invalid && (
              <div className={classNames.iconWrapper}>
                <Icon
                  icon="error"
                  size={16}
                  className="w-4 h-4 text-neutral-600"
                />
              </div>
            )}
            {!invalid && (
              <div className={classNames.handleWrapper}>
                <FormTheme variant="clean">
                  <FieldHandle.Checkbox
                    id={id}
                    onClick={onClick}
                    checked={isSelected}
                  />
                </FormTheme>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

export default FinanceOptionHandleToggle;

import { type FunctionComponent } from 'react';
import Highlighter from 'react-highlight-words';
import { type QueryType } from 'store/slices/query';
import { CLASS_NAMES, HIGHLIGHT_PROPS } from './constants';
import useTableContact from './useTableContact';

export interface TableContactProps {
  email: string;
  phone: string;
  queryType?: QueryType;
}

const TableContact: FunctionComponent<TableContactProps> = function ({
  email,
  phone,
  queryType = 'leads',
}) {
  const { searchWords } = useTableContact(queryType);

  return (
    <div className={CLASS_NAMES.container}>
      <Highlighter
        {...HIGHLIGHT_PROPS}
        searchWords={searchWords}
        textToHighlight={email || ''}
      />
      <Highlighter
        {...HIGHLIGHT_PROPS}
        searchWords={searchWords}
        textToHighlight={phone || ''}
      />
    </div>
  );
};

export default TableContact;

import TradeBodyButton from './TradeBodyButton';

export interface TradeBodyButtonsProps {
  onYesClick: () => void;
  onNoClick: () => void;
  state?: string;
}

const TradeBodyButtons: React.FunctionComponent<TradeBodyButtonsProps> =
  function ({ onYesClick, onNoClick, state }) {
    return (
      <div className="flex flex-col mb-6">
        <p className="text-14-22-em text-neutral-800 font-bold mb-2">
          Are you a member of a trade body?
        </p>
        <div className="flex flex-row gap-x-4">
          <TradeBodyButton
            onClick={onYesClick}
            label="Yes"
            active={state === 'yes'}
          />
          <TradeBodyButton
            onClick={onNoClick}
            label="No"
            active={state === 'no'}
          />
        </div>
      </div>
    );
  };

export default TradeBodyButtons;

import { Icon } from '@kanda-libs/ks-design-library';
import type { WorkTypeSelectOption as WorkTypeSelectOptionProps } from './useWorkTypesSelect';
import useWorkTypesSelectOption from './useWorkTypesSelectOption';

const WorkTypeSelectOption: React.FunctionComponent<WorkTypeSelectOptionProps> =
  function ({ name, onClick, active }) {
    const { classNames } = useWorkTypesSelectOption(active);
    return (
      <button type="button" className={classNames.button} onClick={onClick}>
        <div className={classNames.toggle}>
          <Icon
            icon="check"
            size={12}
            stroke="neutral-000"
            className="m-auto"
          />
        </div>
        <p className="text-16-24">{name}</p>
      </button>
    );
  };

export default WorkTypeSelectOption;

import {
  ModalContainer,
  ModalLayoutSlideUp,
} from '@kanda-libs/ks-design-library';
import type { MutableRefObject, ReactNode } from 'react';

export interface PopoverMobileModalProps {
  children: ReactNode;
  modalId: string;
}

const PopoverMobileModal: React.FunctionComponent<PopoverMobileModalProps> =
  function ({ children, modalId }) {
    return (
      <ModalContainer id={modalId}>
        {({ id, handleClose, ref }) => (
          <ModalLayoutSlideUp
            id={id}
            onClose={handleClose}
            ref={ref as MutableRefObject<HTMLDivElement>}
          >
            <div className="flex flex-col">{children}</div>
          </ModalLayoutSlideUp>
        )}
      </ModalContainer>
    );
  };

export default PopoverMobileModal;

export const SEARCH_OPTIONS = {
  keys: [
    { name: 'id', weight: 1 },
    { name: 'source', weight: 1 },
    { name: 'id', weight: 1 },
    { name: 'firstName', weight: 1 },
    { name: 'lastName', weight: 1 },
    { name: 'email', weight: 1 },
    { name: 'phone', weight: 1 },
    { name: 'address', weight: 1 },
    { name: 'source', weight: 1 },
    { name: 'currentState', weight: 1 },
    { name: 'verboseCurrentState', weight: 1 },
    { name: 'budget', weight: 1 },
    { name: 'interestInFinance', weight: 1 },
    { name: 'workType', weight: 1 },
  ],
  includeMatches: true,
  matchAllOnEmptyQuery: true,
  minMatchCharLength: 3,
  threshold: 0.4,
};

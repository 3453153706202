import { BreakPoints } from '@kanda-libs/ks-design-library';
import { Helmet } from 'components';
import MyAccountFinanceRatesProvider from './context';
import MyAccountFinanceRatesDesktop from './Desktop';
import MyAccountFinanceRatesMobile from './Mobile';

const MyAccountFinanceRates: React.FunctionComponent = function () {
  return (
    <MyAccountFinanceRatesProvider>
      <Helmet title="Team Settings" />
      <BreakPoints
        mobile={<MyAccountFinanceRatesMobile />}
        desktop={<MyAccountFinanceRatesDesktop />}
      />
    </MyAccountFinanceRatesProvider>
  );
};

export default MyAccountFinanceRates;

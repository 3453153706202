import { Widget } from '@kanda-libs/ks-component-ts';

import { Button } from '@kanda-libs/ks-design-library';
import { VALIDATION } from './Document-constants';
import Container from './Document-container';
import AIDocModal from './Modal';

interface ViewComponentProps {
  /**
   * Name of field
   */
  arrayName: string;
  /**
   * Default value for field
   */
  index: number;
  refreshJobDetails?: () => void;
}

const ViewComponent = ({ refreshJobDetails, ...props }: ViewComponentProps) => (
  <Container {...props}>
    {({ inputFile, error, content, showAnalyseModal, isLoading }) => (
      <div className="flex flex-col w-full mt-0 md:w-96">
        <Widget.JobJobItemsQuoteDocumentContentArrayInput
          name="quote_document.content"
          jobPdfInput
          maxFiles={1}
          inputFile={inputFile}
          validation={VALIDATION}
          error={error}
          maxSize={768 * 1024}
          accept="application/pdf"
          label={
            <div className="flex flex-row mb-2">
              <span className="text-14-22-em text-neutral-900">Quote PDF</span>
            </div>
          }
          isLoading={isLoading}
          {...props}
        />
        {content && (
          <Button.Text
            id="analyse-pdf-builder"
            label="Analyse PDF with AI"
            variant="gradient"
            size={40}
            onClick={showAnalyseModal}
            className="mb-6 md:mb-0"
          />
        )}
        <AIDocModal content={content} refreshJobDetails={refreshJobDetails} />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Createjob-Quote-Custom-Item-Document-view';

export default ViewComponent;

import type { AuthUser, Enrolment } from '@kanda-libs/ks-frontend-services';
import type { MyAccountFinanceRatesFormValues } from './useMyAccountFinanceRatesForm';

export const formatEnrolmentBody = (
  fv: MyAccountFinanceRatesFormValues,
  me: AuthUser,
  enrolment: Enrolment,
): Enrolment => {
  const currentRates = enrolment.rate_request.added;
  const toRemove = currentRates.filter((rate) => !fv.enabled.includes(rate));
  const toAdd = fv.enabled.filter((rate) => !currentRates.includes(rate));
  return {
    ...enrolment,
    rate_request: {
      ...enrolment.rate_request,
      requester: me.email,
      to_add: toAdd,
      to_remove: toRemove,
    },
  };
};

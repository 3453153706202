import type { IconProps } from '@kanda-libs/ks-design-library';
import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import { useFinanceOptionsContext } from 'components/FinanceOptions/context';
import { useMemo } from 'react';
import { filterRatesByTotalAndDeposit } from 'utils';
import { BASE_ICON_CLASSNAME } from './constants';

export interface FinanceOptionHandleHook {
  available: boolean;
  iconProps: IconProps;
}

export default function useFinanceOptionHandle(
  rates: FinanceRate[],
): FinanceOptionHandleHook {
  const { total, deposit, lenderConfigs, isExample } =
    useFinanceOptionsContext();

  const pending = useMemo(() => {
    if (!lenderConfigs) return true;
    const rateLenders = rates
      .map((rate) => rate.provider)
      .filter((provider, idx, arr) => arr.indexOf(provider) === idx);
    const configs = rateLenders.map((lender) => lenderConfigs[lender]);
    return configs.some((config) => !config);
  }, [rates, lenderConfigs]);

  const validRates = useMemo(
    () => filterRatesByTotalAndDeposit(rates, total, deposit),
    [rates, total, deposit],
  );

  const available = useMemo(() => validRates.length > 0, [validRates]);

  const iconProps = useMemo(() => {
    if (pending && !isExample)
      return {
        icon: 'warning',
        className: [BASE_ICON_CLASSNAME, 'text-orange-200'].join(' '),
        size: 16,
      };
    if (!available)
      return {
        icon: 'error',
        className: [BASE_ICON_CLASSNAME, 'text-neutral-600'].join(' '),
        size: 16,
      };
    return {
      icon: 'check',
      className: [BASE_ICON_CLASSNAME, 'text-green-600'].join(' '),
      size: 16,
    };
  }, [available, pending, isExample]);

  return {
    available,
    iconProps,
  };
}

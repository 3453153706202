import { Layout } from '@kanda-libs/ks-design-library';
import Header from '../../components/Header';
import MyAccountAlwaysOnLinkContent from '../Content';

const MyAccountAlwaysOnLinkMobile: React.FunctionComponent = function () {
  return (
    <Layout noBorder header={<Header />}>
      <MyAccountAlwaysOnLinkContent />
    </Layout>
  );
};

export default MyAccountAlwaysOnLinkMobile;

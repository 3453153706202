import { useState } from 'react';
import { useWatch } from 'react-hook-form';
import { COMPANY_ADDRESS_FIELD_NAMES } from './constants';

interface LimitedCompanyFormHook {
  selected: boolean;
  noCompany: boolean;
  noCompanyCallback: () => void;
  searchCompany: () => void;
}

export default function useLimitedCompanyForm(): LimitedCompanyFormHook {
  const [noCompany, setNoCompany] = useState<boolean>(false);

  const [search, focus, number] = useWatch({
    name: [
      COMPANY_ADDRESS_FIELD_NAMES.companySearchName,
      COMPANY_ADDRESS_FIELD_NAMES.companyFocusName,
      COMPANY_ADDRESS_FIELD_NAMES.companyNumberName,
    ],
  });

  const selected = (search && focus && number) || noCompany;

  const noCompanyCallback = () => setNoCompany(true);
  const searchCompany = () => setNoCompany(false);

  return { selected, noCompany, noCompanyCallback, searchCompany };
}

import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import { useMemo } from 'react';
import { formatAprString, getRateSubsidy } from 'utils';

export interface RateCostPopoverHook {
  subsidy: number;
  subsidyTitle: string;
  charge: number;
  aprLabel: string;
}

export default function useRateCostPopover(
  rate: FinanceRate,
  total: number,
  deposit: number,
): RateCostPopoverHook {
  // const fee = useMemo(() => rate.fee, [rate]);

  const subsidy = useMemo(
    () => getRateSubsidy(rate, total, deposit),
    [rate, total, deposit],
  );

  const subsidyTitle = useMemo(
    () => `${rate.fee / 100}% of the loan value`,
    [rate],
  );

  const charge = useMemo(() => rate.charge, [rate]);

  const aprLabel = useMemo(
    () => `${formatAprString(String(rate.apr || 0))}% APR`,
    [rate],
  );

  return { subsidy, subsidyTitle, charge, aprLabel };
}

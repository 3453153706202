import {
  useToast,
  type StringIndexedObject,
} from '@kanda-libs/ks-design-library';
import {
  services,
  useSubmit,
  type Service,
} from '@kanda-libs/ks-frontend-services';
import { useCurrentCompany } from 'hooks';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  type FunctionComponent,
  type ReactNode,
} from 'react';

export interface CompanyInfoProviderProps {
  children: ReactNode;
}

export interface Contract {
  content: string;
  name: string;
  signee?: string;
  timestamp?: number;
}

export interface CompanyInfoContextType {
  contract: Contract | undefined;
}

export const CompanyInfoContext = createContext<CompanyInfoContextType>({
  contract: undefined,
});

export const useCompanyInfoContext = () => useContext(CompanyInfoContext);

const CompanyInfoProvider: FunctionComponent<CompanyInfoProviderProps> =
  function ({ children }) {
    const [contract, setContract] = useState<Contract | undefined>(undefined);

    const { company } = useCurrentCompany();
    const { showError } = useToast();

    const { submit: generateContract, isSubmitting } = useSubmit(
      services.contract.generate as unknown as Service<
        StringIndexedObject,
        StringIndexedObject,
        StringIndexedObject
      >,
    );

    const values = useMemo(
      () => ({
        contract,
      }),
      [contract],
    );

    const initRef = useRef<boolean>(false);

    useEffect(() => {
      if (!company || contract || isSubmitting || initRef.current) return;
      initRef.current = true;
      generateContract({
        body: {
          company,
        },
      }).then(({ data: contractData, error: contractError }) => {
        if (contractError || !contractData) {
          showError(
            'Error generating your contract - please refresh and try again. If this issue persists, please contact Kanda',
          );
          initRef.current = false;
          return;
        }
        setContract({
          name: contractData.data.name,
          content: contractData.data.content,
        });
      });
    }, [company, generateContract, showError, isSubmitting, contract]);

    return (
      <CompanyInfoContext.Provider value={values}>
        {children}
      </CompanyInfoContext.Provider>
    );
  };

export default CompanyInfoProvider;

import type { FunctionComponent } from 'react';

import RequestOwnDeposit from '../../RequestOwnDeposit';
import { JobSettingsForm, WorkTypesForm } from './components';

const Content: FunctionComponent = function () {
  return (
    <div className="flex flex-col gap-y-6">
      <JobSettingsForm />
      <WorkTypesForm />
      <RequestOwnDeposit />
    </div>
  );
};

export default Content;

import { useMemo } from 'react';

export interface PercentageDisplayProps {
  title: string;
  percentage: number | undefined;
}

const PercentageDisplay: React.FunctionComponent<PercentageDisplayProps> =
  function ({ title, percentage }) {
    const label = useMemo(() => {
      if (typeof percentage === 'undefined') return 'NaN';
      return `${String(percentage / 100)}%`;
    }, [percentage]);

    if (typeof percentage === 'undefined') return null;
    return (
      <>
        <p className="text-14-22 md:text-12-18 text-light text-neutral-600">
          {title}
        </p>
        <p className="text-16-20-em text-green-400 ml-auto">{label}</p>
      </>
    );
  };

export default PercentageDisplay;

import { Layout } from '@kanda-libs/ks-design-library';
import Header from '../../components/Header';
import MyAccountFinanceRatesContent from '../Content';

const MyAccountFinanceRatesMobile: React.FunctionComponent = function () {
  return (
    <Layout noBorder header={<Header />}>
      <MyAccountFinanceRatesContent />
    </Layout>
  );
};

export default MyAccountFinanceRatesMobile;

import { Icon } from '@kanda-libs/ks-design-library';
import type { WorkTypeSelectedOption } from './useWorkTypesSelect';

const SelectedOption: React.FunctionComponent<WorkTypeSelectedOption> =
  function ({ name, onClick }) {
    return (
      <div className="flex flex-row gap-x-3 border border-neutral-700 bg-neutral-100 rounded-full px-3 py-1 cursor-default">
        <p className="text-14-22">{name}</p>
        <button type="button" onClick={onClick} aria-label="remove-item">
          <Icon icon="close" size={16} />
        </button>
      </div>
    );
  };

export default SelectedOption;

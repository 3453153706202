import { FormTheme } from '@kanda-libs/ks-component-ts';
import { type ReactNode } from 'react';
import { BusinessForm, MeForm, WorkTypesForm } from './components';

export interface SetupAccountBusinessFormProps {
  schemaError: boolean;
  children: ReactNode;
}

const SetupAccountBusinessForm: React.FunctionComponent<SetupAccountBusinessFormProps> =
  function ({ schemaError, children }) {
    return (
      <div className="flex flex-col flex-1 md:border border-neutral-300 rounded-lg md:p-16">
        <div className="w-full flex-1 max-w-120 mx-auto flex flex-col gap-y-6">
          <FormTheme variant="streamline">
            <MeForm />
            <BusinessForm schemaError={schemaError} />
            <WorkTypesForm />
          </FormTheme>
          {children}
        </div>
      </div>
    );
  };

export default SetupAccountBusinessForm;

import { Button, Loader } from '@kanda-libs/ks-design-library';
import { URLS } from 'config';
import LeadNameStatus from 'pages/Lead/components/LeadNameStatus';
import { useLeadContext } from 'pages/Lead/context';
import { Link } from 'react-router-dom';
import ActionButtons from './ActionButtons';

const DesktopLeadHeader: React.FunctionComponent = function () {
  const { isSubmitting } = useLeadContext();
  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-row">
        <div className="flex flex-col mr-4">
          <Link to={URLS.leads}>
            <Button.Icon id="leads-back" icon="arrow-left" size="48-20" />
          </Link>
        </div>
        <LeadNameStatus />
      </div>
      <ActionButtons />
      <Loader isLoading={isSubmitting} />
    </div>
  );
};

export default DesktopLeadHeader;

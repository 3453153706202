import type { TagColor } from '@kanda-libs/ks-design-library';
import type { EnquiryState } from '@kanda-libs/ks-frontend-services';
import { useMemo } from 'react';
import { getLabel, getLabelColor } from './helpers';

export interface LeadStatusTagHook {
  color: TagColor;
  label: string | undefined;
}

export default function useLeadStatusTag(
  status: EnquiryState | undefined,
  archived: boolean,
  isLoading: boolean,
): LeadStatusTagHook {
  const color = useMemo(
    () => getLabelColor(status, archived),
    [status, archived],
  );
  const label = useMemo(() => {
    if (isLoading) return 'loading';
    if (archived) return 'Archived';
    return getLabel(status);
  }, [isLoading, archived, status]);
  return {
    color,
    label,
  };
}

import { useMe } from 'hooks';
import { useMemo } from 'react';

export interface WorkTypeModalHook {
  isAdmin: boolean;
}

export default function useWorkTypeModal(): WorkTypeModalHook {
  const { me } = useMe();

  const isAdmin = useMemo(() => {
    const role = me?.role;
    if (!role) return false;
    return ['company-admin', 'staff'].includes(role);
  }, [me]);

  return {
    isAdmin,
  };
}

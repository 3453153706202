import { useToast } from '@kanda-libs/ks-design-library';
import { actions, Job } from '@kanda-libs/ks-frontend-services';
import type { AsyncThunkActionError } from '@kanda-libs/ks-frontend-services/src/store/types';
import { useAppDispatch } from 'components/App';
import { URLS } from 'config';
import useApiError from 'hooks/useApiError';
import { formatPayload } from 'pages/CreateJob/components/CloseModal/CloseModal-functions';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';

import { useCallback } from 'react';
import { useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { selectors } from 'store';

export interface WorkTypeModalModalAdminHook {
  onSaveClick: () => void;
  onExitClick: () => void;
  isSubmitting: boolean;
}

export default function useWorkTypeModalModalAdmin(
  handleClose: () => void,
): WorkTypeModalModalAdminHook {
  const { push, replace } = useHistory();
  const { data: formData } = useCreateJobContextValue();
  const enteredData = useWatch();
  const { showSuccess } = useToast();
  const dispatch = useAppDispatch();
  const isSubmitting = useSelector(selectors.job.getIsSubmitting);

  const onSuccess = useCallback(
    (job?: Job) => {
      const id = job?.id;
      if (id) {
        replace(generatePath(URLS.createJobFromDraft, { id }));
        setTimeout(() => {
          showSuccess('Draft saved');
          push(URLS.jobSettings);
          handleClose();
        }, 10);
        return;
      }
      showSuccess('Draft saved');
      push(URLS.jobSettings);
      handleClose();
    },
    [showSuccess, replace, push, handleClose],
  );

  const handleApiError = useApiError('Error saving draft');

  const onError = useCallback(
    (error: AsyncThunkActionError) => {
      handleApiError(error);
      push(URLS.jobSettings);
      handleClose();
    },
    [handleApiError, push, handleClose],
  );

  const onSaveClick = useCallback(() => {
    const compiledData = {
      ...formData,
      job: {
        ...formData?.job,
        ...enteredData?.job,
      },
    };
    const body = formatPayload(compiledData);
    const id = body?.id;

    if (id) {
      dispatch(
        actions.putJob({
          body,
          params: { id },
          onError,
          onSuccess,
        }),
      );
      return;
    }
    dispatch(
      actions.postJob({
        body,
        onError,
        onSuccess,
      }),
    );
  }, [dispatch, onError, onSuccess, formData, enteredData]);

  const onExitClick = useCallback(() => {
    push(URLS.jobSettings);
    handleClose();
  }, [push, handleClose]);

  return {
    onSaveClick,
    onExitClick,
    isSubmitting,
  };
}

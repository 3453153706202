import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import OptionPopover from '../OptionPopover';
import useFinanceOptionPending from './useFinanceOptionPending';

export interface FinanceOptionRateDetailsPendingProps {
  rates: FinanceRate[];
}

const FinanceOptionRateDetailsPending: React.FunctionComponent<FinanceOptionRateDetailsPendingProps> =
  function ({ rates }) {
    const { popoverId } = useFinanceOptionPending(rates);
    return (
      <div className="flex flex-row items-center col-span-2 justify-center pr-2">
        <p className="text-12-18-em text-neutral-600">Rate Pending</p>
        <OptionPopover popoverId={popoverId}>
          <div className="grid grid-cols-[1fr_auto] gap-y-2 gap-x-2">
            <p className="text-18-22-em md:text-12-18-em text-neutral-900 col-span-2 mb-2 md:mb-0">
              Rate Currently Pending
            </p>
            <p className="text-14-22 md:text-12-18 text-neutral-900 col-span-2 mb-2 md:mb-0">
              These rates are currently pending approval from the lender. This
              can take up to a week, dependent on the lender. Please check back
              soon
            </p>
          </div>
        </OptionPopover>
      </div>
    );
  };

export default FinanceOptionRateDetailsPending;

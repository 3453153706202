import type { SelectOption } from '@kanda-libs/ks-component-ts';
import {
  Job,
  JobItem,
  WorkType,
  type Company,
} from '@kanda-libs/ks-frontend-services';
import { pipe } from 'fp-ts/lib/function';
import { capitalise, filterForRatesByWorkType } from 'utils';
import { type WorkTypeOption } from './constants';

// interface WorkTypeSelectProps {
//   name: string;
//   options: SelectOption[];
// }

const mapValuesAndNamesToSelectOptions = (
  options: WorkTypeOption[],
): SelectOption[] =>
  options.map((option: WorkTypeOption) => ({
    value: option.value,
    name: option?.name || option.value.split('_').map(capitalise).join(' '),
  }));

export const formatSelectOptions = (
  options: WorkTypeOption[],
): SelectOption[] => pipe(options, mapValuesAndNamesToSelectOptions);

export const checkWorkTypeNoRates = (
  company: Company,
  workType: WorkType,
): boolean => {
  const rates = company?.finance_rates;
  if (!rates) return false;
  const valid = filterForRatesByWorkType(rates, workType);
  return valid.length === 0;
};

// const formatToOption = (workTypes: WorkType[]): WorkTypeOption[] => workTypes.map((type: WorkType) => )

// export const generateProps = (workTypes?: WorkType[]): WorkTypeSelectProps => {
//   if (!workTypes)
//     return {
//       name: 'job.work_type',
//       options: formatSelectOptions(WORK_TYPES),
//     };
//   return {
//     name: 'job.work_type',
//     options: pipe(workTypes, formatToOption, formatSelectOptions),
//   };
// };

// // Check if user has added an item title or price
// const checkItem = (item: JobItem): boolean =>
//   [!!item?.title, !!item?.price?.amount].filter(Boolean).length === 2;

// // Check validity of all items
// const checkItems = (items: JobItem[]): boolean => {
//   const checked = items.map((item) => checkItem(item));
//   return !!checked.filter((item) => item > 0).filter(Boolean).length;
// };

const checkStandardItem = (item: JobItem): boolean =>
  [item?.title, item?.price?.amount].filter(Boolean).length > 2;

const standardItemChecker = (items: JobItem[]): boolean =>
  items.map((item: JobItem) => checkStandardItem(item)).some((val) => val);

const checkCustomItem = (item: JobItem): boolean =>
  Boolean(item?.quote_document?.content);

const customItemChecker = (items: JobItem[]): boolean =>
  items.map((item) => checkCustomItem(item)).some((val) => val);

export const shouldShowSave = (values) => {
  const job: Job = values?.job;
  if (!job) return false;
  const type = values?.jobType;
  const itemChecker =
    type === 'custom' ? customItemChecker : standardItemChecker;

  const itemCheck = itemChecker(job.job_items);
  // Check at least title and one valid item
  const conditions = [Boolean(job?.title), itemCheck].filter(Boolean).length;
  if (conditions < 2) return false;
  return true;
};

export const CLASS_NAMES = {
  container:
    'flex flex-col md:flex-row justify-between w-full mb-8 md:mt-18 md:mb-auto',
  info: 'flex flex-col mr-0 md:mr-6 pl-12',
  titleWrapper: 'flex flex-1 w-full flex-row justify-between',
  title: 'text-style-d md:text-style-a text-neutral-900 mb-3',
  customer:
    'text-style-h-em md:text-style-f text-neutral-600 flex flex-row items-center mb-3',
  customerLabel: 'ml-2',
  desktopFooter: 'flex flex-row flex-wrap',
  footer: '-ml-4 flex flex-row flex-wrap',
  status: 'md:mr-6 flex items-center',
};

export const SKELETONS = {
  title: {
    width: 160,
  },
  customer: {
    width: 120,
  },
  icon: {
    width: 20,
    height: 20,
  },
  status: {
    width: 60,
    height: 20,
  },
};

export const CUSTOMER_PLACEHOLDER = 'No customer name added...';

export const TITLE_PLACEHOLDER = 'No title added...';

export const CUSTOMER_ICON_PROPS = {
  desktop: {
    size: 22,
    icon: 'account',
    stroke: 'neutral-500',
  },
  mobile: {
    size: 22,
    icon: 'account',
    stroke: 'neutral-500',
  },
};

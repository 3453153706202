import { useState, type Dispatch, type SetStateAction } from 'react';

export interface PaginationProps {
  pageIndex: number;
  perPage: number;
  totalPages: number;
  setPageIndex: Dispatch<SetStateAction<number>>;
  setPerPage: Dispatch<SetStateAction<number>>;
}

export default function usePagination(
  numberOfResults: number,
): PaginationProps {
  const [pageIndex, setPageIndex] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const totalPages = Math.ceil(numberOfResults / perPage);

  return {
    pageIndex,
    perPage,
    totalPages,
    setPageIndex,
    setPerPage,
  };
}

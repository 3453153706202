import {
  aiActions,
  aiSelectors,
  JobQuote,
  PostPDFOutput,
  type PostPDFInput,
} from '@kanda-libs/kai-frontend-services';
import { useToast } from '@kanda-libs/ks-design-library';
import type { Company, WorkType } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { useCurrentCompany } from 'hooks';
import { useCallback } from 'react';
import {
  useFormContext,
  type FieldValue,
  type UseFormSetValue,
} from 'react-hook-form';
import { useSelector } from 'react-redux';

interface AnalysePDFHook {
  onClick: () => void;
  isSubmitting: boolean;
}

interface JobTotals {
  totalExVat: number;
  vatString: string;
  vatAmount: number;
}

const parseTotals = (data: JobQuote): JobTotals => {
  const {
    total_price: { vat, pence },
  } = data;
  if (vat.includes('%')) {
    const pct = parseInt(vat.replace('%', ''), 10);
    const exVat = Math.round((pence * 100) / (100 + pct));
    if (![0, 5, 20].includes(pct))
      return {
        totalExVat: exVat,
        vatString: 'custom',
        vatAmount: pence - exVat,
      };
    return {
      totalExVat: exVat,
      vatString: vat,
      vatAmount: pence - exVat,
    };
  }
  return {
    totalExVat: pence,
    vatString: 'exempted',
    vatAmount: 0,
  };
};

const processAnalysisReturn = (
  data: JobQuote,
  company: Company,
  setter: UseFormSetValue<FieldValue<any>>,
) => {
  const workTypes = company?.company_info?.work_types;
  const title = data?.title;
  if (title) setter('job.title', title);
  const totals = parseTotals(data);
  setter('job.job_items.0.price.amount', totals.totalExVat);
  setter('job.job_items.0.vat.amount', totals.vatAmount);
  setter('job.job_items.0.vat_rate', totals.vatString);
  if (data?.description) {
    setter('job.notes.0', String(data.description));
  }
  if (data?.category && workTypes && workTypes.length > 0) {
    if (workTypes.includes(data.category as WorkType))
      setter('job.work_type', data.category);
  }
  if (data?.recipient) {
    if (data.recipient.address) {
      if (data.recipient.address.postcode) {
        setter(
          'job.customer.address.postcode',
          data.recipient.address.postcode,
        );
      }
      if (data.recipient.address.line_1) {
        setter('job.customer.address.line_1', data.recipient.address.line_1);
      }
    }
    if (data.recipient.name) {
      const [firstName, ...rest] = data.recipient.name.split(' ');
      setter('job.customer.first_name', firstName);
      if (rest.length) {
        setter('job.customer.last_name', rest.join(' '));
      }
    }
    if (data.recipient.email) {
      if (data.recipient.email !== 'no-reply@kanda.co.uk')
        setter('job.customer.email', data.recipient.email);
    }
    if (data.recipient.mobile) {
      setter('job.customer.phone', data.recipient.mobile);
    }
  }
};

const isJobQuote = (output: PostPDFOutput): output is JobQuote =>
  (output as JobQuote).title !== undefined;

function useAnalysePDF(
  handleClose: () => void,
  content?: string,
  refreshJobDetails?: () => void,
): AnalysePDFHook {
  const dispatch = useAppDispatch();
  const { showError, showSuccess } = useToast();
  const isSubmitting = useSelector(aiSelectors.aiPdfPostPDF.getIsSubmitting);
  const { setValue } = useFormContext();
  const { company } = useCurrentCompany();

  const onClick = useCallback(() => {
    if (!company) return;
    if (!content) {
      showError('No content string in file to be passed to AI agent');
      handleClose();
      return;
    }
    const body: PostPDFInput = {
      body: content,
      generate_type: 'job_quote',
    };
    dispatch(
      aiActions.aiPdfPostPDF({
        body,
        onSuccess: (data: PostPDFOutput) => {
          if (!isJobQuote(data)) {
            showError('Returned document is not a job quote');
            handleClose();
            return;
          }
          showSuccess(
            'Document successfully analysed - please check any prefilled information for accuracy',
          );
          processAnalysisReturn(data, company, setValue);
          handleClose();
          if (refreshJobDetails) refreshJobDetails();
        },
        onError: (error) => {
          if (error?.message) {
            showError(error.message);
          }
          handleClose();
        },
      }),
    );
  }, [
    content,
    company,
    dispatch,
    handleClose,
    showError,
    showSuccess,
    setValue,
    refreshJobDetails,
  ]);

  return {
    onClick,
    isSubmitting,
  };
}

export default useAnalysePDF;

import { useMemo } from 'react';

export interface WorkTypesSelectOptionClassNames {
  button: string;
  toggle: string;
}

export interface WorkTypesSelectOptionHook {
  classNames: WorkTypesSelectOptionClassNames;
}

export default function useWorkTypesSelectOption(
  active: boolean,
): WorkTypesSelectOptionHook {
  const classNames = useMemo(
    () => ({
      button: [
        'w-full flex flex-row gap-x-3 px-4 py-3 border-b last:border-b-0 border-neutral-300',
        active ? 'bg-green-100' : 'bg-neutral-000',
      ].join(' '),
      toggle: [
        'w-6 h-6 flex border rounded-lg mb-auto',
        active
          ? 'bg-green-500 border-green-500'
          : 'bg-neutral-000 border-neutral-400',
      ].join(' '),
    }),
    [active],
  );

  return { classNames };
}

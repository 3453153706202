import type { FieldValues } from 'pages/Setup/Account/useSetupAccountForm';
import { useCallback, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

export type CompanyTypeButtonClassNames = {
  button: string;
  indicator: string;
};

export interface CompanyTypeButtonsHook {
  classNames: {
    limited: CompanyTypeButtonClassNames;
    sole: CompanyTypeButtonClassNames;
  };
  onLimitedClick: () => void;
  onSoleClick: () => void;
}

const generateButtonClassNames = (
  active: boolean,
): CompanyTypeButtonClassNames => ({
  button: [
    'relative flex flex-col text-left py-6 pb-3 px-4 border rounded-xl',
    active
      ? 'bg-green-100 border-green-500'
      : 'bg-neutral-000 border-neutral-300',
  ].join(' '),
  indicator: [
    'absolute flex w-5 h-5 min-w-5 min-h-5 right-4 top-3 rounded-full border',
    active
      ? 'border-green-500 bg-green-500'
      : 'border-neutral-500 bg-neutral-000',
  ].join(' '),
});

export default function useCompanyTypeButtons(): CompanyTypeButtonsHook {
  const type = useWatch<FieldValues, 'company.company_type'>({
    name: 'company.company_type',
  });

  const { setValue, unregister } = useFormContext<FieldValues>();

  const classNames = useMemo(
    () => ({
      limited: generateButtonClassNames(type === 'limited_company'),
      sole: generateButtonClassNames(type === 'sole_trader'),
    }),
    [type],
  );

  const onLimitedClick = useCallback(() => {
    if (type === 'limited_company') return;
    setValue('company.company_type', 'limited_company');
    unregister('company.sole_trader_info');
  }, [type, setValue, unregister]);

  const onSoleClick = useCallback(() => {
    if (type === 'sole_trader') return;
    setValue('company.company_type', 'sole_trader');
    unregister('company.limited_company_info');
  }, [type, setValue, unregister]);

  return {
    classNames,
    onLimitedClick,
    onSoleClick,
  };
}

import { ModalLayoutCenter } from '@kanda-libs/ks-design-library';
import type { MutableRefObject } from 'react';
import { Admin, Member } from './components';
import useWorkTypeModal from './useWorkTypeModal';

export interface WorkTypeModalModalProps {
  id: string;
  handleClose: () => void;
  ref?: React.MutableRefObject<HTMLElement | undefined>;
}

const WorkTypeModalModal: React.FunctionComponent<WorkTypeModalModalProps> =
  function ({ id, handleClose, ref }) {
    const { isAdmin } = useWorkTypeModal();
    return (
      <ModalLayoutCenter
        id={id}
        onClose={handleClose}
        ref={ref as MutableRefObject<HTMLDivElement>}
        className="w-160"
      >
        <p className="text-16-24-em">Add Work Types</p>
        <div className="px-32 py-14">
          {isAdmin ? (
            <Admin handleClose={handleClose} />
          ) : (
            <Member handleClose={handleClose} />
          )}
        </div>
      </ModalLayoutCenter>
    );
  };

export default WorkTypeModalModal;

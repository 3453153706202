import type { Tag } from '@kanda-libs/ks-frontend-services';
import { useMemo } from 'react';
import { BASE_URL } from '../constants';

export interface TagHook {
  url: string;
  id: string;
}

export default function useTag(tag: Tag): TagHook {
  const url = useMemo(() => `${BASE_URL}${tag.name}`, [tag]);

  const id = useMemo(() => tag.id, [tag]);

  return { url, id };
}

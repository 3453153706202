import { SkeletonLoader, Text } from '@kanda-libs/ks-design-library';
import { useMyAccountAlwaysOnLinkContext } from '../../context';
import TagsWrapper from '../TagWrapper';

const MyAccountAlwaysOnLinkTags: React.FunctionComponent = function () {
  const { tags, isLoading } = useMyAccountAlwaysOnLinkContext();
  return (
    <div className="flex flex-col mb-6">
      <Text text="Your Links" className="text-16-20-em text-neutral-900 mb-4" />
      <SkeletonLoader
        isLoading={isLoading}
        height={96}
        count={2}
        className="mt-4 first:mt-0"
        afterLoading={<TagsWrapper tags={tags} />}
      />
    </div>
  );
};

export default MyAccountAlwaysOnLinkTags;

import { reset } from '@kanda-libs/ks-frontend-services';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export type LeadSorting =
  | 'UPDATED_AT'
  | 'FIRST_NAME'
  | 'LAST_NAME'
  | 'EMAIL'
  | 'PHONE'
  | 'BUDGET_MIN'
  | 'BUDGET_MAX'
  | 'FINANCE_INTEREST'
  | 'STATUS'
  | 'SOURCE'
  | 'ID';

export type BaseOrder = 'NORMAL' | 'INVERSE';

export type LeadsSorting = {
  sorting: LeadSorting;
  order: BaseOrder;
};

export type SortingActionPayload = {
  leads?: LeadsSorting;
};

export type SortingState = {
  leads: LeadsSorting;
};

export const sortingInitialState: SortingState = {
  leads: {
    sorting: 'EMAIL',
    order: 'NORMAL',
  },
};

export const sortingSlice = createSlice({
  name: 'sorting',
  initialState: sortingInitialState,
  reducers: {
    setSorting: (state, action: PayloadAction<SortingActionPayload>) => {
      const { payload } = action;
      const sortingKeys = Object.keys(payload) as (keyof SortingState)[];
      const updatedSorting = sortingKeys.reduce(
        (final: SortingActionPayload, key: keyof SortingState) => {
          const current = {
            [key]: {
              ...state[key],
              ...payload[key],
            },
          };
          return {
            ...final,
            ...current,
          };
        },
        {},
      );
      return {
        ...state,
        ...updatedSorting,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reset, () => ({
      ...sortingInitialState,
    }));
  },
});

export const { setSorting } = sortingSlice.actions;

export default sortingSlice.reducer;

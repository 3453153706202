import Container from './FinanceOptions-container';

import PaymentAndFinanceOptions from '../../../../../PaymentAndFinanceOptions';

const ViewComponent = () => (
  <Container>
    {({ showOptions }) =>
      showOptions ? (
        <PaymentAndFinanceOptions.FinanceRates showStoredRates />
      ) : null
    }
  </Container>
);

ViewComponent.displayName = 'PaymentMethods-Desktop-FinanceOptions-view';

export default ViewComponent;

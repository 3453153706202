import { Layout } from '@kanda-libs/ks-design-library';
import ContactBanner from 'components/ContactBanner';
import { Content, Footer, Header } from './components';

const LeadMobile: React.FunctionComponent = function () {
  return (
    <Layout stickyFooter footer={<Footer />} header={<Header />} noBorder>
      <Content />
      <ContactBanner />
    </Layout>
  );
};

export default LeadMobile;

import { pipe } from 'fp-ts/lib/function';
import { useMemo } from 'react';
import {
  filterForUnregulatedRates,
  groupByAprValuesAndFee,
  type GroupedByAprAndFee,
} from 'utils';
import { useFinanceOptionsContext } from '../context';

export interface UnregulatedRatesHook {
  rates: GroupedByAprAndFee | undefined;
  toggleable: boolean;
}

export default function useUnregulatedRates(): UnregulatedRatesHook {
  const { rates: allRates, toggleable } = useFinanceOptionsContext();

  const rates = useMemo(() => {
    if (!allRates) return undefined;
    return pipe(allRates, filterForUnregulatedRates, groupByAprValuesAndFee);
  }, [allRates]);

  return {
    rates,
    toggleable,
  };
}

import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import {
  createContext,
  useContext,
  useMemo,
  type FunctionComponent,
  type ReactNode,
} from 'react';
import type { FormattedLenderConfig } from 'utils';

export interface FinanceOptionsContextType {
  lenderConfigs: FormattedLenderConfig | undefined;
  rates: FinanceRate[] | undefined;
  total: number;
  deposit: number;
  toggleable: boolean;
  fieldName: string;
  showHandle: boolean;
  isExample: boolean;
  isLoading: boolean;
}

export const FinanceOptionsContext = createContext<FinanceOptionsContextType>({
  lenderConfigs: undefined,
  rates: undefined,
  total: 0,
  deposit: 0,
  showHandle: true,
  toggleable: true,
  fieldName: '',
  isExample: false,
  isLoading: false,
});

export const useFinanceOptionsContext = () => useContext(FinanceOptionsContext);

export interface FinanceOptionsProviderProps {
  children: ReactNode;
  lenderConfigs: FormattedLenderConfig | undefined;
  rates: FinanceRate[] | undefined;
  total: number;
  deposit: number;
  toggleable: boolean;
  fieldName: string;
  showHandle: boolean;
  isExample: boolean;
  isLoading: boolean;
}

const FinanceOptionsProvider: FunctionComponent<FinanceOptionsProviderProps> =
  function ({
    children,
    lenderConfigs,
    rates,
    total,
    deposit,
    showHandle,
    toggleable,
    fieldName,
    isExample,
    isLoading,
  }) {
    const values = useMemo(
      () => ({
        lenderConfigs,
        rates,
        total,
        deposit,
        showHandle,
        toggleable,
        fieldName,
        isExample,
        isLoading,
      }),
      [
        lenderConfigs,
        rates,
        total,
        deposit,
        showHandle,
        toggleable,
        fieldName,
        isExample,
        isLoading,
      ],
    );
    return (
      <FinanceOptionsContext.Provider value={values}>
        {children}
      </FinanceOptionsContext.Provider>
    );
  };

export default FinanceOptionsProvider;

import { URLS } from 'config';
import { useCurrentCompany } from 'hooks';
import { useEffect, type FunctionComponent, type ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

export interface WorkTypesWrapperProps {
  children: ReactNode;
}

const WorkTypesWrapper: FunctionComponent<WorkTypesWrapperProps> = function ({
  children,
}) {
  const { company } = useCurrentCompany();
  const { push } = useHistory();

  useEffect(() => {
    if (!company) return;
    if (!['completed_setup', 'verified'].includes(company?.lifecycle || ''))
      return;
    const workTypes = company?.company_info?.work_types;
    if (workTypes && workTypes.length > 0) return;
    push(URLS.addWorkTypes);
  }, [company, push]);

  return children;
};

export default WorkTypesWrapper;

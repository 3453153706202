import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import useFee from './useFee';

export interface FeeProps {
  rates: FinanceRate[];
  grouped: boolean;
}

const Fee: React.FunctionComponent<FeeProps> = function ({ rates, grouped }) {
  const { fee } = useFee(rates, grouped);
  return (
    <div className="py-2 text-right text-14-22-em text-neutral-600 whitespace-nowrap">
      {fee} of loan
    </div>
  );
};

export default Fee;

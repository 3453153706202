import type { StringIndexedObject } from '@kanda-libs/ks-component-ts';
import { actions, type Job } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { DEV_CIDS } from 'config';
import { useCurrentCompany } from 'hooks';
import useApiError from 'hooks/useApiError';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { formatJobPayload } from './CreateJob-functions';
import useCreateJobContextValue from './CreateJob-useCreateJobContextValue';

export interface SubmitJobHook {
  handleSubmit: (formData: StringIndexedObject) => void;
  isSubmitting: boolean;
  error?: string;
}

/**
 * Handles job submit
 */
const useSubmitJob = (): SubmitJobHook => {
  const dispatch = useAppDispatch();

  const isSubmitting = useSelector(selectors.job.getIsSubmitting);

  const { data, setData, handleContinue } = useCreateJobContextValue();
  const { company } = useCurrentCompany();

  const [error, setError] = useState<string | undefined>();

  const onError = useApiError(
    'Error sending job at this time. This job has been saved as a draft',
  );

  /**
   * Handles submit job
   */
  const handleSubmit = useCallback(
    (formData: StringIndexedObject) => {
      if (!company) return;
      const cid = company?.cid;
      const notes = data?.job?.notes?.[0] || '';
      const payload = formatJobPayload(formData, notes, company);

      dispatch(
        actions.postJob({
          body: payload,
          useDevHeader: cid ? DEV_CIDS.includes(cid) : false,
          chainedRequest: true,
          onError,
          onSuccess: (draftData) => {
            const { id } = draftData || {};

            dispatch(
              actions.sendJob({
                params: {
                  id: id as string,
                },
                onError: (sentJobError) => {
                  onError(sentJobError);
                  setError(sentJobError.message);
                  setData({
                    ...formData,
                    job: {
                      ...(draftData as Job),
                    },
                  });
                },
                onSuccess: (sentJobData) => {
                  handleContinue({
                    ...formData,
                    job: sentJobData,
                  });
                },
              }),
            );
          },
        }),
      );
    },
    [data, setError, setData, handleContinue, dispatch, company, onError],
  );

  return {
    handleSubmit,
    isSubmitting,
    error,
  };
};

export default useSubmitJob;

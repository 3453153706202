import {
  useToast,
  type StringIndexedObject,
} from '@kanda-libs/ks-design-library';
import { actions, Company, InfoMe } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { APP_ENV, URLS } from 'config';
import useApiError from 'hooks/useApiError';
import useQueryParam from 'hooks/useQueryParam';
import { useCallback, useMemo, useState } from 'react';
import { useForm, type UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { selectors } from 'store';
import { scrollToTop } from 'utils';
import { formatCompanyBody, formatMeBody, formatPid } from './helpers';

export type MeNameFieldValues = {
  first_name?: string;
  last_name?: string;
};

export type MeFieldValues = Partial<InfoMe> & MeNameFieldValues;

export type FieldValues = {
  me?: MeFieldValues;
  company: Partial<Company>;
};

export interface SetupAccountFormHook {
  form: UseFormReturn<FieldValues>;
  onSubmit: (formValues: StringIndexedObject<FieldValues>) => void;
  isSubmitting: boolean;
  disabled: boolean;
  schemaError: boolean;
}

const splitName = (name: string): MeNameFieldValues => {
  const [first, ...second] = name.split(' ');
  return {
    first_name: first,
    last_name: second.join(' '),
  };
};

export default function useSetupAccountForm(): SetupAccountFormHook {
  const [schemaError, setSchemaError] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [pid] = useQueryParam('pid');
  const { showError } = useToast();
  const onCompanyError = useApiError('Error updating company info');
  const onMeError = useApiError('Error updating personal info');
  const { push } = useHistory();

  const dispatch = useAppDispatch();
  const me = useSelector(selectors.getUser);

  const includeMePayload = useMemo(() => {
    if (!me) return false;
    return !me?.phone && APP_ENV !== 'qa';
  }, [me]);

  const defaultValues: FieldValues = useMemo(
    () => ({
      company: {
        quote_preference: 'kanda' as Company['quote_preference'],
      },
      ...(includeMePayload && {
        me: {
          ...me,
          ...splitName(me.name),
        },
      }),
    }),
    [me, includeMePayload],
  );

  const form = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const postCompany = useCallback(
    (formValues: FieldValues, formattedId?: string) => {
      setIsSubmitting(true);
      const body = formatCompanyBody(
        formValues.company as Company,
        formattedId,
      );
      dispatch(
        actions.postCompany({
          body,
          onError: (error) => {
            setIsSubmitting(false);
            if (error?.message?.includes('schema')) {
              showError('Error with company info - please fill missing fields');
              setSchemaError(true);
              return;
            }
            onCompanyError(error);
          },
          onSuccess: () => {
            dispatch(
              actions.getCompanies({
                params: {},
                onError: () => {
                  setIsSubmitting(false);
                  setTimeout(() => {
                    push(URLS.setupInfo);
                    scrollToTop();
                  }, 10);
                },
                onSuccess: () => {
                  setIsSubmitting(false);
                  setTimeout(() => {
                    push(URLS.setupInfo);
                    scrollToTop();
                  }, 10);
                },
              }),
            );
          },
        }),
      );
    },
    [dispatch, onCompanyError, push, showError],
  );

  const putMe = useCallback(
    (formValues: FieldValues, formattedId?: string) => {
      const meFormValues = formValues.me;
      if (!meFormValues) return;
      const body = formatMeBody(meFormValues);
      setIsSubmitting(true);
      dispatch(
        actions.putMe({
          body,
          onError: (error) => {
            setIsSubmitting(false);
            onMeError(error);
          },
          onSuccess: () => postCompany(formValues, formattedId),
        }),
      );
    },
    [dispatch, onMeError, postCompany],
  );

  const onSubmit = useCallback(
    (formValues: StringIndexedObject<FieldValues>) => {
      const fv = formValues as unknown as FieldValues;
      const formattedId = formatPid(pid);
      if (includeMePayload) {
        putMe(fv, formattedId);
        return;
      }
      postCompany(fv, formattedId);
    },
    [pid, includeMePayload, postCompany, putMe],
  );

  return {
    form,
    onSubmit,
    isSubmitting,
    disabled: isSubmitting,
    schemaError,
  };
}

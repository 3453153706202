import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

interface ContainerComponentChildrenArgs {
  handleClick: () => void;
}

interface ContainerComponentProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
  backURL: string;
}

const ContainerComponent = ({ children, backURL }: ContainerComponentProps) => {
  const { length, goBack, push } = useHistory();

  const handleClick = useCallback(() => {
    if (length === 0) {
      push(backURL);
      return;
    }
    goBack();
  }, [length, goBack, push, backURL]);

  return children({
    handleClick,
  });
};

ContainerComponent.displayName = 'Backlink-container';

export default ContainerComponent;

import {
  DesktopLayoutBoxed,
  DesktopLayoutBoxedContent,
} from '@kanda-libs/ks-design-library';
import type { ReactNode } from 'react';

export interface AddWorkTypeLayoutDesktopProps {
  children: ReactNode;
}

const AddWorkTypeLayoutDesktop: React.FunctionComponent<AddWorkTypeLayoutDesktopProps> =
  function ({ children }) {
    return (
      <DesktopLayoutBoxed>
        <DesktopLayoutBoxedContent>
          {children as unknown as JSX.Element | JSX.Element[] | undefined}
        </DesktopLayoutBoxedContent>
      </DesktopLayoutBoxed>
    );
  };

export default AddWorkTypeLayoutDesktop;

import { Form } from '@kanda-libs/ks-component-ts';
import { Button, Loader } from '@kanda-libs/ks-design-library';
import FinanceRatesConfirmModal from './ConfirmModal';
import { FORM_ID } from './constants';
import LockedMessage from './LockedMessage';
import MyAccountFinanceRatesFormRates from './Rates';
import useMyAccountFinanceRatesForm from './useMyAccountFinanceRatesForm';

const MyAccountFinanceRatesForm: React.FunctionComponent = function () {
  const {
    form,
    onSubmit,
    onClick,
    interestFreeRates,
    interestBearingRates,
    bnplRates,
    isSubmitting,
    locked,
  } = useMyAccountFinanceRatesForm();
  return (
    <Form
      form={form}
      onSubmit={onSubmit}
      id={FORM_ID}
      className="flex flex-col gap-y-10"
    >
      <div className="flex flex-col w-full md:border border-neutral-300 rounded-xl md:shadow-card">
        <h2 className="text-12-18-em-up text-neutral-700 md:p-6 pb-6">
          Available Rates
        </h2>
        <div className="w-full md:border-b border-neutral-300" />
        <div className="flex flex-col gap-y-4 md:p-6">
          {interestFreeRates && (
            <MyAccountFinanceRatesFormRates grouped={interestFreeRates} />
          )}
          {interestBearingRates && (
            <MyAccountFinanceRatesFormRates
              grouped={interestBearingRates}
              groupDurations
            />
          )}
          {bnplRates && (
            <MyAccountFinanceRatesFormRates
              grouped={bnplRates}
              groupDurations
            />
          )}
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row gap-x-6 gap-y-4">
        <Button.Text
          id="my-account-finance-rates-confirm"
          label="Update Rates"
          className="whitespace-nowrap"
          variant="gradient"
          disabled={isSubmitting || locked}
          onClick={onClick}
        />
        <button
          id="my-account-finance-rates-submit"
          type="submit"
          className="hidden"
          aria-label="submit"
        />
        <div className="w-full">{locked && <LockedMessage />}</div>
        <FinanceRatesConfirmModal isSubmitting={isSubmitting} />
      </div>
      <Loader isLoading={isSubmitting} />
    </Form>
  );
};

export default MyAccountFinanceRatesForm;

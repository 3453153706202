import type { FunctionComponent } from 'react';

const StandardLabel: FunctionComponent = function () {
  return (
    <span>
      Job Type{' '}
      <span className="whitespace-nowrap text-style-h text-neutral-500 ml-3 pt-0.5">
        (visible only to the lender)
      </span>
    </span>
  );
};

export default StandardLabel;

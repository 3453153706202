import { reset } from '@kanda-libs/ks-frontend-services';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export type QueryType = 'leads';

export type QueryTerms = {
  [key in QueryType]?: string;
};

export interface QueryState {
  queryTerms: QueryTerms;
}

const initialState: QueryState = {
  queryTerms: {
    leads: '',
  },
};

export const querySlice = createSlice({
  name: 'query',
  initialState,
  reducers: {
    setQuery: (state, action: PayloadAction<[QueryType, string]>) => ({
      ...state,
      queryTerms: {
        ...state.queryTerms,
        [action.payload[0]]: action.payload[1],
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(reset, () => ({
      ...initialState,
    }));
  },
});

export const { setQuery } = querySlice.actions;

export default querySlice.reducer;

import { Card, NoRates, Options } from '../components';
import { SUBTITLE, TITLE } from './constants';
import useUnregulatedRates from './useUnregulatedRates';

const FinanceRatesUnregulated: React.FunctionComponent = function () {
  const { rates, toggleable } = useUnregulatedRates();
  return (
    <Card title={TITLE} subtitle={SUBTITLE}>
      {rates ? <Options rates={rates} toggleable={toggleable} /> : <NoRates />}
    </Card>
  );
};

export default FinanceRatesUnregulated;

import { URLS } from '../../config/application';

export const MARKETING_RESOURCES_LINK =
  'https://www.notion.so/getkanda/Marketing-Resources-eba2a32cd574434591e7125fac9fa644';
export const API_DOCS_LINK =
  'https://www.notion.so/getkanda/Kanda-Integration-Guide-a98d046a29334595816d6b5285f5d9cf';

export const DESKTOP_MENU_ITEMS = [
  // { name: 'Leads', to: URLS.leads },
  { name: 'Jobs', to: URLS.jobs },
];

export const DESKTOP_MENU_ADMIN_ITEMS = [{ name: 'Home', to: URLS.home }];

export const DESKTOP_COMPANY_ITEMS = [
  {
    name: 'Referrals',
    to: URLS.referrals,
    icon: 'currency',
  },
  {
    name: 'Account settings',
    to: URLS.accountSettings,
    icon: 'settings',
  },
];

export const DESKTOP_ADMIN_ITEMS = [
  // {
  //   name: 'Always On Link',
  //   to: URLS.companyInformation,
  //   icon: 'send-quote',
  // },
  {
    name: 'Company information',
    to: URLS.companyInformation,
    icon: 'toolbox',
  },
  {
    name: 'Job settings',
    to: URLS.jobSettings,
    icon: 'job',
  },
  {
    name: 'Finance rates',
    to: URLS.financeRates,
    icon: 'currency',
  },
  {
    name: 'Subscription',
    to: URLS.subscription,
    icon: 'special-check',
  },
  {
    name: 'Team members',
    to: URLS.teamSettings,
    icon: 'user',
  },
  {
    name: 'Notification Preferences',
    to: URLS.notificationPreferences,
    icon: 'notification',
  },
  {
    name: 'API Docs',
    onClick: () => {
      window.open(API_DOCS_LINK, '_blank')?.focus();
    },
    icon: 'note',
  },
];

export const DESKTOP_VERIFICATION_ITEM = {
  name: 'Director Verification',
  to: URLS.directorVerification,
  icon: 'verification',
};

export const STAFF_DESKTOP_COMPANY_ITEM = {
  name: 'Imitate user',
  icon: 'user',
};

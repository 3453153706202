import {
  Button,
  Loader,
  type StatusTagStatus,
} from '@kanda-libs/ks-design-library';

import { URLS } from 'config';
import { BackLink, PriceStatus } from '../../../../../components';
import MarkAsFinished from '../../MarkAsFinished';
import SendReminder from '../../SendReminder';
import { Customer, FinanceStatus, Menu, Status, Title } from '../components';
import { CLASS_NAMES } from '../JobHeader-constants';

interface ViewComponentProps {
  /**
   * Customer
   */
  customer?: string;
  /**
   * Job title
   */
  title?: string;
  /**
   * Job status
   */
  status?: string;
  /**
   * Job finance status
   */
  financeStatus?: string;
  /**
   * Job total price
   */
  price?: number;
  /**
   * Job id
   */
  id?: string;
  /**
   * Timestamp
   */
  timestamp?: Date;
  /**
   * Is loading
   */
  isLoading?: boolean;
  /**
   * Job is archived
   */
  archived?: boolean;
  /**
   * Job PDF download handler
   */
  downloadPdf: (id: string) => void;
  /**
   * Job PDF download is submitting
   */
  pdfIsSubmitting?: boolean;
}

const ViewComponent = ({
  customer,
  title,
  status,
  financeStatus,
  price,
  id,
  timestamp,
  isLoading,
  archived,
  downloadPdf,
  pdfIsSubmitting,
}: ViewComponentProps) => (
  <div className={CLASS_NAMES.container}>
    <div className="flex flex-row">
      <BackLink backURL={URLS.jobs}>
        {({ handleClick }) => (
          <Button.Icon
            id="job-details-desktop-back-button"
            icon="arrow-left"
            onClick={handleClick}
          />
        )}
      </BackLink>
      <div className={CLASS_NAMES.info}>
        <Customer isLoading={isLoading} name={customer} />
        <Title isLoading={isLoading} text={title} />
        <div className={CLASS_NAMES.desktopFooter}>
          <Status
            isLoading={isLoading}
            status={status as StatusTagStatus}
            archived={archived}
            timestamp={timestamp}
          />
          <FinanceStatus
            isLoading={isLoading}
            financeStatus={financeStatus as StatusTagStatus}
          />
          <PriceStatus
            isLoading={isLoading}
            total={price as number}
            status={status}
            financeStatus={financeStatus}
          />
        </div>
      </div>
    </div>
    <div className="flex flex-row mb-auto mr-6">
      <MarkAsFinished />
      <SendReminder />
      <Menu jobId={id} status={status} downloadPdf={downloadPdf} />
      <Loader isLoading={pdfIsSubmitting} />
    </div>
  </div>
);

ViewComponent.displayName = 'Job-JobHeader-Desktop-view';

ViewComponent.defaultProps = {
  customer: undefined,
  title: undefined,
  status: undefined,
  financeStatus: undefined,
  price: undefined,
  id: undefined,
  timestamp: undefined,
  isLoading: undefined,
  archived: false,
};

export default ViewComponent;

import { Text } from '@kanda-libs/ks-design-library';
import { type ReactNode } from 'react';

interface InfoCardProps {
  children: ReactNode;
  title: string;
  isLoading?: boolean;
}

const InfoCard: React.FunctionComponent<InfoCardProps> = function ({
  children,
  title,
  isLoading = false,
}) {
  return (
    <div className="flex flex-col gap-y-4 md:gap-y-6 text-left">
      <Text
        text={title}
        isLoading={isLoading}
        className="text-16-20-em text-neutral-900"
      />
      <div className="flex flex-col p-4 border border-neutral-300 rounded-lg shadow-card">
        {children}
      </div>
    </div>
  );
};

export default InfoCard;

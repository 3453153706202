import { Field } from '@kanda-libs/ks-component-ts';
import { Icon, SkeletonLoader, Text } from '@kanda-libs/ks-design-library';
import { WORK_TYPE_VALIDATION } from './constants';
import WorkTypeSelectOption from './Option';
import SelectedOption from './SelectedOption';
import useWorkTypesSelect from './useWorkTypesSelect';

export interface WorkTypesSelectProps {
  name?: string;
  title?: string;
  alwaysActive?: boolean;
  isLoading?: boolean;
}

const WorkTypesSelect: React.FunctionComponent<WorkTypesSelectProps> =
  function ({
    name = 'work_types',
    title = 'What types of work does your business undertake? Please select all relevant work types (at least one must be selected)',
    alwaysActive = false,
    isLoading = false,
  }) {
    const {
      onButtonClick,
      classNames,
      active,
      dropdownRef,
      error,
      options,
      selectedOptions,
    } = useWorkTypesSelect(name, alwaysActive);
    return (
      <div className="flex flex-col mb-6">
        <div className="hidden">
          <Field.Validator validation={WORK_TYPE_VALIDATION}>
            <Field.Input name={name} />
          </Field.Validator>
        </div>
        <Text
          isLoading={isLoading}
          className="text-14-22-em text-neutral-800 font-bold mb-2"
          text={title}
        />
        <SkeletonLoader
          isLoading={isLoading}
          height={200}
          afterLoading={
            <>
              <button
                type="button"
                className={classNames.button}
                onClick={onButtonClick}
              >
                <div className="w-full flex flex-row flex-wrap gap-2">
                  {selectedOptions ? (
                    selectedOptions.map((selectedOption) => (
                      <SelectedOption
                        key={selectedOption.name}
                        {...selectedOption}
                      />
                    ))
                  ) : (
                    <p className="text-14-22">Select work type(s)</p>
                  )}
                </div>
                <Icon
                  icon="chevron-down"
                  size={16}
                  className={classNames.chevron}
                />
              </button>
              {error && !active && (
                <p className="text-red-200 text-12-18 mt-2">
                  At least one work type is required
                </p>
              )}
              {active && (
                <div
                  ref={dropdownRef}
                  className="w-full mt-3 bg-neutral-000 border border-neutral-300 rounded-lg h-44 overflow-scroll"
                >
                  {options.map((option) => (
                    <WorkTypeSelectOption key={option.name} {...option} />
                  ))}
                </div>
              )}
            </>
          }
        />
      </div>
    );
  };

export default WorkTypesSelect;

import { Link } from 'react-router-dom';

import { BreakPoints, Button } from '@kanda-libs/ks-design-library';
import SearchBar from 'components/SearchBar';
import { URLS } from 'config';
import {
  CompleteAccountBadge,
  UpgradeAccountBadge,
} from 'pages/Jobs/components/Banner/components';
import { BUTTON_PROPS, CLASS_NAMES } from './constants';
import useLeadsBanner from './useLeadsBanner';

const LeadsBanner: React.FunctionComponent = function ({}) {
  const {
    showOptions,
    setupInProgress,
    hasActiveFilters,
    archive,
    showSubscriptionBanner,
    isLoading,
    showSearch,
  } = useLeadsBanner();
  return (
    <div className={CLASS_NAMES.container}>
      {setupInProgress && !archive && (
        <div className="mb-8 md:mb-16">
          <CompleteAccountBadge />
        </div>
      )}
      {!setupInProgress && showSubscriptionBanner && (
        <div className="mb-8 md:mb-16">
          <UpgradeAccountBadge />
        </div>
      )}
      <div className={CLASS_NAMES.header}>
        {archive && (
          <div className="hidden md:block md:flex">
            <Link to={URLS.leads} className="-ml-3 mr-3">
              <Button.Icon id="leads-banner-back" icon="arrow-left" size={40} />
            </Link>
          </div>
        )}
        <p className={CLASS_NAMES.title}>
          {archive ? 'Archived Leads' : 'Leads'}
        </p>
        {showSearch && (
          <div className="hidden md:flex flex-1 flex-row ml-6 mr-12">
            <SearchBar queryType="leads" isLoading={isLoading} />
          </div>
        )}
        <div className={CLASS_NAMES.options}>
          {showOptions && !archive && (
            <>
              {/* todo: add filter here */}
              <Link to={URLS.leadsArchive} className="ml-2">
                <BreakPoints
                  mobile={<Button.Icon {...BUTTON_PROPS.mobile} />}
                  desktop={<Button.Text {...BUTTON_PROPS.desktop} />}
                />
              </Link>
            </>
          )}
        </div>
      </div>
      {hasActiveFilters && <p>Active Filters</p>}
      {showSearch && (
        <div className="flex md:hidden flex-1 flex-row">
          <SearchBar queryType="leads" isLoading={isLoading} />
        </div>
      )}
    </div>
  );
};

export default LeadsBanner;

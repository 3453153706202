import { useMemo, type FunctionComponent } from 'react';
import LeadsNoResults from '../components/LeadsNoResults';
import LeadsPlaceholder from '../components/LeadsPlaceholder';
import { useLeadsContext } from '../context';
import LeadsTable from './LeadsTable';

export interface LeadsDesktopHook {
  Content: FunctionComponent;
}

export default function useLeadsDesktop(): LeadsDesktopHook {
  const { leads, noLeads, isLoading } = useLeadsContext();

  const Content = useMemo(() => {
    if (isLoading) return LeadsTable;
    if (noLeads) return LeadsPlaceholder;
    if (leads.length === 0 && !noLeads) return LeadsNoResults;
    return LeadsTable;
  }, [isLoading, noLeads, leads]);

  return {
    Content,
  };
}
